<template>
    <div class="component-container mb-4">
        <b-card
            overlay
            :img-src="urlImagem"
            img-alt="Imagem banner"
            text-variant="black"
            title-text-variant="danger"
            style="width: 100%;"
        >

            <div class="banner-principal-wrapper" v-if="windowWidth >= 992">
                <banner-principal-container 
                    :componente="componente"
                    :id_comp="id_comp"
                    :windowWidth="windowWidth"
                />
            </div>

        </b-card>

        <div class="banner-principal-wrapper" v-if="windowWidth < 992">
                <banner-principal-container 
                    :componente="componente"
                    :id_comp="id_comp"
                    :windowWidth="windowWidth"
                />
        </div>

    </div>
</template>

<script>
import { LISTA_CORES } from '@/assets/js/dicionarioPaginas';
import { redirecionadorExterno } from '@/assets/js/SharkUtils';
import BannerPrincipalContainer from '@/components/page-components/BannerPrincipalContainer';

export default {
    name: 'BannerPrincipal',
    components: {
        BannerPrincipalContainer,
    },
    props: {
        id_comp: Number,
        componente: Object,
    },
    computed: {
        tema() {
            if(this.componente) {
                if(this.componente.tema) {
                    let corLista = this.getCorLista(this.componente.tema);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return null;
        },
        corTitulo() {
            if(this.componente) {
                if(this.componente.cor_titulo) {
                    let corLista = this.getCorLista(this.componente.cor_titulo);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--azul-omni)';
        },
        corSubTitulo() {
            if(this.componente) {
                if(this.componente.cor_sub_titulo) {
                    let corLista = this.getCorLista(this.componente.cor_sub_titulo);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--azul-omni)';
        },
        temBotao1() {
            if(this.componente) {
                if(this.componente.txt_botao_1) {
                    return this.componente.txt_botao_1.length > 0 ? true: false;
                }
            }
            return false;
        },
        textoBotao1() {
            if(this.componente) {
                if(this.componente.txt_botao_1) {
                    return this.componente.txt_botao_1
                }
            }
            return 'Clique aqui!';
        },
        corBotao1() {
            if(this.componente) {
                if(this.componente.cor_botao_1) {
                    let corLista = this.getCorLista(this.componente.cor_botao_1);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--laranja-omni)';
        },
        temBotao2() {
            if(this.componente) {
                if(this.componente.txt_botao_2) {
                    return this.componente.txt_botao_2.length > 0 ? true: false;
                }
            }
            return false;
        },
        textoBotao2() {
            if(this.componente) {
                if(this.componente.txt_botao_2) {
                    return this.componente.txt_botao_2
                }
            }
            return 'Clique aqui!';
        },
        corBotao2() {
            if(this.componente) {
                if(this.componente.cor_botao_2) {
                    let corLista = this.getCorLista(this.componente.cor_botao_2);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--laranja-omni)';
        },
        urlImagem() {
            if(this.componente) {
                if(this.windowWidth >= 992 && this.componente.url_img) {
                    return this.componente.url_img;
                }
                if(this.windowWidth < 992 && this.componente.url_img_mb) {
                    return this.componente.url_img_mb;
                }
                if(this.windowWidth < 992 && this.componente.url_img) {
                    return this.componente.url_img;
                }
            }
            return require('../../assets/images/placeholders/img_placeholder.png');
        },
    },
    data: function() {
        return {
            listaCores: LISTA_CORES,
            windowWidth: window.innerWidth,
        }
    },
    methods: {
        getCorLista: function(COR) {
            return this.listaCores.find(cor => cor.chave === COR);
        },
        redireciona(pUrl) {
            if(pUrl) {
                redirecionadorExterno(this.$router, pUrl);
            }
        },
        onResize: function() {
            this.windowWidth = window.innerWidth 
        },
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
}
</script>

<style scoped>

.component-container {
    max-width: 100% !important;
    width: 100% !important;
    min-height: 200px;
}

.btn-secondary{
    position: relative;
    top:80%;
    width: 80%;
    border:none;
    margin-bottom: 10px;
}
.container
{
    width: 100%;
    display: block;
   
}
.card
{
    border-style: none;
}
.card-title
{
    color: #007bff;
    font-family: MuseoSans;
    font-weight: 900;
}

.banner-principal-wrapper {
    width: 100%;
    left: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media screen and (max-width: 991px) {
    .banner-principal-wrapper {
        position: relative;
        top: 0%;
        left: 0;
        -ms-transform: none;
        transform: none;
    }
}

</style>