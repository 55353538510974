import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CKEditor from '@ckeditor/ckeditor5-vue'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { BootstrapVue, BIcon, BIconSearch, BIconFiles, BIconTrashFill, BIconPencilSquare  } from 'bootstrap-vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Dropdown from 'bp-vuejs-dropdown'
import vueScrollto from 'vue-scrollto'
import * as VueGoogleMaps from 'vue2-google-maps'

require('./config/httpConfig');
require('@/assets/css/variaveis-root-omni.css');

Vue.config.productionTip = false

// global
Vue.use(vueScrollto);
Vue.use(Dropdown);
Vue.use(BootstrapVue);
Vue.component('BIcon', BIcon)
Vue.component('BIconSearch', BIconSearch)
Vue.component('BIconFiles', BIconFiles)
Vue.component('BIconTrashFill', BIconTrashFill)
Vue.component('BIconPencilSquare', BIconPencilSquare)
Vue.use(CKEditor);
Vue.use(VueGoogleMaps);

// Tenta autenticar antes de abrir a aplicação
let admin;
try {
  admin = JSON.parse(localStorage.getItem('admin'))
} catch(e) {
  admin = null
}

store.dispatch('authAdmin/attempt', admin).finally( () => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}); 


