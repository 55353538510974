<template>
  <div class="container-lista-icones" v-bind:style="{ 'background-color': corFundo }">
    <div class="container container-w-100-lista-icones">
        <div class="row mx-auto my-3 px-3" v-if="tituloLista">
            <h2 v-bind:style="{ color: corTitulo }" class="mx-auto lista-icones-titulo-lista"> {{ tituloLista }}</h2>
        </div>
        <div class="row mx-auto my-2" v-else>
        </div>

        <div class="row my-2" :class="margemLateral">

            <div :class="classeColunasIcones" class="mx-auto px-2 py-2" v-for="(item, index) in itens" v-bind:key="index">
                <lista-icones-bloco-icone 
                    :url_icone="item.url_icone"
                    :titulo="item.titulo"
                    :sub_titulo="item.sub_titulo"
                    :texto="item.texto"
                    :cor_titulo="item.cor_titulo"
                    :cor_sub_titulo="item.cor_sub_titulo"
                    :cor_texto="'#fff'"
                    :alinhamento="tipo"
                    :link="item.url_redir"
                    @carregou="trataAltura"
                    :alturaImg="alturaMaximaImagem"
                />
            </div>

        </div>

    </div>
  </div>
</template>

<script>

import ListaIconesBlocoIcone from '@/components/page-components/ListaIconesBlocoIcone.vue';
import { temasFactory, getCor } from '@/assets/js/SharkUtils';

export default {
  name: 'ListaIcones',
  components: {
      ListaIconesBlocoIcone
  },
  props: {
    id_comp: Number,
    componente: Object,
  },
  methods: {
    trataAltura(pAltura) {
      if(pAltura > this.alturaMaximaImagem) {
        this.alturaMaximaImagem = pAltura;
      }
    },
  },
  computed: {
      itens() {
        if(this.componente) {
            return this.componente.itens? this.componente.itens: [];
        } else {
            return [];
        }
      },
      tema() {
        if(this.componente) {
            return temasFactory(this.componente.tema);
        }
        return null;
      },
      corFundo() {
        if(this.componente) {
          return getCor(this.componente.tema).var_hexa;
        }
        return '#000';
      },
      corTitulo() {
        if(this.componente) {
          return getCor(this.componente.cor_titulo).var_hexa;
        }
        return '#fff';
      },
      tituloLista() {
        if(this.componente) {
          if(this.componente.titulo) {
            return this.componente.titulo;
          }
        }
        return '';
      },
      classeColunasIcones() {
        if(this.itens.length <= 2)
          return 'col-sm-4 col-lg-4';
        if(this.itens.length <= 3)
          return 'col-sm-3 col-lg-3';
        if(this.itens.length <= 4)
          return 'col-sm-3 col-md-2 col-lg-2';
        if(this.itens.length <= 6)
          return 'col-sm-4 col-md-3 col-lg-2';
        if(this.itens.length <= 7)
          return 'lista-icones-w-14';

        return 'col-md-1 ';
      },
      tipo() {
        if(this.componente){
          if(this.componente.tipo === 'LISTA_ICONES_ESQ'){
            return 'ESQUERDA';
          }
          return '';
        }
        return '';
      },
      margemLateral() {
        if(this.itens.length > 4) {
          return 'mx-5'
        }
        if(this.itens.length > 2) {
          return 'mx-4'
        }
        return 'mx-3'
      },
  },
  data: function() {
    return {
      alturaMaximaImagem: 60,
    }
  }
}
</script>

<style scoped>
.container-lista-icones {
    /* border: 1px solid red; */
    max-width: 100% !important;
    width: 100% !important;
    padding-top: 33px;
    padding-bottom: 33px;
}
.container-w-100-lista-icones {
    max-width: 100% !important;
    overflow-x: hidden;
}

.lista-icones-titulo-lista {
  font-weight: 900;
}
</style>

<style>
.lista-icones-w-14 {
  width: 13.5% !important;
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
	.lista-icones-w-14 {
    width: 24% !important;
  }
}

@media screen and (max-width: 900px) {
	.lista-icones-w-14 {
    width: 80% !important;
  }
}
</style>