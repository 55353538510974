import { LISTA_CORES, LISTA_TEMAS } from '@/assets/js/dicionarioPaginas';

export const getCor = (COR) => {
    return {...LISTA_CORES.find(cor => cor.chave === COR) };
}

export const temasFactory = (TEMA) => {
    const tema = LISTA_TEMAS.find(item => item.chave === TEMA);
    if(tema) {
        const corPrimaria = LISTA_CORES.find(corObj => corObj.chave === tema.cores.primaria.chave);
        const corSecundaria = LISTA_CORES.find(corObj => corObj.chave === tema.cores.secundaria.chave);
        const corTerciaria = LISTA_CORES.find(corObj => corObj.chave === tema.cores.terciaria.chave);
        return {
            chave: tema.chave,
            cores: {
                primaria: {
                    ...corPrimaria
                },
                secundaria: {
                    ...corSecundaria
                },
                terciaria: {
                    ...corTerciaria
                },
            },
        };
    } else {
        return null;
    }
}

// Formata data no formato dd/mm/yyyy
export const dataFormatada = (data) => {
    if(!data)
        return new Date();
    var dia  = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0'+dia : dia,
        mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0'+mes : mes,
        anoF = data.getFullYear();
    return diaF+"/"+mesF+"/"+anoF;
}

export const formatarTelefone = (numeroTel) => {
    if(!numeroTel) {
        return '';
    }

    let numero = numeroTel.toString();
    let length = numero.length;
    let telefoneFormatado;

    if (length === 10) {
        telefoneFormatado = '(' + numero.substring(0, 2) + ') ' + numero.substring(2, 6) + '-' + numero.substring(6, 10);
    } else if (length === 11) {
        telefoneFormatado = '(' + numero.substring(0, 2) + ') ' + numero.substring(2, 3) + ' ' + numero.substring(3, 7) + '-' + numero.substring(7, 11);
    } else {
        telefoneFormatado = '';
    }
    return telefoneFormatado;
}

// this.$router.push(`/admin${rota}`).catch(()=>{});
export const redirecionadorExterno = (pRouter, pUrl) => {
    // Se for rota externa, redireciona por href, senao usa o router.
    if(RegExp('^(?:[a-z]+:)?//', 'i').test(pUrl)) {
        // location.href = pUrl;
        // ou window.location?
        // Nenhum dos dois, abre em nova guia
        window.open(pUrl, '_blank');
    } else {
        pRouter.push(pUrl.substring(0,1) === '/'? pUrl: '/'+pUrl).catch(()=>{});
        //
    }
} 

export const abrirChat = () => {
    setTimeout(() => document.getElementById('kloeWidgetFloat').click(), 50);
}

export function detectSwipe(selector, resolve, reject, deltaMin = 90) {
    const swipe_det = {
      sX: 0,
      sY: 0,
      eX: 0,
      eY: 0
    }
    // Directions enumeration
    const directions = Object.freeze({
      UP: 'up',
      DOWN: 'down',
      RIGHT: 'right',
      LEFT: 'left'
    })
    let direction = null
    const el = document.querySelector(selector)
    if(!el) { 
        reject('seletor invalido')
        return 
    } 

    el.addEventListener('touchstart', function(e) {
      const t = e.touches[0]
      swipe_det.sX = t.screenX
      swipe_det.sY = t.screenY
    }, false)
    el.addEventListener('touchmove', function(e) {
      // Prevent default will stop user from scrolling, use with care
      // e.preventDefault();
      const t = e.touches[0]
      swipe_det.eX = t.screenX
      swipe_det.eY = t.screenY
    }, false)
    el.addEventListener('touchend', function() {
      const deltaX = swipe_det.eX - swipe_det.sX
      const deltaY = swipe_det.eY - swipe_det.sY
      // Min swipe distance, you could use absolute value rather
      // than square. It just felt better for personnal use
      if (deltaX ** 2 + deltaY ** 2 < deltaMin ** 2) return
      // horizontal
      if (deltaY === 0 || Math.abs(deltaX / deltaY) > 1)
        direction = deltaX > 0 ? directions.RIGHT : directions.LEFT
      else // vertical
        direction = deltaY > 0 ? directions.UP : directions.DOWN
  
      if (direction && typeof resolve === 'function') resolve(el, direction)
  
      direction = null
    }, false)
}

export const validarArquivoExtensao = (nomeArquivo) => {
    let extensao = nomeArquivo.split('.').pop();
    const EXTENSOES_ACEITAS = [ 'pdf', 'png', 'jpg', 'jpeg' ];

    if (EXTENSOES_ACEITAS.includes(extensao)) {
        return true;
    }
    return false;
}

export function criaHandTalk() {
    if(!document) {
        return
    }

    var scriptSrc = document.createElement('script');
    scriptSrc.onload = function() {
        //eslint-disable-next-line
        var ht = new HT({
            token: "4bdf53e071f951d75e3f4f492a54be29"
        });
    }
    scriptSrc.setAttribute('src','https://plugin.handtalk.me/web/latest/handtalk.min.js');
    document.body.appendChild(scriptSrc);
}