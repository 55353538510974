import axios from 'axios';

export default {
    namespaced: true,
    state: {
        form: null,
    },
    getters: {
        GET_FORM(state) {
            return state.form;
        },
    },
    mutations: {
        SET_FORM(state, form) {
            state.form = form;
        },
    },
    actions: {
        getFormContato({ dispatch }) {
            dispatch('showOverlay', true, { root: true });
            axios.get("/api/formcontato/1")
                .then((response) => {
                    dispatch('showOverlay', false, { root: true });
                    return dispatch('attempt', response.data.form);
                })
                .catch(err => {
                    dispatch('showOverlay', false, { root: true });
                    return dispatch('failed', err);
                });
        },
        attempt({ commit }, form) {
            if (form)
                console.log(form);
            commit('SET_FORM', form);
        },
        failed(_, err) {
            console.log('falha ao carregar dados do formulario de contato');
            console.log(err);
        },
    },
}