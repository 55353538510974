<template>
    <div class="modal fade" id="modalPopup" ref="modalPopup" tabindex="-1" role="dialog" aria-hidden="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">

                <div class="modal-body modal-body-img">
                    <img :src="popupImagem" class="popup-img" alt="Omni Popup"/>
                </div>

                <div style="text-align:center;">
                    <div v-if="popup.url !== 'null' || ''" class="py-1">
                        <a :href="'https://' + popup.url" target="_blank">Saiba mais.</a>
                    </div>
                    <div class="py-2">
                        <button class="btn btn-sm button-popup" data-dismiss="modal">FECHAR</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import $jquery from 'jquery'
    import axios from 'axios'

    export default {
        name: 'Popup',
        data() {
            return {
                popup: {
                    imagem: '',
                    url: '',
                    ativo: false,
                },
            }
        },
        computed: {
            popupAtivo: function () {
                return this.popup.ativo;
            },
            popupImagem: function () {
                let pathComplete = `/storage/popup/${ this.popup.imagem }`;
                return pathComplete;
            }
        },
        created() {
            this.getPopup();
        },
        methods: {
            getPopup: function () {
                axios.get('/api/popup')
                    .then(res => {
                        const RET_POPUP = res.data.dados;
                        this.popup.imagem = RET_POPUP.imagem;
                        this.popup.url = RET_POPUP.url;
                        this.popup.ativo = RET_POPUP.ativo;
                        this.showPopup();
                    })
                    .catch(err => {
                        console.log(err.data);
                    })
            },
            showPopup: function () {
                if (this.popup.ativo) {
                    $jquery('#modalPopup').modal('show');
                }
            },
        },
    }
</script>

<style scoped>
    .modal-body-img {
        text-align: center;
    }

    .popup-img {
        width: 100%;
        max-height: 500px;
    }

    .button-popup {
        font-weight: 500;
        color: white;
        background-color: var(--laranja-omni);
        width: 5rem;
    }

    .button-popup:hover {
        color: white;
        opacity: 0.8;
    }

    @media (min-width: 500px) {
        .modal-dialog {
            max-width: 34rem !important;
        }
    }

</style>