<template>
    <div class="caixa-resultado-agente-container">
        <div class="container " v-if="agente">

            <div class="row no-gutters">
                <h4 class="caixa-resultado-agente-titulo">{{ agente.nome }}</h4>
            </div>

            <div class="row ">
                <div class="col-6">
                    <div class="container">

                        <div class="row">
                            <p class="caixa-resultado-agente-paragrafo-grande"> Razao social: </p>
                        </div>
                        <div class="row">
                            <p class="caixa-resultado-agente-texto-pequeno"> {{ agente.razao_social }} </p>
                        </div>

                        <div class="row">
                            <p class="caixa-resultado-agente-paragrafo-grande"> Nome Fantasia: </p>
                        </div>
                        <div class="row">
                            <p class="caixa-resultado-agente-texto-pequeno"> {{ agente.nome }} </p>
                        </div>
                    
                    </div>
                </div>

                <div class="col-6">
                        <div class="container">

                        <div class="row">
                            <p class="caixa-resultado-agente-paragrafo-grande"> Endereço: </p>
                        </div>
                        <div class="row">
                            <p class="caixa-resultado-agente-texto-pequeno"> {{ agente.endereco }} </p>
                        </div>
                        <div class="row">
                            <p class="caixa-resultado-agente-texto-pequeno"> {{ agente.bairro }} </p>
                        </div>
                            <div class="row">
                            <p class="caixa-resultado-agente-texto-pequeno"> {{ agente.complemento }} </p>
                        </div>
                        <div class="row">
                            <p class="caixa-resultado-agente-texto-pequeno"> {{ agente.cidade + ', ' + agente.estado }} </p>
                        </div>

                        <div class="row">
                            <p class="caixa-resultado-agente-paragrafo-grande"> CNPJ: </p>
                        </div>
                        <div class="row">
                            <p class="caixa-resultado-agente-texto-pequeno"> {{ agente.cnpj }} </p>
                        </div>
                    
                    </div>
                </div>
            </div>

            <div class="row no-gutters">
                <h4 class="caixa-resultado-agente-servicos-prestados"> Serviços Prestados </h4>
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="container">

                        <div class="row" v-for="(servico, index) in servicosAgente" :key="index">
                            <p class="caixa-resultado-agente-texto-servicos ">
                                
                                {{ '- ' + servico }}
                            </p>
                        </div>
                    
                    </div>
                </div>

                <div class="col-6">
                    <div class="container">

                        <div class="row py-1">
                            <p class="caixa-resultado-agente-texto-pequeno">
                                <span class="caixa-resultado-agente-icone"><img class="caixa-resultado-agente-img" src="@/assets/images/agentes/icone_whats_agentes.png"/></span> 
                                {{ agente.whatsapp.length > 1 ? agente.whatsapp : 'Não disponível' }} 
                            </p>
                        </div>
                        
                        <div class="row py-1">
                            <p class="caixa-resultado-agente-texto-pequeno">
                                <span class="caixa-resultado-agente-icone"><img class="caixa-resultado-agente-img" src="@/assets/images/agentes/icone_telefone_agentes.png"/></span> 
                                {{ agente.telefone.length > 1 ? agente.telefone : 'Não disponível' }}
                            </p>
                        </div>
                    
                        <div class="row py-1">
                            <p class="caixa-resultado-agente-texto-pequeno">
                                <span class="caixa-resultado-agente-icone">{{ agente.email }}</span> 
                            </p>
                        </div>
                    
                    </div>
                </div>
            </div>

            <div class="row no-gutters mb-3" v-if="agente">
                <botao-m class="caixa-resultado-agente-botao-acessar">
                    <router-link :to="'/correspondentes/' + agente.uri" class="caixa-resultado-agente-link">
                        Acessar Página do Correspondente
                    </router-link>  
                </botao-m>
            </div>

        </div>
    </div>
</template>

<script>
import BotaoM from '@/components/botoes/BotaoM';
import { mapGetters } from 'vuex';

export default {
  name: 'CaixaResultadoAgente',
  components: {
      BotaoM,
  },
  props: {
    agente: Object,
  },
  data: function() {
      return {
      }
  },
  computed: {
    ...mapGetters({
      servicos: 'servicos/servicos',
    }),
    servicosAgente() {
        if(this.agente) {
            if(this.agente.servicos && this.servicos) {
                return this.servicos
                            .filter(servico => this.agente.servicos.includes(servico.id))
                            .map(servico => servico.nome)
                            .sort((servico1, servico2) => servico1.nome > servico2.nome? 1:-1 )
            }
        }
        return [];
    },
    whatsapp() {
        if(this.agente) {
            return this.agente.whatsapp;
        } else {
            return '';
        }
    },
    telefone() {
        if(this.agente) {
            return this.agente.telefone;
        } else {
            return '';
        }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.caixa-resultado-agente-container{
    width: 100%;
}

.caixa-resultado-agente-titulo {
    color: var(--azul-claro-omni);
    font-weight: 400;
}

.caixa-resultado-agente-servicos-prestados {
    color: var(--laranja-claro-omni);
    font-weight: 400;
}

.caixa-resultado-agente-paragrafo-grande {
    font-size: 18px;
}

.caixa-resultado-agente-texto-servicos {
    color: var(--azul-claro-omni);
}

.caixa-resultado-agente-texto-pequeno,
.caixa-resultado-agente-texto-servicos,
.caixa-resultado-agente-botao-acessar {
    font-size: 14px;
}

.caixa-resultado-agente-paragrafo-grande,
.caixa-resultado-agente-texto-servicos,
.caixa-resultado-agente-texto-pequeno {
    margin-bottom: 3px;
}

.caixa-resultado-agente-botao-acessar {
    background-color: var(--azul-claro-omni) !important;
    height: 49px !important;
    margin-top: 15px;
}

.caixa-resultado-agente-icone {
    max-height: 15px;
    margin-right: 6px;
}

.caixa-resultado-agente-img {
    width: auto;
    height: 1.8rem;
    position: relative;
    top: -2px;
}

.caixa-resultado-agente-link {
    color: #fff;
}

</style>