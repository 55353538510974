<template>
    <div class="mapa-google-container">
        <GmapMap
            :options="{
            }"
            ref="mapa"
            :center="center"
            :zoom="zoom"
            map-type-id="roadmap"
            style="width: 100%; height: 500px;"
        >

          <GmapMarker
              :position="marker.position"
              :clickable="true"
              :draggable="true"
              @click="center=marker.position"
          />

        </GmapMap>
    </div>
</template>

<script>
import { loadGmapApi, gmapApi } from "vue2-google-maps";
import { chaveMaps } from '@/config/googleConfig';

export default {
name: 'MapaGoogle',
  components: {
  },
  props: {
    id_comp: Number,
    componente: Object,
  },
  data() {
    return {
      center: {
        lat: -23.5782626,
        lng: -46.6712658
      },
      zoom: 18,
      marker: {
        position: {
          lat: -23.5782626,
          lng: -46.6712658
        }
      },
    };
  },
  computed: {
    google: gmapApi,
  },
  beforeMount() { 
    if(!this.google) {
      loadGmapApi({
        key: chaveMaps,
        libraries: "places",
        language: "pt"
      });
    }
  },
};
</script>

<style>
.mapa-google-container {
    width: 100%;
    margin-top: 20px;
    border: 1px solid black;
}
</style>