<template>
    <div id="banners">
        <div id="carousel_banners" class="carousel slide" data-ride="carousel" data- data-interval="4000">

            <div class="carousel-inner">
                <div class="carousel-item" v-for="(banner, index) in ListaBanners" :key="index" v-bind:class="{active:index === 0}">

                    <div>
                        <s-router-link v-if="banner.tipoClique === 'imagem'" 
                                       :url="banner.tipoRedirecionamento === 'arquivo'? banner.urlArquivo : banner.urlPagina">
                            <img class="d-block w-100" :src="'/storage/banners/imagens/' + banner.urlImagem" />
                        </s-router-link>

                        <img v-if="banner.tipoClique !== 'imagem'" class="d-block w-100" :src="'/storage/banners/imagens/' + banner.urlImagem" />
                    </div>

                    <div class="d-block banner-texto" v-bind:class="banner.posicaoHorizontal">
                        <div class="row m-0">
                            <h1 class="banner-principal-titulo" :style="{color: banner.corTitulo}">{{banner.textoTitulo}}</h1>

                            <h1 class="banner-principal-titulo pb-3" :style="{color: banner.corSubtitulo}">{{banner.textoSubtitulo}}</h1>

                            <div class="banner-principal-conteudo" v-html="banner.textoConteudo"></div>
                        </div>

                        <div class="row m-0 pt-3" v-if="banner.tipoClique === 'botao'">
                            <s-router-link
                                           :style="{backgroundColor: banner.corBotao}" class="btn banner-button"
                                           :url="banner.tipoRedirecionamento === 'arquivo'? banner.urlArquivo : banner.urlPagina">
                                {{banner.textoBotao}}
                            </s-router-link>
                        </div>
                    </div>

                </div>
            </div>
            
            <ol class="carousel-indicators">
                <li v-for="(banner, index) in ListaBanners" :key="index" v-bind:class="{active:index === 0}" data-target="#carousel_banners" :data-slide-to="index">
                    <div class="banners-home-dots-class"></div>
                </li>
            </ol>

        </div>

    </div>
</template>

<script>
    import { LISTA_CORES } from '@/assets/js/dicionarioPaginas'
    import SRouterLink from '@/components/SRouterLink';
    import axios from 'axios'
    import $ from 'jquery'

    export default {
        name: 'BannersHome',
        components: {
            SRouterLink,
        },
        data: function () {
            return {
                banners: [],
            }
        },
        computed: {
            ListaBanners() {
                let result = this.banners.filter((banner) => {
                    if (window.innerWidth > 850) {
                        return banner.localizacao.toLowerCase() === 'web';
                    }
                    return banner.localizacao.toLowerCase() === 'mobile';
                });
                return result;
            } 
        },
        methods: {
            GetBanners: function () {
                axios.get('/api/banners')
                    .then(res => {
                        this.FiltrarCores(res.data.banners);
                    })
                    .catch((err) => {
                        console.log(err.response);
                    })
            },
            FiltrarCores: function (banners) {
                if (banners) {
                    banners.map(banner => {
                        if (banner.corBotao) {
                            const botao = LISTA_CORES.find(x => x.valor === banner.corBotao);
                            if (botao) {
                                banner.corBotao = botao.var_hexa;
                            }
                        }
                        if (banner.corTitulo) {
                            const titulo = LISTA_CORES.find(x => x.valor === banner.corTitulo);
                            if (titulo) {
                                banner.corTitulo = titulo.var_hexa;
                            }
                        }
                        if (banner.corSubtitulo) {
                            const subtitulo = LISTA_CORES.find(x => x.valor === banner.corSubtitulo);
                            if (subtitulo) {
                                banner.corSubtitulo = subtitulo.var_hexa;
                            }
                        }
                    });
                    this.banners = banners;
                }
            },
        },
        created() {
            this.GetBanners();
            $('.carousel').carousel();
        },
    }
</script>

<style>
    #banners {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        font-family: MuseoSans;
        background-color: white;
    }

    @media only screen and (max-width: 500px) {
        #banners {
            min-height: 600px;
        }
    }

    @media only screen and (min-width: 501px) and (max-width: 1025px) {
        #banners {
            min-height: 250px;
        }
    }

    @media only screen and (min-width: 1026px) {
        #banners {
            min-height: 400px;
        }
    }

    .banner-principal-titulo {
        font-weight: 900;
        word-wrap: normal !important;
        font-size: clamp(27px, 2.6vw, 40px);
    }

    .banner-texto {
        width: 40%;
        margin: 0 5%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    @media only screen and (max-width: 1026px) {
        .banner-texto {
            width: 100%;
            margin: 0;
            padding: 20px 25px;
            position: relative;
            top: 0;
            transform: translateY(0);
        }
    }

    .carousel-item {
        position: relative;
        padding: 0;
    }

    ol.carousel-indicators {
        position: relative !important;
        width: 100%;
        margin: 0;
    }

    .banner-principal-conteudo {
        line-height: normal;
        font-size: 14px;
        width: 82%;
    }

    .carousel-indicators li {
        opacity:unset !important;
        border-radius: 50%;
        width: 20px !important;
        height: 20px !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
    }

    .banners-home-dots-class {
        background-color: var(--cinza-omni) !important;
        height: 6px;
        border-radius: 3px;
        width: 25px;
    }
    .carousel-indicators li.active .banners-home-dots-class {
        background-color: var(--azul-claro-omni) !important;
    }

    .banner-button {
        color: white !important;
        min-width: 20% !important;
        padding: 8px 55px !important;
        width: auto !important;
        margin-bottom: 10px;
        display: block;
        height: auto !important;
    }

    .left {
        left: 0%;
    }

    .right {
        left: 48%;
    }
    .center {
        left: 24%;
    }

    @media only screen and (max-width: 1260px) {
        .left {
            right: 63%;
            left: 5%;
        }

        .right {
            right: 5%;
            left: 63%;
        }

        .center {
            right: 36%;
            left: 40%;
        }
    }

    @media only screen and (max-width: 998px) {
        .left {
            right: 65%;
            left: 5%;
        }

        .right {
            right: 5%;
            left: 65%;
        }

        .center {
            right: 36%;
            left: 40%;
        }

    }
    
    @media only screen and (max-width: 700px) {
        .banner_conteudo {
            padding: 50px 2rem 0 2rem;
            text-align: center;
            position: relative;
            top: unset;
            transform: none;
        }

        .texto-conteudo {
            text-align: center;
            font-size: clamp(2rem,4vw,5rem);
        }

        .left {
            right: unset;
            left: unset;
        }

        .right {
            right: unset;
            left: unset;
        }

        .center {
            right: unset;
            left: unset;
        }
    }
</style>

