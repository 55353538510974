<template>
    <div class="lista-carrossel-container" :style="{ 'background-color': corFundo }">
        <div class="container-fluid" v-if="componente">

            <div class="row mt-2" v-if="componente.titulo">
                <h2 class="lista-carrossel-titulo" :style="{ 'color': corTitulo }">{{componente.titulo}}</h2>
            </div>
            <div class="row mb-3" v-if="componente.sub_titulo">
                <h5 class="lista-carrossel-sub-titulo" :style="{ 'color': corSubTitulo }" >{{componente.sub_titulo}}</h5>
            </div>

            <div class="row mt-2">
                <div class="col-12">
                    <div class="lista-carrossel-slick-wrapper">
                    <div class="lista-carrossel-slick-container">
                        <VueSlickCarousel 
                        v-bind="settings"
                        >
                            <div class="lista-carrossel-container-bloco-icone px-2 py-3" v-for="(item, index) in itens" v-bind:key="index">
                                <lista-icones-bloco-icone 
                                    class="mx-auto mw-60"
                                    :url_icone="item.url_icone"
                                    :titulo="item.titulo"
                                    :sub_titulo="item.sub_titulo"
                                    :texto="item.texto"
                                    :cor_titulo="item.cor_titulo"
                                    :cor_sub_titulo="item.cor_sub_titulo"
                                    :cor_texto="'#fff'"
                                    :link="item.url_redir"
                                    @carregou="trataAltura"
                                    :alturaImg="alturaMaximaImagem"
                                />
                            </div>

                            <template #prevArrow>
                                <span class="lista-carrossel-arrow-esquerda">
                                    <img src="@/assets/images/seta_azul_carrosel.png" class="w-100 lista-carrossel-arrow-img-esquerda" />
                                </span>
                            </template>

                            <template #nextArrow>
                                <span class="lista-carrossel-arrow-direita">
                                    <img src="@/assets/images/seta_azul_carrosel.png" class="w-100 lista-carrossel-arrow-img-direita" />
                                </span>
                            </template>

                            <template #customPaging>
                                <div class="lista-carrossel-dots-class">
                                </div>
                            </template>

                        </VueSlickCarousel>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCor } from '@/assets/js/SharkUtils';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import ListaIconesBlocoIcone from '@/components/page-components/ListaIconesBlocoIcone.vue';

export default {
    name: 'ListaCarrossel',
    components: {
        ListaIconesBlocoIcone,
        VueSlickCarousel,
    },
    props: {
        id_comp: Number,
        componente: Object,
        dotClassProdutos: {
            type: String,
            default: 'lista-carrossel-dots-class'
        },
    },
    computed: {
        itens() {
            if(this.componente) {
                return this.componente.itens? this.componente.itens: [];
            } else {
                return [];
            }
        },
        corTitulo() {
            if(this.componente) {
                return getCor(this.componente.cor_titulo).var_hexa;
            }
            return '#000';
        },
        corSubTitulo() {
            if(this.componente) {
                return getCor(this.componente.cor_sub_titulo).var_hexa;
            }
            return '#000';
        },
        corFundo() {
            if(this.componente) {
            return getCor(this.componente.tema).var_hexa;
            }
            return '#fff';
        },
    },
    data: function () {
        return {
            alturaMaximaImagem: 60,
            settings: {
                dots: true,
                arrows: true,
                focusOnSelect: true,
                infinite: false,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 1,
                initialSlide: 0,
                touchThreshold: 5,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            }
        }
    },
    methods: {
        trataAltura(pAltura) {
            if(pAltura > this.alturaMaximaImagem) {
                this.alturaMaximaImagem = pAltura;
            }
        },
    },
}

</script>

<style>
.lista-carrossel-container {
    width: 100%;
    max-width: 100vw;
    padding-top: 10px;
    padding-bottom: 20px;
}

.lista-carrossel-titulo {
    text-align: center;
    margin: 0 auto;
    font-weight: 900;
}

.lista-carrossel-sub-titulo {
    text-align: center;
    margin: 0 auto;
}

.lista-carrossel-dots-class {
    background-color: var(--cinza-omni);
    height: 6px;
    border-radius: 3px;
    width: 25px;
}
.slick-active > .lista-carrossel-dots-class {
    background-color: var(--azul-claro-omni);
}

.lista-carrossel-arrow-direita,
.lista-carrossel-arrow-esquerda {
    height: 100px;
    width: 50px;
    position: absolute;
}
    .lista-carrossel-arrow-direita::before,
    .lista-carrossel-arrow-esquerda::before {
        content: none;
    }
    .lista-carrossel-arrow-direita:hover,
    .lista-carrossel-arrow-esquerda:hover {
        opacity: 0.7;
    }

.lista-carrossel-arrow-direita {
    right: -7%;
}
.lista-carrossel-arrow-esquerda {
    left: -7%;
}

.lista-carrossel-arrow-img-direita {
    transform: rotate(180deg);
    right: 0px;
}
.lista-carrossel-arrow-img-esquerda {
    transform: rotate(0deg);
}

.lista-carrossel-slick-wrapper {
    max-width: 100vw !important;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 40px;
    margin: 0;
}

.lista-carrossel-slick-container {
    max-width: calc(90% - 150px) !important;
    margin: 0 auto;
}

.lista-carrossel-container-bloco-icone {
    width: 80%;
}


</style>

