<template>
    <div class="header">
        <nav class="navbar navbar-expand-lg navbar-light bg-light px-lg-2 px-xl-5">

            <a class="navbar-brand mx-4 mx-lg-2" href="/">
                <div class="header-logo">
                    <img src="/storage/logomarcas/omni-logo.png" alt="Omni Banco &amp; Financeira">
                </div>
            </a>
            <button style="float: right;" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">

                    <template v-for="(setor, index) in setoresMenu">
                        <header-menu-drop-down 
                        :setor="setor"
                        :key="index"
                        :raiz="true"
                        class="header-menu-button text-nowrap"
                        :classeCustom="index==1? 'classe-custom-azul': 'classe-custom-laranja'"
                        />
                    </template>

                    <li class="nav-item header-menu-button text-nowrap">
                        <router-link class="nav-link botao-header-azul-arredondado font-weight-bold" to="/pagina/area-do-cliente" id="navbarDropdownMenuLink" aria-haspopup="true" aria-expanded="false">Área do Cliente</router-link>
                    </li>

                    <li class="nav-item dropdown header-menu-button text-nowrap">
                        <a class="nav-link botao-header-laranja-arredondado font-weight-bold" href="https://ib.omni.com.br/login" target="_blank" id="navbarDropdownMenuLink"  aria-haspopup="true" aria-expanded="false">Conta Digital</a>
                    </li>

                </ul>
            </div>

            <b-nav-form class="header-pesquisa mx-4" @submit.prevent="pesquisarPaginas">
                <b-input-group size="sm" class="text-nowrap header-input-pesquisa">
                    <b-form-input v-model="textoPesquisa" type="search" placeholder="Digite aqui..."></b-form-input>
                    <b-button-group title="Pesquisar">
                        <button type="submit" class="header-btn-pesquisa" >
                            <b-icon icon="search" font-scale="1"></b-icon>
                        </button>
                    </b-button-group>
                </b-input-group>
            </b-nav-form>

        </nav>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import HeaderMenuDropDown from '@/components/header-components/HeaderMenuDropDown.vue';

    export default {
        name: 'HeaderMenu',
        components: {
            HeaderMenuDropDown,
        },
        data: function() {
            return {
                textoPesquisa: '',
            }
        },
        computed: {
            ...mapGetters({
                setoresMenu: 'setoresMenu/setores',
            })
        },
        created() {
            this.getSetores();
        },
        methods: {
            ...mapActions({
                getSetores: 'setoresMenu/getSetores',
            }),
            pesquisarPaginas: function() {
                if(!this.textoPesquisa) {
                    return;
                }
                this.$router.push({
                    name: 'BuscaPaginasPage',
                    query: { texto: this.textoPesquisa }
                }).catch(() => { 
                    console.log('catch do push') 
                })
            },
        },
        
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .header {
        z-index: 999;
        font-family: MuseoSans;
        height: 100%;
    }

    .header-logo img {
        width: auto;
        height: 60px;
    }

    .header-menu-button {
        font-family: MuseoSans;
        font-weight: 900;
        font-size: 0.9vw;
        padding-left: 1.2rem;
    }

    @media screen and (max-width: 991px) {
        .header-menu-button {
            font-size: 20px;
        }
    }

    .cor-cinza-omni {
        color: #646464;
    }

    .header-input-pesquisa {
        flex-wrap: nowrap;
    }

    .header-input-pesquisa > input, input:focus {
        background-color: transparent;
        border: 0px;
        outline: none;
        box-shadow: none;
        border-radius: 0px;
        font-style: italic;
        font-weight: 500;
        font-size: 0.8vw;
    }
    
    .header-btn-pesquisa, .header-btn-pesquisa:focus {
        color: var(--cinza-escuro-omni);
        background-color: transparent;
        border-color: transparent;
        outline: none;
    }

    .dropdown-menu-ajuste {
        border-radius: 0;
        padding: 0;
        font-size: 0.8rem;
        background-color: #387fc4;
        min-width: 13rem;
        border: none;
        top: 120%;
    }

    .dropdown-menu-ajuste:hover a{
        color: white;
        opacity: 0.8;
    }

    .dropdown-menu-ajuste a {
        color: white;
    }

        .dropdown-menu-ajuste:hover a:hover {
            color: white;
            opacity: 1;
            background-color: #387fc4;
        }

    .caixa-dropdown {
        padding: 1.5rem;
    }

    .caixa-dropdown-azul, .caixa-dropdown-azul a:hover {
        background-color: #253573 !important;
    }

    .caixa-dropdown-laranja, .caixa-dropdown-laranja a:hover {
        background-color: var(--laranja-claro-omni) !important;
        text-align: right;
    }


    @media screen and (min-width: 1025x) and (max-width: 1260px) {
        .header-menu-button {
            font-family: MuseoSans;
            font-size: 1.2vw;
            padding-left: 1rem;
        }
        .header-logo img {
            margin: 0px;
            height: 60px;
            width: auto;
        }
        .header-input-pesquisa > input, input:focus {
            width: 110px;
        }
        .header-pesquisa {
            margin-right: 30px;
        }
    }

    @media screen and (min-width: 992x) and (max-width: 1025px) {
        .header-menu-button {
            font-family: MuseoSans;
            font-size: 1.2vw;
            padding-left: 4px;
        }
        .header-logo img {
            margin: 0px;
            height: 60px;
            width: auto;
        }
        .header-input-pesquisa > input, input:focus {
            width: 110px;
        }
        .header-pesquisa {
            margin-right: 30px;
        }
    }

    @media screen and (min-width: 700px) and (max-width: 991px) {
        .header-input-pesquisa > input, input:focus {
            width: 210px;
            margin-top: 6px;            
            font-size: 20px;
        }
        .header-pesquisa {
            margin-right: 0;
        }
    }

.navbar .dropdown-toggle,
.navbar .dropdown-menu a {
  cursor: pointer;
}

.navbar .dropdown-item.active,
.navbar .dropdown-item:active {
  text-decoration: none;
  background-color: inherit;
}

.navbar .dropdown-item:focus,
.navbar .dropdown-item:hover {
  text-decoration: none;
  background-color: #f8f9fa;
}

@media (min-width: 767px) {
  .navbar .dropdown-toggle:not(.nav-link)::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .5em;
    vertical-align: 0;
    border-bottom: .3em solid transparent;
    border-top: .3em solid transparent;
    border-left: .3em solid;
  }
}

.botao-header-azul-arredondado {
    border-radius: 10px;
    padding: 10px 18px 10px 18px !important;
    background-color: var(--azul-claro-omni);
    color: #f8f9fa !important;
}

.botao-header-laranja-arredondado {
    border-radius: 10px;
    padding: 10px 18px 10px 18px !important;
    background-color: var(--laranja-claro-omni);
    color: #f8f9fa !important;
}
    @media screen and (min-width: 992x) and (max-width: 1024px) {
        .botao-header-laranja-arredondado,
        .botao-header-azul-arredondado {
            padding: 8px 8px 8px 8px !important;
        }
    }

    @media screen and (min-width: 700px) and (max-width: 991px) {
        .botao-header-laranja-arredondado,
        .botao-header-azul-arredondado {
            margin-top: 4px;
            width: 250px !important;
            text-align: center;
        }
    }

@media (min-width: 767px) {
  .navbar .dropdown-toggle:not(.nav-link)::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .5em;
    vertical-align: 0;
    border-bottom: .3em solid transparent;
    border-top: .3em solid transparent;
    border-left: .3em solid;
  }
}

.botao-header-azul-arredondado:hover,
.botao-header-laranja-arredondado:hover {
    opacity: 0.7;
}

</style>