<template>
    <div class="input-boleto-container">
        <input v-model="numeroLinhaDigitavel" type="number" class="input-boleto-input my-1" placeholder="Código de barras">
        <input v-model="numeroContrato" type="number" class="input-boleto-input my-1" placeholder="Nº Contrato">
        <div class="d-flex my-1">
            <input v-model="numeroCpf" type="number" class="input-boleto-input mr-2" placeholder="Nº CPF">
            <button class="input-boleto-botao" @click="validarBoleto">
                <img src="@/assets/images/seta_branca.png" class="input-boleto-img-botao mx-auto"/>
            </button>
        </div>
    </div>
</template>

<script>
import BoletoValidator from '@/assets/js/BoletoValidator';

export default {
    name: 'InputBoleto',
    data: function() {
        return {
            numeroLinhaDigitavel: '',
            numeroContrato: '',
            numeroCpf: ''
        }
    },
    methods: {
        validarBoleto: function () {
            this.$store.dispatch('showOverlay', true);
            BoletoValidator.boletoOmni({ 
                linhaDigitavel: this.numeroLinhaDigitavel,
                contrato: this.numeroContrato,
                cpf: this.numeroCpf
             },
            (resultadoValidacaoBoleto) => {
                this.$emit('click', resultadoValidacaoBoleto);
            });
        },
    },
}
</script>

<style scoped>
.input-boleto-input {
    min-height: 50px;
    border: none;
    border-radius: 10px;
    width: 100%;
    padding: 2px 24px;
    background-color: #fff;
    outline: none;
}

.input-boleto-botao {
    border: none;
    border-radius: 10px;
    outline: none !important;
    min-width: 55px !important;
    height: 50px;
    background-color: var(--laranja-claro-omni);
}
    .input-boleto-botao:hover {
        opacity: 0.7;
    }

.input-boleto-img-botao {
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.input-boleto-container 
    input[type=number],
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -moz-appearance: textfield;
        -webkit-appearance: none;
        margin: 0;
    }

</style>