<template>
    <component :is="isTag" :href="url" :to="urlInterna" :target="urlExterna?'_blank':null">
        <slot></slot>
    </component>
</template>

<script>

export default {
    name: 'SRouterLink',
    components: {
    },
    props: {
        url: {
            type: String,
            default: ''
        },
    },
    computed: {
        urlExterna() {
            if (RegExp('^(?:[a-z]+:)?//', 'i').test(this.url) || this.url.startsWith('/storage')) {
                return this.url
            } else {
                return null;
            }
        },
        urlInterna() {
            if(!this.url) {
                return '';
            }
            return this.url.substring(0,1) === '/'? this.url: '/'+this.url;
        },
        isTag() {
            if(!this.urlExterna) {
                return 'router-link';
            } else {
                return 'a';
            }
        },
    },
    methods: {
    },
    data: function() {
        return {
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>