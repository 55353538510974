<template>
  <div class="container-renderizador">
    <div class="container container-modificado-w-100">

        <div class="row" 
        v-for="(comp, index) in componentes" 
        :key="index"
        :id="comp && comp.posicao !== undefined && comp.posicao !== null? `parte-${comp.posicao}`: null"
        >
            <component
                :is="comp.tipo"
                v-bind:key="comp.id"
                :componente="comp"
                :id_comp="comp.id"
            ></component>
        </div>
        
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BannerPrincipal from '@/components/page-components/BannerPrincipal.vue';
import ImgDireita from '@/components/page-components/ImgDireita.vue';
import ImgEsquerda from '@/components/page-components/ImgEsquerda.vue';
import ListaIcones from '@/components/page-components/ListaIcones.vue';
import RowsCentralizadas from '@/components/page-components/RowsCentralizadas.vue';
import FormContato from '@/components/page-components/FormContato.vue';
import FormAgentes from '@/components/page-components/FormAgentes.vue';
import FaixaTextoImagem from '@/components/page-components/FaixaTextoImagem.vue';
import ColunasBotaoImg from '@/components/page-components/ColunasBotaoImg.vue';
import ListaCarrossel from '@/components/page-components/ListaCarrossel.vue';
import Faq from '@/components/page-components/Faq.vue';
// Novos para home
import BannersHome from '@/components/home-components/BannersHome.vue'
import LinhaProdutos from '@/components/home-components/LinhaProdutos.vue'
import ValidaBoleto from '@/components/home-components/ValidaBoleto.vue'
import UltimasNoticias from '@/components/home-components/UltimasNoticias.vue'
import ImgDireitaHome from '@/components/home-components/ImgDireitaHome.vue'
import ImgEsquerdaHome from '@/components/home-components/ImgEsquerdaHome.vue'

//--
import MapaGoogle from '@/components/page-components/MapaGoogle.vue';

export default {
  name: 'RenderizadorPage',
  components: {
    "BANNER_PADRAO": BannerPrincipal,
    "IMG_DIREITA": ImgDireita,
    "LISTA_ICONES": ListaIcones,
    "LISTA_ICONES_ESQ": ListaIcones,
    "IMG_ESQUERDA": ImgEsquerda,
    "AGENTES": FormAgentes,
    "CONTATO": FormContato,
    "ROWS_CENTRALIZADAS": RowsCentralizadas,
    "IMAGEM_FAIXA_TEXTO": FaixaTextoImagem,
    "COLUNAS_BOTAO_IMG": ColunasBotaoImg,
    "LISTA_CARROSSEL" :ListaCarrossel,
    "FAQ": Faq,

    "BANNER_HOME": BannersHome,
    "LINHA_PRODUTOS": LinhaProdutos, 
    "VALIDA_BOLETO": ValidaBoleto,
    "ULTIMAS_NOTICIAS": UltimasNoticias,
    "MAPA_GOOGLE": MapaGoogle,
    "IMG_DIREITA_HOME": ImgDireitaHome,
    "IMG_ESQUERDA_HOME": ImgEsquerdaHome,
  },
  props: {
      url: {
          type: String,
          default: '/',
      }
  },
  data: function() {
      return {
          id_pagina: 0,
      }
  },
  computed: {
    ...mapGetters({
        listaPaginas: 'paginas/paginas',
    }),
    pagina: function() {
        let pagina = this.listaPaginas? 
                        this.listaPaginas
                            .find(pagina => {
                                return pagina.url_setor === this.url;
                            })
                        : {};

        return pagina; 
    },
    componentes: function() {
        if(this.pagina) {
            return this.pagina.componentes? this.pagina.componentes: [];
        } else {
            return [];
        }
    },
  },
  watch: {
      pagina(novaPagina) {
          if(novaPagina && novaPagina.titulo) {
            document.title = `Omni Banco & Financeira - ${novaPagina.titulo}`
          }
      }
  },
  created() {
  },
  mounted() {
    if(this.pagina && this.pagina.titulo) {
        document.title = `Omni Banco & Financeira - ${this.pagina.titulo}`
    }
  },
}
</script>

<style scoped>
.container-modificado-w-100 {
    max-width: 100% !important;
}
</style>