exports.boletoOmni = boletoOmni;

function boletoOmni(dadosBoleto, callback) {
    const API_OMNI_VALIDADOR = "https://hmg-api.omni.com.br/autoatendimento-cliente/api/check-boleto";

    fetch(API_OMNI_VALIDADOR, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify(dadosBoleto)
    })
        .then(function (resp) {
            resp.json()
                .then(function (data) {
                    callback(data);
                })
        })
        .catch(function (error) {
            console.log("Erro ao validar boleto: " + error);
        })
}
