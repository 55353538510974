<template>
    <div class="rows-centralizadas-container" :style="{ 'background-color': corFundo }">
        <div class="container">
            <div class="row row-centralizada-componente" v-if="componente.titulo">
                <h3 class="rows-centralizadas-titulo" :style="{ 'color': corTitulo }">{{ componente.titulo }}</h3>
            </div>

            <div class="row row-centralizada-componente py-1" v-if="urlImagem">
                <div class="col-8 mx-auto">
                    <div class="img-icone-lista-container">
                        <img class="img-icone-lista" :src="urlImagem" alt="icone"/>
                    </div>
                </div>
            </div>

            <div class="row row-centralizada-componente py-1" v-if="temBotao1">
                <botao-m class="px-4 py-3 my-2 rows-centralizadas-botao" @click="redireciona(componente.url_botao_1)" :style="{ 'background-color': corBotao1 }"> {{textoBotao1}} </botao-m>
            </div>

            <div class="row row-centralizada-componente py-1" v-if="componente.sub_titulo">
                <h4 class="rows-centralizadas-sub-titulo" :style="{ 'color': corSubTitulo }">{{ componente.sub_titulo }}</h4>
            </div>

            <div class="row row-centralizada-componente py-1" v-if="componente.texto">
                <span v-html="componente.texto" :style="{ 'color': corTexto }" class="conteudo-html-components"></span>
            </div>

            <div class="row row-centralizada-componente" v-if="temBotao2">
                <botao-m class="px-4 py-3 rows-centralizadas-botao" @click="redireciona(componente.url_botao_2)" :style="{ 'background-color': corBotao2 }"> {{textoBotao2}} </botao-m>
            </div>
        </div>
    </div>
</template>

<script>
import { temasFactory, getCor } from '@/assets/js/SharkUtils';
import { redirecionadorExterno } from '@/assets/js/SharkUtils';
import BotaoM from '@/components/botoes/BotaoM';

export default {
    name: 'RowsCentralizadas',
    components: {
        BotaoM,
    },
    props: {
        id_comp: Number,
        componente: Object,
    },
    computed: {
        tema() {
            if(this.componente) {
                return temasFactory(this.componente.tema);
            }
            return null;
        },
        corFundo() {
            if(this.componente) {
                if(this.componente.tema) {
                    let corLista = getCor(this.componente.tema);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return '#fff';
        },
        corTexto() {
            return '#000';
        },
        temBotao1() {
            if(this.componente) {
                if(this.componente.txt_botao_1) {
                    return this.componente.txt_botao_1.length > 0 ? true: false;
                }
            }
            return false;
        },
        textoBotao1() {
            if(this.componente) {
                if(this.componente.txt_botao_1) {
                    return this.componente.txt_botao_1
                }
            }
            return 'Clique aqui!';
        },
        corBotao1() {
            if(this.componente) {
                if(this.componente.cor_botao_1) {
                    let corLista = getCor(this.componente.cor_botao_1);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--laranja-omni)';
        },
        temBotao2() {
            if(this.componente) {
                if(this.componente.txt_botao_2) {
                    return this.componente.txt_botao_2.length > 0 ? true: false;
                }
            }
            return false;
        },
        textoBotao2() {
            if(this.componente) {
                if(this.componente.txt_botao_2) {
                    return this.componente.txt_botao_2
                }
            }
            return 'Clique aqui!';
        },
        corBotao2() {
            if(this.componente) {
                if(this.componente.cor_botao_2) {
                    let corLista = getCor(this.componente.cor_botao_2);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--laranja-omni)';
        },
        urlImagem() {
            if(this.componente) {
                if(this.componente.url_img) {
                    return this.componente.url_img;
                }
            }
            return '';
        },
        corTitulo() {
            if(this.componente) {
                return getCor(this.componente.cor_titulo).var_hexa;
            }
            return '#000';
        },
        corSubTitulo() {
            if(this.componente) {
                return getCor(this.componente.cor_sub_titulo).var_hexa;
            }
            return '#000';
        },        
    },
    data: function() {
        return {
        }
    },
    methods: {
        redireciona(pUrl) {
            if(pUrl) {
                redirecionadorExterno(this.$router, pUrl);
            }
        },
    },
}
</script>
<style scoped>
.rows-centralizadas-container {
    max-width: 100% !important;
    width: 100% !important;
    padding-top: 30px;
    padding-bottom: 30px;
}

.img-icone-lista {
    width: 100%;
}
.img-icone-lista-container {
  width: 70px;
  margin: auto;
}

.row-centralizada-componente > * {
  margin: 5px auto;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
}

.rows-centralizadas-botao {
    height: auto !important;
    min-width: 250px !important;
    width: auto !important;
    /*margin-bottom: 10px;*/
}

.rows-centralizadas-titulo {
    font-weight: 900;
}

.rows-centralizadas-sub-titulo {
    font-weight: 900;
}

</style>