<template>
    <div class="component-container" :style="{ 'background-color': corFundo }">
        <div class="container-fluid pr-0" v-bind:style="{ 'background-color': corFundo }">
            <div class="row no-gutters" style="min-height: 150px;">

                <div class="col-md-6 ml-auto" style="text-align:start" >

                        <div class="img-direita-body-content">
                            <div class="container-body-card-centralizado-vertical">

                                <div class="container py-4">
                                    <div class="row py-1" v-if="componente.titulo">
                                        <h3 class="img-direita-titulo" :style="{ 'color': corTitulo }"> {{ componente.titulo }} </h3>
                                    </div>
                                    <div class="row pb-2" v-if="componente.sub_titulo">
                                        <h5 class="img-direita-sub-titulo" :style="{ 'color': corSubTitulo }"> {{ componente.sub_titulo }} </h5>
                                    </div>
                                    <div class="row pb-2" v-if="componente.texto">
                                        <span class="conteudo-html-components" v-html="componente.texto" :style="{ 'color': corTexto }"></span>
                                    </div>
                                    <div class="row py-2" v-if="temBotao1">
                                        <botao-m @click="redireciona(componente.url_botao_1)" :style="{ 'background-color': corBotao1 }" class="img-direita-botao"> {{textoBotao1}} </botao-m>
                                    </div>
                                    <div class="row" v-if="temBotao2">
                                        <botao-m @click="redireciona(componente.url_botao_2)" :style="{ 'background-color': corBotao2 }" class="img-direita-botao"> {{textoBotao2}} </botao-m>
                                    </div>
                                </div>

                            </div>
                        </div>

                </div>

                <div class="col-md-6 pr-0">
                    <div class="img-direita-container-imagem">
                        <img class="img-direita-imagem"
                            :src="urlImagem" 
                            alt="Imagem"
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCor } from '@/assets/js/SharkUtils';
import { LISTA_CORES } from '@/assets/js/dicionarioPaginas';
import { redirecionadorExterno } from '@/assets/js/SharkUtils';
import BotaoM from '@/components/botoes/BotaoM';


export default {
    name: 'ImgDireitaHome',
    components: {
        BotaoM,
    },
    props: {
        id_comp: Number,
        componente: Object,
    },
    computed: {
        corFundo() {
            if(this.componente) {
                if(this.componente.tema) {
                    let corLista = getCor(this.componente.tema);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return '#fff';
        },
        corTexto() {
            return '#000';
        },
        temBotao1() {
            if(this.componente) {
                if(this.componente.txt_botao_1) {
                    return this.componente.txt_botao_1.length > 0 ? true: false;
                }
            }
            return false;
        },
        textoBotao1() {
            if(this.componente) {
                if(this.componente.txt_botao_1) {
                    return this.componente.txt_botao_1
                }
            }
            return 'Clique aqui!';
        },
        corBotao1() {
            if(this.componente) {
                if(this.componente.cor_botao_1) {
                    let corLista = getCor(this.componente.cor_botao_1);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--laranja-omni)';
        },
        temBotao2() {
            if(this.componente) {
                if(this.componente.txt_botao_2) {
                    return this.componente.txt_botao_2.length > 0 ? true: false;
                }
            }
            return false;
        },
        textoBotao2() {
            if(this.componente) {
                if(this.componente.txt_botao_2) {
                    return this.componente.txt_botao_2
                }
            }
            return 'Clique aqui!';
        },
        corBotao2() {
            if(this.componente) {
                if(this.componente.cor_botao_2) {
                    let corLista = getCor(this.componente.cor_botao_2);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--laranja-omni)';
        },
        urlImagem() {
            if(this.componente) {
                if(this.componente.url_img) {
                    return this.componente.url_img;
                }
            }
            return require('../../assets/images/placeholders/img_placeholder.png');
        },
        corTitulo() {
            if(this.componente) {
                if(this.componente.cor_titulo) {
                    let corLista = getCor(this.componente.cor_titulo);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return '#000';
        },
        corSubTitulo() {
            if(this.componente) {
                if(this.componente.cor_sub_titulo) {
                    let corLista = getCor(this.componente.cor_sub_titulo);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return '#000';
        },
    },
    data: function() {
        return {
            listaCores: LISTA_CORES,
        }
    },
    methods: {
        redireciona(pUrl) {
            if(pUrl) {
                redirecionadorExterno(this.$router, pUrl);
            }
        },
    },
}
</script>
<style scoped>

.component-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    width: 100%;
    min-height: 150px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.img-direita-body-content {
    width: 75%;
    margin-left: 15%;
    height: 100%;
    position: relative;
}

.container-body-card-centralizado-vertical {
    font-family: MuseoSans;
    font-weight: 900;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.img-direita-botao {
    height: 3em !important;
    width: 80% !important;
    max-width: 300px;
    margin-bottom: 10px;
}

.img-direita-container-imagem {
    width: 100%;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.img-direita-imagem {
    width: 100%;
    height: auto;
}

.img-direita-titulo {
    font-weight: 900;
}

.img-direita-sub-titulo {
    font-weight: 900;
}

</style>