<template>
  <b-container fluid class="text-center" style="padding-right: 0px; padding-left: 0px">
      <b-img class="mx-auto px-auto" :src="urlImagem"></b-img>
      <b-card class="text-center w-100" :style="{ 'background-color':corFundo }">
          <b-card-text class="my-4 mx-auto w-75" :style="{ 'color': corTexto }" ><span v-html="componente.texto"></span></b-card-text>
      </b-card>
  </b-container>
</template>

<script>
import { getCor } from '@/assets/js/SharkUtils';
export default {
    name: 'FaixaTextoImagem',
    data: function(){
        return {}
    },
    props: {
        id_comp: Number,
        componente: Object,
    },
    computed:{
        corFundo() {
            if(this.componente) {
                if(this.componente.tema) {
                    let corLista = getCor(this.componente.tema);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return '#fff';
        },
        corTexto() {
            if(this.componente) {
                if(this.componente.cor_texto) {
                    let corLista = getCor(this.componente.cor_texto);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return '#fff';
        },
        urlImagem() {
            if(this.componente) {
                if(this.componente.url_img) {
                    return this.componente.url_img;
                }
            }
            return require('../../assets/images/placeholders/img_placeholder.png');
        },
    }
}
</script>

<style scoped>
</style>