// Views
const LoginAdmPage = () => import(/* webpackChunkName: "bloco-admin" */'../views/admin/LoginAdmPage.vue')
const UsuariosPage = () => import(/* webpackChunkName: "bloco-admin" */'../views/admin/UsuariosPage.vue')
const MostradorComponentesPage = () => import(/* webpackChunkName: "bloco-admin" */'../views/admin/MostradorComponentesPage.vue')
const PainelAdmPage = () => import(/* webpackChunkName: "bloco-admin" */'../views/admin/PainelAdminPage.vue')
const PainelAdmPaginasPage = () => import(/* webpackChunkName: "bloco-admin" */"../views/admin/PainelAdmPaginasPage.vue")
const PainelAdmPopupsPage = () => import(/* webpackChunkName: "bloco-admin" */"../views/admin/PainelAdmPopupsPage.vue")
const PainelAdmRodapePage = () => import(/* webpackChunkName: "bloco-admin" */"../views/admin/PainelAdmRodapePage.vue")
const PainelAdmSetoresPage = () => import(/* webpackChunkName: "bloco-admin" */"../views/admin/PainelAdmSetoresPage.vue")
const PainelAdmAgentesPage = () => import(/* webpackChunkName: "bloco-admin" */"../views/admin/PainelAdmAgentesPage.vue")
const PainelAdmFerramentasPage = () => import(/* webpackChunkName: "bloco-admin" */"../views/admin/PainelAdmFerramentasPage.vue")
const PainelAdmBlogPage = () => import(/* webpackChunkName: "bloco-admin" */'../views/admin/PainelAdmBlogPage.vue')

export default [
    {
        path: '/admin/acesso',
        name: 'LoginAdmPage',
        component: LoginAdmPage,
    },
    {
        path: '/admin',
        component: PainelAdmPage,
        children: [
            {
                path: '/',
                name: 'PainelAdmSetoresPage',
                component: PainelAdmSetoresPage,
                meta: { requiresAuth: true, pageAdmin: true },
            },
            {
                path: 'blog',
                name: 'PainelAdmBlogPage',
                component: PainelAdmBlogPage,
                meta: { requiresAuth: true, pageAdmin: true },
            },
            {
                path: 'usuarios',
                name: 'UsuariosPage',
                component: UsuariosPage,
                meta: { requiresAuth: true, pageAdmin: true },
            },
            {
                path: 'rodape',
                name: 'PainelAdmRodapePage',
                component: PainelAdmRodapePage,
                meta: { requiresAuth: true, pageAdmin: true },
            },
            {
                path: 'mostrador',
                name: 'MostradorComponentesPage',
                component: MostradorComponentesPage,
                meta: { requiresAuth: true, pageAdmin: true },
            },
            {
                path: 'paginas',
                name: 'PainelAdmPaginasPage',
                component: PainelAdmPaginasPage,
                meta: { requiresAuth: true, pageAdmin: true },
            },
            {
                path: 'popups',
                name: 'PainelAdmPopupsPage',
                component: PainelAdmPopupsPage,
                meta: { requiresAuth: true, pageAdmin: true },
            },
            {
                path: 'agentes',
                name: 'PainelAdmAgentesPage',
                component: PainelAdmAgentesPage,
                meta: { requiresAuth: true, pageAdmin: true },
            },
            {
                path: 'ferramentas',
                name: 'PainelAdmFerramentasPage',
                component: PainelAdmFerramentasPage,
                meta: { requiresAuth: true, pageAdmin: true },
            },
        ]
    },
    
]
