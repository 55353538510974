
import axios from 'axios';

export default {
    namespaced: true,
    state: {
        admAuth: !!localStorage.getItem('admtoken'),
        admin: localStorage.getItem('admin')
    },

    getters: {
        admAuth(state) {
            return state.admAuth
        },
        admUser(state) {
            return state.admin
        }
    },

    mutations: {
        SET_ADMAUTH(state, auth) {
            state.admAuth = auth;
        },
        SET_ADMIN(state, dados) {
            state.admin = dados
        }
    },

    actions: {
        async signIn({ dispatch }, credentials) {
            try {
                const response = await axios.post('/api/auth/gerencia', credentials)
                if (response.status === 200 && response.data.dados) {
                    return dispatch('attempt', response.data.dados);
                } else {
                    return dispatch('failed', null);
                }

            } catch(err) {
                return dispatch('failed', err);
            }
        },
        
        async attempt({ commit }, dados) {
            if(dados && dados.access_token) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + dados.access_token
            } else {
                commit('SET_ADMAUTH', false);
                commit('SET_ADMIN', null);
                localStorage.setItem('admin', '')
                localStorage.setItem('admtoken', '')
                return;
            }

            try {
                const response = await axios.get('api/auth/me');
                if(response && response.status === 200) {
                    commit('SET_ADMAUTH', true);
                    commit('SET_ADMIN', dados);
                    localStorage.setItem('admin', JSON.stringify(dados))
                    localStorage.setItem('admtoken', dados.access_token)
                } else {
                    commit('SET_ADMAUTH', false);
                    commit('SET_ADMIN', null);
                    localStorage.setItem('admin', '')
                    localStorage.setItem('admtoken', '')
                }
                return;
            } catch(err) {
                console.log(err);
                commit('SET_ADMAUTH', false);
                commit('SET_ADMIN', null);
                localStorage.setItem('admin', '')
                localStorage.setItem('admtoken', '')
                return;
            }
        },
        signOut ({ commit }) {
            commit('SET_ADMAUTH', false);
            commit('SET_ADMIN', null);
            localStorage.setItem('admin', '');
            localStorage.setItem('admtoken', '');
        },

        failed(_, err) {
            console.log('login failed');
            console.log(err);
        },
    },
}