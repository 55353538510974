<template>
    <button class="btn botao-medio-desktop-omni" v-on:click="$emit('click')">
        <slot></slot>
    </button>    
</template>

<script>
export default {
    name: 'BotaoM',
    props: {
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

button.botao-medio-desktop-omni  {
    background-color: var(--laranja-claro-omni);
    min-width: 90px;
    height: 3em;
    color: #fff;
    border-radius: 8px;
    border: none;
    outline: none;
    padding-left: 3em;
    padding-right: 3em;
}

button.botao-medio-desktop-omni:disabled {
    color: var(--cinza-omni);
}

button.botao-medio-desktop-omni:hover {
    opacity: 0.7;
}

</style>