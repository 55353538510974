<template>
    <button 
        class="btn botao-pequeno-omni" 
        :disabled="!enabled"
        v-on:click="$emit('click')"
        v-bind:style="{ color: txt_color, 'background-color': backgroundColor,  width: largura, height: altura }"
    >
        <span aria-hidden="true">{{texto_botao}}</span>
        <slot></slot>
    </button>    
</template>

<script>
export default {
    name: 'BotaoP',
    props: {
        texto_botao: {
            type: String,
            default: '>'
        },
        background_color: {
            type: String,
            default: 'var(--laranja-claro-omni)'
        },
        background_color_disabled: {
            type: String,
            default: 'var(--cinza-claro-omni)'
        },
        txt_color: {
            type: String,
            default: '#fff'
        },
        largura: {
            type: String,
            default: '60px'
        },
        altura: {
            type: String,
            default: '60px'
        },
        enabled: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        backgroundColor() {
            if(this.enabled) {
                return this.background_color
            }
            return this.background_color_disabled;
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    button.botao-pequeno-omni  {
        border: none;
        border-radius: 10px;
        outline: none;
    }
    button.botao-pequeno-omni:hover {
        opacity: 0.7;
    }
    .botao-pequeno-omni  > span {
        font-family: Museo;
        display: inline-block;
        font-size: 18px;
        text-align: center;
        line-height: 1em;
    }
</style>