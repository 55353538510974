<template>
    <div class="valida-boleto-container">
        <div class="container-fluid">
            <div class="row">

                <div class="col-md-6" style="background-color:var(--cinza-claro-omni);">
                    <div class="valida-boleto-container-img mx-auto">
                        <img src="@/assets/images/img_boleto.svg" class="valida-boleto-img"/>
                    </div>
                </div>

                <div class="col-md-6" style="background-color:var(--azul-claro-omni); color: #fff; min-height:450px;">
                    <div class="container-fluid valida-boleto-alinha-vertical pt-2 pb-2 px-5">

                        <div class="row">
                            <h2 class="valida-boleto-texto-900 mx-auto mb-0 text-center">Não caia em fraudes.</h2>
                        </div>
                        <div class="row pb-2">
                            <h2 class="valida-boleto-texto-900 mx-auto text-center">Valide aqui seu boleto da Omni.</h2>
                        </div>
                        <div class="row pb-2">
                            <p class="valida-boleto-texto-500 mx-auto text-center">Digite o código numérico do seu boleto para verificar a autenticidade:</p>
                        </div>

                        <div class="row">
                            <div class="col-9 mx-auto">
                                <input-boleto @click="validaBoleto" />
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import InputBoleto from '@/components/inputs/InputBoleto.vue';

export default {
    name: 'ValidaBoleto',
    components: {
        InputBoleto,
    },
    props: {
        id_comp: Number,
        componente: Object,
    },
    methods: {
        validaBoleto: function (resultadoValidacao) {
            const boletoValido = resultadoValidacao?.encontrouBoleto;

            let options = {
                title: resultadoValidacao?.mensagemCliente,
                size: resultadoValidacao?.mensagemCliente ? 'lg' : 'sm',
                buttonSize: 'lg',
                okVariant: boletoValido === true ? 'success':'danger',
                okTitle: 'Entendi',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }

            this.$store.dispatch('showOverlay', false);

            if (resultadoValidacao.status.toUpperCase() === "FAIL") {
                this.$bvModal.msgBoxOk(resultadoValidacao.messages[0], options)
                return;
            }

            if (resultadoValidacao.status.toUpperCase() === "SUCCESS") {
                if (!resultadoValidacao.encontrouBoleto) {
                    let msgModalInvalido = `Verifique se a digitação está correta ou entre em contato com nosso SAC 
                                para mais informações: 0800 727 0885 (Atendimento para todos os produtos, 24 horas, 7 dias por semana). 
                                Se preferir, fale direto com o Correspondente Bancário Omni da sua região.`

                    this.$bvModal.msgBoxOk(msgModalInvalido, options)
                    return;
                }

                if (resultadoValidacao.encontrouBoleto) {
                    let msgModalValido = 'Você pode efetuar o pagamento deste boleto com segurança.'

                    this.$bvModal.msgBoxOk(msgModalValido, options)
                    return;
                }
            }
            return;
        },
    },
}
</script>

<style scoped>
.valida-boleto-container {
    width: 100%;
    max-width: 100%;
    margin-top: 35px;
    margin-bottom: 20px;
}

.valida-boleto-row-resultado {
    min-height: 70px;
}

.valida-boleto-container-img {
    position: relative;
    max-width: 75%;
    min-width: 200px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.valida-boleto-img {
    width: 100%;
    height: auto;
}

.valida-boleto-texto-500 {
    font-weight: bold;
}

.valida-boleto-texto-900 {
    font-weight: 900;
}

.valida-boleto-alinha-vertical {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

</style>

