<template>
    <div>
        <seja-correspondente></seja-correspondente>
        <banner-agentes></banner-agentes>
        <precadastro-agente></precadastro-agente>
    </div>
</template>

<script>
    import SejaCorrespondente from '@/components/agentes-components/SejaAgente.vue'
    import BannerAgentes from '@/components/agentes-components/BannerSejaAgente.vue'
    import PrecadastroAgente from '@/components/agentes-components/PrecadastroAgente.vue'

    export default {
        name: 'SejaAgentePage',
        components: {
            SejaCorrespondente,
            BannerAgentes,
            PrecadastroAgente
        },
    }
</script>

<style scoped>
</style>