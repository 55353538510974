<template>
    <div class="encontre-agente-page-container">
        <div class="container encontre-agente-page-w-100">

            <div class="row encontre-agente-page-row-inicial">
                <div class="encontre-agente-page-container-imagem-inicial">
                    <img class="encontre-agente-page-imagem-inicial" src="@/assets/images/agentes/agente_omni_busca.png"/>
                </div>
            </div>

            <div class="row encontre-agente-page-segunda-row">
                <div class="col-md-5 mx-auto mt-5">
                    <div class="container">

                        <div class="row">
                            <h3 class="encontre-agente-page-titulo mt-1 mb-0"> Encontre um Correspondente Bancário Omni </h3>
                        </div>
                        <div class="row">
                            <p class="encontre-agente-page-paragrafo">
                                Estamos presentes onde as pessoas estão, com pontos em todas as regiões do Brasil. Entre em contato com um Correspondente Bancário próximo de você
                            </p>
                        </div>
                        <div class="row">

                            <div class="col-6">
                                <input v-model="cep" type="text" class="encontre-agente-page-input-cep" placeholder="Insira seu CEP" >
                            </div>

                            <div class="col-6">
                                <select v-model.number="produto" class="encontre-agente-page-select-produto">
                                    <option  :value="0">Produto</option>
                                    <option
                                        v-for="(servico, i) in servicosDisponiveis"
                                        v-bind:key="i"
                                        :value="servico.id"
                                    >
                                        {{ servico.nome }}
                                    </option>
                                </select>
                            </div>
                                    
                        </div>
                        <div class="row">
                            <botao-m class="encontre-agente-page-botao-encontre" @click="redireciona">
                                Buscar Correspondente Bancário
                            </botao-m>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BotaoM from '@/components/botoes/BotaoM';

export default {
    name: 'EncontreAgentePage',
    components: {
        BotaoM,
    },
    data: function() {
        return {
            produto: 0,
            cep: '',
        }
    },
    methods: {
        redireciona: function() {
            // Limpa caracteres nao numericos
            let cepNumeros = this.cep.replace(/\D/g,'');
            this.$router.push({ name: 'ResultadoAgentesPage', query: { cep: cepNumeros, produto: this.produto } });
        },
    },
    computed: {
        ...mapGetters({
            servicosDisponiveis: 'servicos/servicos',
        }),
    },
        
}

</script>

<style scoped>
.encontre-agente-page-container {
    width: 100%;
    min-height: 900px;
}

.encontre-agente-page-w-100 {
    max-width: 100% !important;
}
.encontre-agente-page-row-inicial {
    background-color: var(--azul-claro-omni);
    min-height: 375px;
}
.encontre-agente-page-container-imagem-inicial {
    text-align: center;
    height: 290px;
    max-height: 290px;
    position: relative;
    bottom: -165px;
    margin: 0 auto;
}
    .encontre-agente-page-imagem-inicial {
        width: 430px;
        height: auto;
    }

.encontre-agente-page-titulo,
.encontre-agente-page-paragrafo {
    color: var(--chumbo-texto-omni);
    text-align: center;
    margin: 15px auto;
}

.encontre-agente-page-input-cep,
.encontre-agente-page-select-produto {
    border-radius: 6px;
    outline: none;
    border: 1px solid var(--chumbo-texto-omni);
    height: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: small;
    width: 100%;
}

.encontre-agente-page-select-produto {
    color: var(--azul-claro-omni);
    background:
        linear-gradient(45deg, transparent 50%, var(--azul-claro-omni) 50%),
        linear-gradient(135deg, var(--azul-claro-omni) 50%, transparent 50%),
        linear-gradient(to right, #fff, #fff);
    background-position:
        calc(100% - 21px) calc(1em + 2px),
        calc(100% - 16px) calc(1em + 2px),
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;

    /* styling and reset */
    font: 300 1em/100% "Museo Sans", sans-serif;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;

    /* reset */
    margin: 0;      
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance:none;
    -moz-appearance:none;
}

.encontre-agente-page-botao-encontre {
    margin: 30px auto;
    height: auto !important;
    min-width: 55% !important;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}

</style>
