<template>

<b-container fluid class="container-faq" :style="{ 'background-color':corFundo }">
    <b-row>
        <b-col cols="12" sm="12" md="6">
            <p class="titulo-faq text-sm-left text-center" :style="{ 'color':corTitulo }">{{titulo}}</p>
        </b-col>
    </b-row>

    <b-row>
        <b-col cols="12" class="py-sm-3">
            <div class="accordion" role="tablist">

                <b-card v-for="(item, index) in itens" :key="index" class="card-faq" no-body>
                    <b-button class="col-12 col-md-6 button-faq-pergunta p-0 my-2" v-b-toggle="'accordion-' + index">
                        {{item.titulo}} <span class="collapse-fechado">+</span><span class="collapse-aberto">-</span>
                    </b-button>

                    <b-collapse :id="'accordion-' + index"
                                class="collapse-faq-resposta pb-1"
                                accordion="my-accordion">

                        <b-row class="pb-3">
                            <b-col cols="12" md="6" align-self="center">
                                <span v-html="item.texto"></span>
                            </b-col>
                            <b-col cols="12" md="6" align-self="center" class="text-center">
                                <img v-if="item.url_icone" :src="item.url_icone" class="w-100" />
                            </b-col>
                        </b-row>

                    </b-collapse>

                </b-card>

            </div>

        </b-col>
    </b-row>
</b-container>

</template>

<script>
    import { getCor } from '@/assets/js/SharkUtils';
    export default {
        name: 'Faq',
        data: function () {
            return {}
        },
        props: {
            id_comp: Number,
            componente: Object,
        },
        computed: {
            itens() {
                if (this.componente) {
                    return this.componente.itens ? this.componente.itens : [];
                } else {
                    return [];
                }
            },
            corFundo() {
                if (this.componente) {
                    if (this.componente.tema) {
                        let corLista = getCor(this.componente.tema);
                        if (corLista) {
                            return corLista.var_hexa;
                        }
                    }
                }
                return '#fff';
            },
            titulo() {
                if (this.componente) {
                    if (this.componente.titulo) {
                        return this.componente.titulo
                    }
                }
                return 'Dúvidas Frequentes'
            },
            corTitulo() {
                if (this.componente) {
                    if (this.componente.cor_titulo) {
                        let corLista = getCor(this.componente.cor_titulo);
                        if (corLista) {
                            return corLista.var_hexa;
                        }
                    }
                }
                return 'var(--cinza-escuro-omni)';
            },
        }
    }
</script>

<style scoped>
    .container-faq {
        padding: 3% 7%;
        font-family: Museo !important;
    }

    .titulo-faq {
        font-weight: 900 !important;
        font-size: calc(22px + (36 - 22) * ((80vw - 200px) / (1600 - 200)));
    }

    .card-faq, .card-faq > * {
        background-color: transparent !important;
        color: var(--cinza-escuro-omni) !important;
        border: none !important;
        font-size: 16px;
    }

    .button-faq-pergunta {
        text-align: left;
        background-color: transparent !important;
    }
        .button-faq-pergunta:focus {
            box-shadow: none !important;
        }

    .collapse-faq-resposta {
        font-family: MuseoSans;
        font-weight: 400;
    }

    .collapsed > .collapse-aberto,
    .not-collapsed > .collapse-fechado {
        display: none;
    }

    .collapse-fechado {
        color: var(--azul-claro-omni) !important;
        font-size: 15px;
    }

    .collapse-aberto {
        color: var(--laranja-claro-omni) !important;
        font-size: 15px;
    }

    @media only screen and (max-width: 800px) {
        .container-faq {
            padding: 2% 8%;
        }
    }
</style>