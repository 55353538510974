<template>
    <dropdown
        :trigger="trigger" 
        :role="role" 
        :align="align"
        :class-name="classeCustom"
        :class="classesDinamicas"
    >

    <template slot="btn" >
        <span @click="redireciona(setor.url)" class="font-weight-bold header-menu-drop-down-maozinha header-menu-drop-down-opacity">
            <s-router-link :url="setor.url" class="header-menu-dropdown-router-link">
                {{ setor.nome }}
            </s-router-link>
        </span>
    </template>

    <template slot="body">
        <ul class="header-menu-omni-lista-drop-down">

            <template v-for="(subsetor, index) in setor.sub_setores">
                    
                <li v-if="!temSubSetores(subsetor)" :key="index" class="header-menu-drop-down-maozinha header-menu-omni-item-drop-down" @click="redireciona(subsetor.url)">
                    <s-router-link :url="subsetor.url" class="header-menu-dropdown-router-link">
                        {{ subsetor.nome }}
                    </s-router-link>
                </li>

                <dropdown
                    v-else
                    :key="index" 
                    :trigger="'hover'" :role="'sublist'" :align="'right'"
                    :class-name="classeCustom == 'classe-custom-laranja'? classeCustom: 'classe-custom-azul-escuro'"
                    :x="offX"
                    :y="offY"
                >
                    <template slot="btn" >
                        <span @click="redireciona(subsetor.url)" class="header-menu-drop-down-maozinha header-menu-drop-down-opacity">
                            <s-router-link :url="subsetor.url" class="header-menu-dropdown-router-link">
                                {{ subsetor.nome }}
                            </s-router-link>
                        </span>
                    </template>

                    <template slot="body">
                        <ul class="header-menu-omni-lista-drop-down">
                            <template v-for="(sub2, index2) in subsetor.sub_setores">
                                <li 
                                    :key="index2" 
                                    class="header-menu-drop-down-maozinha header-menu-omni-item-drop-down" 
                                    @click="redireciona(sub2.url)"
                                >
                                    <s-router-link :url="sub2.url" class="header-menu-dropdown-router-link">
                                        {{ sub2.nome }}
                                    </s-router-link>
                                </li>
                            </template>
                        </ul>
                    </template>


                </dropdown>
            
            </template>

        </ul>
    </template>
    </dropdown>
</template>

<script>
import Dropdown from 'bp-vuejs-dropdown';
// import { redirecionadorExterno } from '@/assets/js/SharkUtils';
import SRouterLink from "@/components/SRouterLink.vue";

export default {
    name: 'HeaderMenuDropDown',
    components: {
        Dropdown,
        SRouterLink
    },
    props: {
        raiz: {
            type: Boolean,
            default: false,
        },
        trigger: {
            type: String,
            default: 'click'
        },
        role: {
            type: String,
            default: ''
        },
        align: {
            type: String,
            default: 'bottom'
        },
        setor: {
            type: Object,
            default: () => {
                return {
                    nome : "Setor",
                    url : "",
                    ordenacao : 0,
                    sub_setores : [],
                }
            }
        },
        classeCustom: {
            type: String,
            default: 'classe-custom-laranja'
        },
    },
    computed: {
        classesDinamicas() {
            let classes = [];
            if(this.raiz) {
                classes.push('classe-raiz-botao-dropdown-customizado');
            }

            if(!this.temSubSetores(this.setor)) {
                classes.push('classe-raiz-icone-dropdown-customizado');
            }

            return classes;
        },
    },
    methods: {
        temSubSetores(setor) {
            if(setor.sub_setores) {
                return setor.sub_setores.length > 0 
            }
            return false;
        },
        redireciona(pUrl) {
            if(pUrl) {
                this.$root.$emit('bp-dropdown:hide')
                // redirecionadorExterno(this.$router, pUrl);
            }
        },
        offset: function(el) {
            var rect = el.getBoundingClientRect(),
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            return { top: rect.top + scrollTop, right: rect.right }
        },
    },
    data: function() {
        return {
            offX: 0,
            offY: 0,
        }
    },
    mounted() {
        this.offX = 0;
        this.offY = -42;
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.header-menu-omni-lista-drop-down {
    padding-top: 3px;
    padding-right: 5px;
}

    .header-menu-omni-lista-drop-down:hover > li,
    .header-menu-omni-lista-drop-down:hover > div {
        opacity: 0.5;
    }

    .header-menu-omni-lista-drop-down:hover li:hover,
    .header-menu-omni-lista-drop-down:hover div:hover {
        opacity: 1;
    }

    .header-menu-omni-lista-drop-down div,
    .header-menu-omni-lista-drop-down li {
        color: #fff;
        padding: 8px; 
        padding-right: 8px; 
    }

    .header-menu-omni-lista-drop-down > div {
        padding-right: 2px; 
        width: calc(100% + 12px);
    }

    .header-menu-omni-lista-drop-down > div > span {
        padding-right: 6px; 
    }

.header-menu-omni-item-drop-down {
    list-style-type: none;
    color: #fff;
    padding-right: 1.2rem;
}

.header-menu-omni-item-drop-down:hover {
    opacity: 0.5;
}

.classe-custom-laranja-bp__body {
    background-color: var(--laranja-claro-omni);
}

.classe-custom-azul-bp__body {
    background-color: var(--azul-menu-omni);
}

.classe-custom-azul-escuro-bp__body {
    background-color: var(--azul-omni);
    padding-left: 25px !important;
}

.classe-custom-laranja-bp__body,
.classe-custom-azul-bp__body {
    padding: .5rem 0.3rem 0.5rem 1.5rem; 
    font-family: MuseoSans;
    font-size: 14px;
    font-weight: 700;
}

.classe-custom-laranja-bp__btn--active,
.classe-custom-azul-bp__btn--active {
    opacity: 1;
}

/* .my-class {}
.my-class-bp__btn {}
.my-class-bp__btn--active {}
.my-class-bp__body {} */

.classe-raiz-botao-dropdown-customizado > .bp-dropdown__btn {
    background-color: #f8f9fa;
    border: none;
    color: var(--azul-claro-omni);
    padding-top: 8px;
}


.classe-raiz-icone-dropdown-customizado  .bp-dropdown__icon,
.classe-raiz-icone-dropdown-customizado  .bp-dropdown__body {
    display: none;
}

.header-menu-drop-down-maozinha {
    cursor: pointer;
}

.header-menu-drop-down-opacity:hover,
.header-menu-drop-down-opacity:focus {
    opacity: 0.7;
}

    @media screen and (max-width: 991px) {
        .classe-custom-laranja-bp__body,
        .classe-custom-azul-bp__body {
            font-size: 16px;
        }
    }

.header-menu-dropdown-router-link,
.header-menu-dropdown-router-link:hover,
.header-menu-dropdown-router-link:active,
.header-menu-dropdown-router-link:focus {
    color: inherit;
    text-decoration: inherit;
}

</style>