import axios from 'axios';

export default {
    namespaced: true,
    state: {
        lista_agentes: null,
    },
    getters: {
        agentes(state) {
            return state.lista_agentes;
        },
    },

    mutations: {
        SET_AGENTES (state, agentes) {
            state.lista_agentes = agentes;
        },
    },
    actions: {
        getAgentes({ dispatch }) {
            dispatch('showOverlay', true, { root: true });
            return axios.get("/api/agentes")
            .then( (response) => {
                dispatch('showOverlay', false, { root: true });
                dispatch('attempt', response.data.agentes);
            })
            .catch( err => {
                dispatch('showOverlay', false, { root: true });
                dispatch('failed', err);
            });
        },
        attempt({ commit }, agentes) {
            if(agentes)
                commit('SET_AGENTES', agentes);
        },
        failed(_, err) {
            console.log('falha ao carregar agentes');
            console.log(err);
        },
    },
}
