// import axios from 'axios';
import { buscarUrlsResultado } from '../assets/js/BuscadorDePaginas.js';

export default {
    namespaced: true,
    state: {
        paginas_encontradas: null,
    },
    getters: {
        paginas(state) {
            return state.paginas_encontradas;
        },
    },
    mutations: {
        SET_PAGINAS (state, paginas) {
            state.paginas_encontradas = paginas;
        },
    },

    actions: {
        async buscaPaginas({ rootGetters, dispatch }, pStringBusca) {
            if(!pStringBusca) {
                dispatch('failed', 'string de busca vazia');
                return;
            }

            try {
                let listaAgentes = rootGetters['agentes/agentes']
                // Verifica se ja tem agentes
                if(!listaAgentes) {
                    // Senao chama metodo da store que carrega
                    await dispatch('agentes/getAgentes', null, { root: true });
                    listaAgentes = rootGetters['agentes/agentes']
                }

                let listaPaginas = rootGetters['paginas/paginas']
                // Verifica se ja tem paginas
                if(!listaPaginas) {
                    // Senao chama metodo da estore que carrega
                    await dispatch('paginas/getPaginas', null, { root: true });
                    listaPaginas = rootGetters['paginas/paginas']
                }

                let listaPosts = rootGetters['posts/posts']
                // Verifica se ja tem posts
                if(!listaPosts) {
                    // Senao chama metodo da estore que carrega
                    await dispatch('posts/getPosts', null, { root: true });
                    listaPosts = rootGetters['posts/posts']
                }

                let resultado = buscarUrlsResultado(listaAgentes, listaPaginas, listaPosts, pStringBusca);
                dispatch('attempt', resultado);
            } catch(e) {
                dispatch('failed', e);
            }
            return;
        },
        attempt({ commit }, paginas) {
            if(paginas) {
                commit('SET_PAGINAS', paginas);
            }
        },
        failed({ commit }, err) {
            console.log('erro na busca');
            console.log(err);
            commit('SET_PAGINAS', null);
        },
    },
}
