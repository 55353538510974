<template>
  <div class="colunas-botao-img-container" v-bind:style="{ 'background-color': corFundo }">
    <div class="container-fluid" v-if="componente">
        
        <div class="row my-2" v-if="componente.titulo">
            <h2 v-bind:style="{ color: corTitulo }" class="mx-auto colunas-botao-img-titulo"> {{ componente.titulo }}</h2>
        </div>

        <div class="row my-2" v-if="componente.sub_titulo">
            <h4 v-bind:style="{ color: corSubTitulo }" class="mx-auto colunas-botao-img-sub-titulo"> {{ componente.sub_titulo }}</h4>
        </div>

        <div class="row my-2">
            <div class="row my-2 colunas-botao-img-flex-container mx-auto">
     
                <div class="px-2 py-2" v-for="(item, index) in itens" v-bind:key="index">


                    <div class="container-fluid px-0">
                        <div class="row no-gutters" v-if="item.url_icone">
                            <div class="col-12">
                                <s-router-link :url="item.url_redir?item.url_redir:''" :key="index+'im'" >
                                    <div class="colunas-botao-img-container-imagem">
                                        <img
                                            class="colunas-botao-img-imagem"
                                            :src="item.url_icone"
                                        >
                                    </div>
                                </s-router-link>
                            </div>
                        </div>
                        <div class="row no-gutters" v-if="item.titulo">
                            <div class="col-12">
                                <s-router-link :url="item.url_redir?item.url_redir:''" :key="index+'rt'" >
                                    <botao-m :style="`background-color:${varHexa(item.cor_titulo)};`">
                                        {{ item.titulo }}
                                    </botao-m>
                                </s-router-link>
                            </div>
                        </div>
                    </div>

                
                </div>

            </div>
        </div>

    </div>
  </div>
</template>

<script>

import { getCor } from '@/assets/js/SharkUtils';
import BotaoM from '@/components/botoes/BotaoM';
import SRouterLink from '@/components/SRouterLink';

export default {
  name: 'ColunasBotaoImg',
  components: {
      BotaoM,
      SRouterLink,
  },
  props: {
    id_comp: Number,
    componente: Object,
  },
  methods: {
      varHexa(pCor) {
          if(pCor) {
            return getCor(pCor).var_hexa;
          }
          return 'var(--laranja-claro-omni)'
      },
  },
  computed: {
      itens() {
        if(this.componente) {
            return this.componente.itens? this.componente.itens: [];
        } else {
            return [];
        }
      },
      corFundo() {
        if(this.componente) {
          return getCor(this.componente.tema).var_hexa;
        }
        return '#000';
      },
      corTitulo() {
        if(this.componente) {
          return getCor(this.componente.cor_titulo).var_hexa;
        }
        return '#fff';
      },
      corSubTitulo() {
        if(this.componente) {
          return getCor(this.componente.cor_sub_titulo).var_hexa;
        }
        return '#fff';
      },
  },
  data: function() {
    return {
    }
  }
}
</script>

<style scoped>
.colunas-botao-img-container {
    max-width: 100% !important;
    width: 100% !important;
    padding-top: 40px;
    padding-bottom: 40px;
}

.colunas-botao-img-container-imagem {
    width: 100%;
    height: auto;
}

.colunas-botao-img-imagem {
    width: 80%;
    height: auto;
    margin: 0 auto;
}
    .colunas-botao-img-imagem:hover {
        cursor: pointer;
        opacity: 0.8;
    }

.colunas-botao-img-flex-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
    flex-wrap: wrap;
}

.colunas-botao-img-titulo {
    font-weight: 900;
    text-align: center;
}

.colunas-botao-img-sub-titulo {
    font-weight: 700;
    text-align: center;
}

</style>
