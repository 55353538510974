import axios from 'axios';
import { SETORES_MENU_PADRAO } from '../assets/js/dados-padrao/SetoresMenuPadrao.json';
        
export default {
    namespaced: true,
    state: {
        lista_setores: null,
        lista_subsetores: null,
    },

    getters: {
        setores(state) {
            return state.lista_setores;
        },
        subSetores(state) {
            return state.lista_subsetores;
        },
    },

    mutations: {
        SET_SETORES (state, setores) {
            state.lista_setores = setores;
        },
        SET_SUB_SETORES (state, subSetores) {
            state.lista_subsetores = subSetores;
        },
    },

    actions: {
        getSetores({ dispatch }) {
            dispatch('showOverlay', true, { root: true });
            return axios.get("/api/setores")
                .then( (response) => {

                    if(response.data.setores && response.data.setores.length > 0) {
                        dispatch('showOverlay', false, { root: true });
                        dispatch('attempt', response.data.setores);
                    }
                    else {
                        dispatch('failed', response);
                    }

                })
                .catch( err => {
                    dispatch('showOverlay', false, { root: true });
                    dispatch('failed', err.response);
                });
        },
        getSubSetores({ dispatch }) {
            dispatch('showOverlay', true, { root: true });
            return axios.get("/api/subsetores")
            .then( (response) => {
                dispatch('showOverlay', false, { root: true });
                dispatch('attemptSub', response.data.sub_setores);
            })
            .catch( err => {
                dispatch('showOverlay', false, { root: true });
                dispatch('failedsub', err.response);
            });
        },
        attempt({ commit, state }, setores) {
            if(setores) {
                setores.sort((set1, set2) => set1.ordenacao - set2.ordenacao)
                setores.forEach(element => {
                    if(element.sub_setores) {
                        element.sub_setores.sort((set1, set2) => set1.ordenacao - set2.ordenacao)
                    }
                });
                commit('SET_SETORES', setores);
            }
            
            if(!state.setores)
                return;    
        },
        attemptSub({ commit, state }, sub_setores) {
            if(sub_setores) {
                commit('SET_SUB_SETORES', sub_setores);
            }
            
            if(!state.sub_setores)
                return;    
        },
        failed({ commit }, err) {
            console.log('falha ao carregar dados do menu, carregando o padrao');
            console.log(err);
            commit('SET_SETORES', SETORES_MENU_PADRAO);
            commit('SET_SUB_SETORES', SETORES_MENU_PADRAO.map(setor=> setor.sub_setores).flat());
        },
        failedsub({ commit }, err) {
            console.log('failed sub setor', err);
            commit('SET_SUB_SETORES', SETORES_MENU_PADRAO.map(setor=> setor.sub_setores).flat());
        },
    },
}
