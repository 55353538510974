/*
*/
exports.geocodeCEPsGoogle = geocodeCEPsGoogle
exports.distVincenty = distVincenty
exports.handleRespostaApiGoogle = handleRespostaApiGoogle
exports.calculaDistanciasAgentesOrigem = calculaDistanciasAgentesOrigem
exports.handleRespostaApiGoogleJs = handleRespostaApiGoogleJs

function formataCEP(pCep) {
    if(!pCep) {
        return ''
    }
    if(typeof(pCep) !== "string" && typeof(pCep) !== "number") {
        return ''
    }

    // cepNumeros eh string so com numeros
    let cepNumeros
    if(typeof(pCep) === "string") {
        cepNumeros = pCep.replace(/[^0-9]/g, '')
    }
    if(typeof(pCep) === "number") {
        cepNumeros =  String(pCep)
    }
    if(!cepNumeros || cepNumeros.length !== 8) {
        return ''
    }

    return cepNumeros.substring(0, 5) + '-' + cepNumeros.substring(5, 8)
}

async function geocodeCEPsGoogle(pAxios, pKey, pCep) {
    try {
        let cep = formataCEP(pCep)
        if(!cep) {
            return null;
        }
        const response = await pAxios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${cep}&key=${pKey}&region=br`)
        console.log('httpteste', pKey)
        return handleRespostaApiGoogle(response, cep)

    } catch(e) {
        console.log('geocodeCEPsGoogle err', e)
        throw e
    }
}

/*!
 * JavaScript function to calculate the geodetic distance between two points specified by latitude/longitude using the Vincenty inverse formula for ellipsoids.
 *
 * Original scripts by Chris Veness
 * Taken from http://movable-type.co.uk/scripts/latlong-vincenty.html and optimized / cleaned up by Mathias Bynens <http://mathiasbynens.be/>
 * Based on the Vincenty direct formula by T. Vincenty, “Direct and Inverse Solutions of Geodesics on the Ellipsoid with application of nested equations”, Survey Review, vol XXII no 176, 1975 <http://www.ngs.noaa.gov/PUBS_LIB/inverse.pdf>
 *
 * @param   {Number} lat1, lon1: first point in decimal degrees
 * @param   {Number} lat2, lon2: second point in decimal degrees
 * @returns {Number} distance in metres between points
 */
function toRad(n) {
    return n * Math.PI / 180;
}

function distVincenty(lat1, lon1, lat2, lon2) {
    var a = 6378137,
        b = 6356752.3142,
        f = 1 / 298.257223563, // WGS-84 ellipsoid params
        L = toRad(lon2-lon1),
        U1 = Math.atan((1 - f) * Math.tan(toRad(lat1))),
        U2 = Math.atan((1 - f) * Math.tan(toRad(lat2))),
        sinU1 = Math.sin(U1),
        cosU1 = Math.cos(U1),
        sinU2 = Math.sin(U2),
        cosU2 = Math.cos(U2),
        lambda = L,
        lambdaP,
        iterLimit = 100;
    do {
     var sinLambda = Math.sin(lambda),
         cosLambda = Math.cos(lambda),
         sinSigma = Math.sqrt((cosU2 * sinLambda) * (cosU2 * sinLambda) + (cosU1 * sinU2 - sinU1 * cosU2 * cosLambda) * (cosU1 * sinU2 - sinU1 * cosU2 * cosLambda));
     if (0 === sinSigma) {
      return 0; // co-incident points
     }
     var cosSigma = sinU1 * sinU2 + cosU1 * cosU2 * cosLambda,
         sigma = Math.atan2(sinSigma, cosSigma),
         sinAlpha = cosU1 * cosU2 * sinLambda / sinSigma,
         cosSqAlpha = 1 - sinAlpha * sinAlpha,
         cos2SigmaM = cosSigma - 2 * sinU1 * sinU2 / cosSqAlpha,
         C = f / 16 * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha));
     if (isNaN(cos2SigmaM)) {
      cos2SigmaM = 0; // equatorial line: cosSqAlpha = 0 (§6)
     }
     lambdaP = lambda;
     lambda = L + (1 - C) * f * sinAlpha * (sigma + C * sinSigma * (cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM)));
    } while (Math.abs(lambda - lambdaP) > 1e-12 && --iterLimit > 0);
   
    if (!iterLimit) {
     return NaN; // formula failed to converge
    }
   
    var uSq = cosSqAlpha * (a * a - b * b) / (b * b),
        A = 1 + uSq / 16384 * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq))),
        B = uSq / 1024 * (256 + uSq * (-128 + uSq * (74 - 47 * uSq))),
        deltaSigma = B * sinSigma * (cos2SigmaM + B / 4 * (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) - B / 6 * cos2SigmaM * (-3 + 4 * sinSigma * sinSigma) * (-3 + 4 * cos2SigmaM * cos2SigmaM))),
        s = b * A * (sigma - deltaSigma);
    return s.toFixed(3); // round to 1mm precision
}

// Funcao que converte resposta da requisicao para o formato do banco.
function handleRespostaApiGoogle(pResposta, pCep) {
    if(!pResposta || !pResposta.data) {
        return null
    }
    if(!pResposta.data.results[0] || !pResposta.data.results[0].geometry) {
        console.log('erro google API ', pResposta.data)
        return null
    }

    let cep = pCep;
    let latitude = pResposta.data.results[0].geometry.location.lat
    let longitude = pResposta.data.results[0].geometry.location.lng
    let resposta_json = pResposta.data

    return {
        cep,
        latitude,
        longitude,
        resposta_json: JSON.stringify(resposta_json),
    }
}

// Funcao que converte resposta da requisicao para o formato do banco.
function handleRespostaApiGoogleJs(pResposta, pCep) {
    if(!pResposta) {
        return null
    }
    if(!pResposta[0] || !pResposta[0].geometry) {
        console.log('erro google API JS', pResposta)
        return null
    }

    let cep = pCep;
    let latitude = pResposta[0].geometry.location.lat()
    let longitude = pResposta[0].geometry.location.lng()
    let resposta_json = pResposta

    return {
        cep,
        latitude,
        longitude,
        resposta_json: JSON.stringify(resposta_json),
    }
}

function calculaDistanciasAgentesOrigem(pAgentesComLatLng, pLatOrigem, pLongOrigem) {
    return pAgentesComLatLng
        .filter(agente => agente.latitude != 0 && agente.longitude != 0)
        .map(agente => {
            return {
                ...agente,
                distancia: parseFloat(distVincenty(
                    agente.latitude? agente.latitude: 0,
                    agente.longitude? agente.longitude: 0,
                    pLatOrigem? pLatOrigem: 0,
                    pLongOrigem? pLongOrigem: 0
                ))
            }
        })
}