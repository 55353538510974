<template>

    <div class="container-fluid" v-if="componente">
        <div class="row ml-xl-5 ml-lg-3 ml-md-2 ml-sm-1">
            <div class="col-xl-5 col-lg-7 col-sm-11 ml-xl-4 ml-lg-2 mx-sm-auto">
                <div class="container">
            
                    <div class="row pt-3">
                        <h1 class="banner-principal-titulo mb-0" :style="{ 'color': corTitulo }">{{componente.titulo}}</h1>
                    </div>
                    <div class="row pb-xl-3 pb-lg-0 pb-sm-3">
                        <h1 class="banner-principal-titulo" :style="{ 'color': corSubTitulo }">{{componente.sub_titulo}}</h1>
                    </div>
                    <div class="row">
                        <div class="banner-principal-conteudo" v-html="componente.texto"> </div>
                    </div>
                    <div class="row">
                        <div class="mx-sm-auto mx-lg-0">
                            <botao-m class="banner-principal-botao" v-if="temBotao1" @click="redireciona(componente.url_botao_1)" :style="{ 'background-color': corBotao1 }"> {{textoBotao1}} </botao-m>
                            <botao-m class="banner-principal-botao" v-if="temBotao2" @click="redireciona(componente.url_botao_2)" :style="{ 'background-color': corBotao2 }"> {{textoBotao2}} </botao-m>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { LISTA_CORES } from '@/assets/js/dicionarioPaginas';
import { redirecionadorExterno } from '@/assets/js/SharkUtils';
import BotaoM from '@/components/botoes/BotaoM';

export default {
    name: 'BannerPrincipalContainer',
    components: {
        BotaoM,
    },
    props: {
        id_comp: Number,
        componente: Object,
        windowWidth: Number,
    },
    computed: {
        tema() {
            if(this.componente) {
                if(this.componente.tema) {
                    let corLista = this.getCorLista(this.componente.tema);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return null;
        },
        corTitulo() {
            if(this.componente) {
                if(this.componente.cor_titulo) {
                    let corLista = this.getCorLista(this.componente.cor_titulo);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--azul-omni)';
        },
        corSubTitulo() {
            if(this.componente) {
                if(this.componente.cor_sub_titulo) {
                    let corLista = this.getCorLista(this.componente.cor_sub_titulo);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--azul-omni)';
        },
        temBotao1() {
            if(this.componente) {
                if(this.componente.txt_botao_1) {
                    return this.componente.txt_botao_1.length > 0 ? true: false;
                }
            }
            return false;
        },
        textoBotao1() {
            if(this.componente) {
                if(this.componente.txt_botao_1) {
                    return this.componente.txt_botao_1
                }
            }
            return 'Clique aqui!';
        },
        corBotao1() {
            if(this.componente) {
                if(this.componente.cor_botao_1) {
                    let corLista = this.getCorLista(this.componente.cor_botao_1);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--laranja-omni)';
        },
        temBotao2() {
            if(this.componente) {
                if(this.componente.txt_botao_2) {
                    return this.componente.txt_botao_2.length > 0 ? true: false;
                }
            }
            return false;
        },
        textoBotao2() {
            if(this.componente) {
                if(this.componente.txt_botao_2) {
                    return this.componente.txt_botao_2
                }
            }
            return 'Clique aqui!';
        },
        corBotao2() {
            if(this.componente) {
                if(this.componente.cor_botao_2) {
                    let corLista = this.getCorLista(this.componente.cor_botao_2);
                    if(corLista) {
                        return corLista.var_hexa;
                    }
                }
            }
            return 'var(--laranja-omni)';
        },
        urlImagem() {
            if(this.componente) {
                if(this.componente.url_img) {
                    return this.componente.url_img;
                }
            }
            return require('../../assets/images/placeholders/img_placeholder.png');
        },
    },
    data: function() {
        return {
            listaCores: LISTA_CORES,
        }
    },
    methods: {
        getCorLista: function(COR) {
            return this.listaCores.find(cor => cor.chave === COR);
        },
        redireciona(pUrl) {
            if(pUrl) {
                redirecionadorExterno(this.$router, pUrl);
            }
        },
    },
}
</script>

<style scoped>

.btn-secondary {
    position: relative;
    top:80%;
    width: 80%;
    border:none;
    margin-bottom: 10px;
}
.container
{
    width: 100%;
    display: block;
}

.banner-principal-botao {
    min-width: 100% !important;
    padding: 8px 55px !important;
    width: auto !important;
    margin-bottom: 10px;
    display: block;
    height: auto !important;
}


.banner-principal-conteudo {
    line-height: normal;
    font-size: 14px;
    width: 82%;
}
@media screen and (min-width: 992px) and (max-width: 1250px) {
    .banner-principal-conteudo {
        width: 87% !important;
    }
}
@media screen and (max-width: 991px) {
    .banner-principal-conteudo {
        width: 80% !important;
    }
}

.banner-principal-titulo {
    font-weight: 900;
    word-wrap: normal !important;
    font-size: clamp(27px, 2.6vw, 40px);
}

@media screen and (max-width: 991px) {
    .banner-principal-botao {
        padding: 8px 65px !important;
    }
    .banner-principal-titulo {
        text-align: center;
        margin: auto;
        font-size: 36px;
    }
    .banner-principal-conteudo {
        width: 90% !important;
        margin: auto;
    }
}

</style>