<template>
    <div class="blog-page-container">

        <div class="container-fluid" >
            <div class="row">
                <img src="@/assets/images/header_omniblog_desk.png" class="blog-page-img-header"/>
            </div>
        </div>

        <div class="container-fluid" v-if="resultadoOk">
            <div class="row blog-page-row-resultados">
                <div class="col-md-11 mx-auto" v-if="posts.length">
                    <div class="d-flex flex-row flex-wrap">

                        <div v-for="(post, index) in posts" :key="index" class="blog-page-caixa-resultados">
                            <router-link :to="`/blog/${post.id}`" :key="index" >
                              <div class="blog-page-img-container">
                                  <img class="blog-page-img" :src="post.url_imagem" alt="imagem publicacao"/>
                              </div>
                              <h5 class="mx-auto blog-page-titulo-pagina"> {{ post.titulo }} </h5>
                            </router-link>
                            <p class="w-75 blog-page-paragrafo-info"> Ano {{ post.ano }} - Edição {{ post.edicao }} </p>
                            <p class="w-75 blog-page-paragrafo-info"> por {{ post.autor }} </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid" v-else>
            <div class="row blog-page-row-nao-encontrado">
                <div class="col-12">
                    <h3>Não existem publicações disponíveis</h3>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'BlogPage',
    components: {
    },
    data: function() {
        return {
        }
    },
    methods: {
        ...mapActions({
            getPosts: 'posts/getPosts',
        }),
    },
    computed: {
        ...mapGetters({
            posts: 'posts/posts',
            getPostCounter: 'posts/getPostCounter',
        }),
        resultadoOk() {
            if(this.posts && this.posts.length > 0)
                return true;
            else
                return false;
        },
    },
    created() {
      this.getPosts();
    },
}

</script>

<style scoped>

.blog-page-img-header {
    width: 100%;
    height: auto;
}

.blog-page-caixa-resultados {
    width: 30%;
    max-width: 30%;
    margin: 20px auto;
}

@media only screen and (max-width:768px) {
    .blog-page-caixa-resultados {
        width: 85%;
        max-width: 85%;
    }
}

.blog-page-titulo-pagina {
  width: 75%;
  text-align: center;
  padding-top: 25px;
  color: var(--azul-claro-omni);
  font-family: MuseoSans;
  font-weight: 900;
}

.blog-page-titulo-pagina:hover {
    opacity: 0.7;
    text-decoration: underline;
    cursor: pointer;
}

.blog-page-row-nao-encontrado,
.blog-page-row-resultados {
    margin-top: 35px;
    min-height: 300px;
    padding: 20px 20px 20px 20px;
}

.blog-page-img-container {
  min-width: 50px;
  max-width: 100%;
  overflow: hidden;

  height: 150px;
  max-height: 150px;
  margin: auto;
}
  .blog-page-img-container:hover {
    opacity: 0.7;
    cursor: pointer;
  }

.blog-page-img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-height: 100%;
  display: block;
}

.blog-page-paragrafo-info {
  text-align: center;
  font-family: MuseoSans;
  font-weight: 500;
  padding-bottom: 0px;
  margin: 0 auto;
  font-size: 14px;
}

</style>
