import axios from 'axios';

export default {
    namespaced: true,
    state: {
        lista_paginas: null,
        home: null,
    },

    getters: {
        paginas(state) {
            return state.lista_paginas;
        },
        home(state) {
            if(!state.lista_paginas) {
                return null
            }
            return state.lista_paginas.find(pagina => pagina.template == "HOME")
        },
    },

    mutations: {
        SET_PAGINAS (state, paginas) {
            state.lista_paginas = paginas;
        },
    },

    actions: {
        getHome({ dispatch, state }) {
            if(state.lista_paginas) {
                return
            }
            dispatch('showOverlay', true, { root: true });
            return axios.get("/api/paginas/url/")
            .then( (response) => {
                dispatch('showOverlay', false, { root: true });
                dispatch('attempt', [response.data.pagina]);
            })
            .catch( err => {
                dispatch('showOverlay', false, { root: true });
                dispatch('failed', err);
            });
        },
        getPaginas({ dispatch }) {
            dispatch('showOverlay', true, { root: true });
            return axios.get("/api/paginas")
            .then( (response) => {
                dispatch('showOverlay', false, { root: true });
                dispatch('attempt', response.data.paginas);
            })
            .catch( err => {
                dispatch('showOverlay', false, { root: true });
                dispatch('failed', err);
            });
        },
        attempt({ commit }, paginas) {
            if(paginas) {
                // Ordena os componentes para cada página
                paginas.forEach(pagina => {
                    if(pagina.componentes) {
                        pagina.componentes.sort((it1, it2) => it1.posicao - it2.posicao);
                        // Ordena os itens quando houver
                        pagina.componentes.forEach( comp => {
                            if(comp.itens) {
                                comp.itens.sort((it1, it2) => it1.posicao - it2.posicao);
                            }
                        });
                    }
                });
                commit('SET_PAGINAS', paginas);
            }
        },
        failed(_, err) {
            console.log('falha ao carregar paginas');
            console.log(err);
        },
    },
}
