<template>
    <div class="container servicos-agente-bloco-container mx-auto" v-if="servicos">

        <div class="servicos-agente-bloco-container-imagem my-4">
            <img class="servicos-agente-bloco-imagem" src="@/assets/images/agentes/img_veiculos.png" v-if="servicoFinanciamento"/>
            <img class="servicos-agente-bloco-imagem" src="@/assets/images/agentes/img_microcredito.png" v-else-if="servicoMicroCredito"/>
            <img class="servicos-agente-bloco-imagem" src="@/assets/images/agentes/img_seguro.png" v-else-if="servicoSeguros"/>
            <img class="servicos-agente-bloco-imagem" src="@/assets/images/agentes/img_seguro.png" v-else/>
        </div>

        <button v-for="(servico, index) in servicos" v-bind:key="index" class="servicos-agente-bloco-botao" @click="navegaPaginaProduto(servico.id)">
            <span class="servicos-agente-bloco-botao-texto">{{ servico.nome }}</span>
            <span class="servicos-agente-bloco-botao-icone">
                <img src="@/assets/images/agentes/seta_botao.png">
            </span>
        </button>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

const servicosDic = {
    "FINANCIAMENTO": 1,
    "REFINANCIAMENTO": 2,
    "MICRO+": 3,
    "GIRO+": 4,
    "CREDIARIO": 6,
    "SEGUROS": 7,
}

export default {
  name: 'ServicosAgenteBloco',
  components: {
  },
  props: {
    servicos: Array,
  },
  data: function() {
      return {
      }
  },
  computed: {
    ...mapGetters({
        servicosDisponiveis: 'servicos/servicos',
        paginas: 'paginas/paginas',
    }),
    servicoFinanciamento() {
        if(!this.servicos || !this.servicos[0]) {
            return false;
        }

        if(this.servicos[0].id == servicosDic["FINANCIAMENTO"]  ||
            this.servicos[0].id == servicosDic["REFINANCIAMENTO"] )
            return true;
        return false;
    },
    servicoMicroCredito() {
        if(!this.servicos || !this.servicos[0]) {
            return false;
        }

        if(this.servicos[0].id == servicosDic["MICRO+"])
            return true;
        return false;
    },
    servicoSeguros() {
        if(!this.servicos || !this.servicos[0]) {
            return false;
        }
        
        if(this.servicos[0].id == servicosDic["SEGUROS"])
            return true;
        return false;
    },
    
  },
  methods: {
    ...mapActions({
        getPaginas: 'paginas/getPaginas',
    }),
    navegaPaginaProduto: function(pIdProduto) {
        if(this.paginas) {
            let pagina = this.paginas.find(pagina => pagina.id_produto === pIdProduto); 
            if(pagina)
                this.visualizar(pagina);
        }
    },
    visualizar: function(pPagina) {
        if(pPagina) {
            if(pPagina.url_setor) {
                let urlRedir = pPagina.url_setor.replace(/\//g, "");
                this.$router.push({name: 'RenderizadorPage', params: { url: urlRedir }}).catch(()=>{});
            }
        }
    },
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.servicos-agente-bloco-container {
    width: 100%;
}

.servicos-agente-bloco-container-imagem {
    height: 110px;
    text-align: center;
}

.servicos-agente-bloco-imagem {
    height: 100%;
    width: auto;
    display: inline-block;
}

.servicos-agente-bloco-botao {
    border: none;
    width: 95%;
    outline: none;
    background: none;
    margin-top: 5px;
    color: var(--azul-claro-omni);
    font-size: 18px;
    text-align: left;
    display: block;
    position: relative;
}

    .servicos-agente-bloco-botao:hover {
        opacity: 0.7;
    }

.servicos-agente-bloco-botao-texto {
    width: calc(100% - 1.3rem);
    display: block;
}

.servicos-agente-bloco-botao-icone {
    right: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

</style>