<template>
    <div class="agente-omni-page-container">
        <div class="container agente-omni-page-w-100" v-if="agente">

            <div class="row agente-omni-page-primeira-coluna">
                <div class="col-md-6 col-sm-12 align-self-center">
                    <div class="agente-omni-page-container-imagem">
                        <img class="agente-omni-page-imagem" :src="agente.url_imagem" :onerror="carregaPlaceHolder" v-if="!naoCarregouImagem"/>
                        <img class="agente-omni-page-imagem" src="@/assets/images/placeholders/img_placeholder.png" v-else/>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="agente-omni-page-centralizado-vertical">
                        <caixa-dados-agente 
                            :agente="agente"
                        />
                    </div>
                </div>
            </div>

            <div class="row pt-4">
                <div class="col-md-6 mx-auto">
                    <div v-html="agente.conteudo" class="agente-omni-page-conteudo-html conteudo-html-components">

                    </div>
                </div>
            </div>

            <div class="row">
                <botao-m class="agente-omni-page-entre-contato"  v-scroll-to="{ element: '#form-contato-container', duration: 1500, offset: -40 }" >
                    Entre em contato conosco!
                </botao-m>
            </div>

            <div class="row pt-3" v-if="servicosComNome && servicosComNome.length > 0">
                <servicos-agente
                    :servicos="servicosComNome"
                />
            </div>

            <div class="row pb-3 my-5" v-if="servicosComNome && servicosComNome.length > 0">
                <div class="col-md-4 mx-auto">
                    <p class="agente-omni-page-texto-micro">
                        *Serviços disponíveis apenas no ato da contratação dos produtos do Omni Banco &amp; Financeira. 
                        Consulte as condições de contratação com nosso Correspondente.
                    </p>
                </div>
            </div>

            <div class="row pt-3 mb-2" id="agente-omni-page-row-contato">
                <form-contato :agente="agente"/>
            </div>

            
        </div>

        <div class="container agente-omni-page-w-100" v-else-if="carregandoPagina">
            <h3>Carregando</h3>
        </div>

        <div class="container agente-omni-page-w-100" v-else>
            <h3>Correspondente não encontrado</h3>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BotaoM from '@/components/botoes/BotaoM';
const CaixaDadosAgente = () => import('@/components/agentes-components/CaixaDadosAgente')
import FormContato from '@/components/page-components/FormContato.vue'
import ServicosAgente from '@/components/agentes-components/ServicosAgente.vue'
import axios from 'axios';

export default {
    name: 'EncontreAgentePage',
    components: {
        BotaoM,
        CaixaDadosAgente,
        FormContato,
        ServicosAgente,
    },
    data: function() {
        return {
            agente: null,
            naoCarregouImagem: false,
            carregandoPagina: false,
        }
    },
    methods: {
        buscaAgentesApi: function(uriAgente) {
            this.carregandoPagina = true;
            axios.get(`/api/agentes/${uriAgente}`,)
                .then(response => {
                    if(response) {
                        if(response.data) {
                            this.agente = response.data.agente;
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(()=> this.carregandoPagina = false)
        },
        carregaPlaceHolder: function() {
            this.naoCarregouImagem = true;
        }
    },
    computed: {
        ...mapGetters({
            servicosDisponiveis: 'servicos/servicos',
        }),
        servicosComNome() {
            if(!this.servicosDisponiveis) {
                return [];
            }

            if(this.agente.servicos) {
                return this.agente.servicos
                    .map(servico => {
                        let serv = this.servicosDisponiveis.find(serv => serv.id == servico)
                        return {
                            id: servico,
                            nome: serv? serv.nome: '',
                        };
                    })
                    .sort((servico1, servico2) => servico1.nome > servico2.nome? 1:-1 )
            } else {
                return []
            }
        },
    },
    created() {
        this.buscaAgentesApi(this.$route.params.uri)
    }
        
}

</script>

<style scoped>
.agente-omni-page-container {
    width: 100%;
    min-height: 900px;
}

.agente-omni-page-w-100 {
    max-width: 100% !important;
}
.agente-omni-page-primeira-coluna {
    background-color: var(--azul-claro-omni);
    min-height: 300px;
}

.agente-omni-page-container-imagem {
    width: 82%;
    float: right;
}
@media only screen and (max-width:768px) {
        .agente-omni-page-container-imagem {
            margin: 0px auto;
            float: none;
        }
    }

.agente-omni-page-imagem {
    width: 100%;
    height: auto;
}

.agente-omni-page-conteudo-html {
    padding: 30px 2px;
    text-align: center;
}
.agente-omni-page-centralizado-vertical {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 65%;
    margin-left: 5%;
    margin-bottom: 45px;
    margin-right: 0;
}

    @media only screen and (max-width:768px) {
        .agente-omni-page-centralizado-vertical {
            width: 80%;
            max-width: 80%;
            padding-bottom: 10px;
        }
    }

.agente-omni-page-entre-contato {
    background-color: var(--azul-claro-omni) !important;
    width: 20% !important;
    min-width: 18em !important;
    max-width: 95% !important;
    margin: 10px auto;
    padding: 12px;
    height: auto !important;
    white-space: nowrap;
}

.agente-omni-page-texto-micro {
    font-size: 12px;
    text-align: center;
    margin: auto;
}

</style>
