import axios from 'axios';
import { SERVICOS_PADRAO } from '../assets/js/dados-padrao/ServicosPadrao.json';

export default {
    namespaced: true,
    state: {
        lista_servicos: null,
    },
    getters: {
        servicos(state) {
            return state.lista_servicos;
        },
    },

    mutations: {
        SET_SERVICOS (state, servicos) {
            state.lista_servicos = servicos;
        },
    },
    actions: {
        getServicos({ dispatch }) {
            axios.get("/api/servicos")
            .then( (response) => {

                if(response.data.servicos && response.data.servicos.length > 0) {
                    dispatch('showOverlay', false, { root: true });
                    dispatch('attempt', response.data.servicos)
                }
                else {
                    dispatch('failed', response);
                }
            })
            .catch( err => {
                dispatch('failed', err);
            });
        },
        attempt({ commit }, servicos) {
            if(servicos) {
                if(servicos.length > 0) {
                    commit('SET_SERVICOS', servicos);
                } else {
                    commit('SET_SERVICOS', SERVICOS_PADRAO);
                }
            }
        },
        failed({ commit }) {
            commit('SET_SERVICOS', SERVICOS_PADRAO);
        },
    },
}
