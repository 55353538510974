<template>
    <div class="linha-produtos-container" :style="{ 'background-color': corFundo }">
        <div class="container-fluid">

            <div class="row mt-2">
                <h2 class="linha-produtos-titulo" :style="{ 'color': corTitulo }">{{componente.titulo}}</h2>
            </div>
            <div class="row mb-3">
                <h6 class="linha-produtos-sub-titulo" :style="{ 'color': corSubTitulo }" >{{componente.sub_titulo}}</h6>
            </div>

            <div class="row mt-2">
                <div class="col-12">
                    <div class="linha-produtos-slick-wrapper">
                    <div class="linha-produtos-slick-container">
                        <VueSlickCarousel 
                        v-bind="settings"
                        >
                            <div class="linha-produtos-container-produto px-2 py-2" v-for="(item, index) in itens" v-bind:key="index">
                                <linha-produtos-caixa 
                                    :titulo="item.titulo"
                                    :urlImg="item.url_icone"
                                    :urlBotao="item.url_redir"
                                />
                            </div>

                            <template #prevArrow>
                                <span class="linha-produtos-arrow">
                                    <svg class="linha-produtos-arrow-svg linha-produtos-arrow-direita" viewBox="0 0 256 512"><path fill="black" d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"></path></svg>
                                </span>
                            </template>

                            <template #nextArrow>
                                <span class="linha-produtos-arrow">
                                    <svg class="linha-produtos-arrow-svg linha-produtos-arrow-esquerda" viewBox="0 0 256 512"><path fill="black" d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"></path></svg>
                                </span>
                            </template>

                            <template #customPaging>
                                <div class="linha-produtos-dots-class">
                                </div>
                            </template>

                        </VueSlickCarousel>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LinhaProdutosCaixa from '@/components/home-components/LinhaProdutosCaixa';
import { getCor } from '@/assets/js/SharkUtils';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    name: 'LinhasProdutos',
    components: {
        LinhaProdutosCaixa,
        VueSlickCarousel,
    },
    props: {
        id_comp: Number,
        componente: Object,
        dotClassProdutos: {
            type: String,
            default: 'linha-produtos-dots-class'
        },
    },
    computed: {
        itens() {
            if(this.componente) {
                return this.componente.itens? this.componente.itens: [];
            } else {
                return [];
            }
        },
        corTitulo() {
            if(this.componente) {
                return getCor(this.componente.cor_titulo).var_hexa;
            }
            return '#000';
        },
        corSubTitulo() {
            if(this.componente) {
                return getCor(this.componente.cor_sub_titulo).var_hexa;
            }
            return '#000';
        },
        corFundo() {
            if(this.componente) {
            return getCor(this.componente.tema).var_hexa;
            }
            return '#fff';
        },
    },
    data: function () {
        return {
            settings: {
                dots: true,
                arrows: true,
                focusOnSelect: true,
                infinite: false,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 1,
                initialSlide: 0,
                touchThreshold: 5,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 799,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            }
        }
    },
    methods: {
        
    },
}

</script>

<style>
.linha-produtos-container {
    width: 100%;
    max-width: 100vw;
    padding-top: 10px;
    padding-bottom: 20px;
}

.linha-produtos-titulo {
    text-align: center;
    margin: 0 auto;
    font-weight: 900;
}

.linha-produtos-sub-titulo {
    text-align: center;
    margin: 0 auto;
}

.linha-produtos-dots-class {
    background-color: var(--cinza-omni);
    height: 6px;
    border-radius: 3px;
    width: 25px;
}
.slick-active > .linha-produtos-dots-class {
    background-color: var(--azul-claro-omni);
}

.linha-produtos-arrow {
    height: 20px;
    width: 20px;
}
.linha-produtos-arrow::before {
    content: none;
}

.linha-produtos-arrow-svg {
    position: absolute;
    top: -50px;
    height: 100px;
    width: 100px;
}
    .linha-produtos-arrow-svg:hover {
        opacity: 0.6;
    }
    
.linha-produtos-arrow-direita {
    right: 0px;
    transform: rotate(90deg);
}
.linha-produtos-arrow-esquerda {
    transform: rotate(270deg);
}

.linha-produtos-slick-wrapper {
    max-width: 100vw !important;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 40px;
    margin: 0;
}

.linha-produtos-slick-container {
    max-width: calc(90% - 150px) !important;
    margin: 0 auto;
}

</style>

