import Vue from 'vue'
import Vuex from 'vuex'
import authAdmin from './authAdmin'
import posts from './posts'
import dadosRodape from './dadosRodape'
import setoresMenu from './setoresMenu'
import agentes from './agentes'
import servicos from './servicos'
import paginas from './paginas'
import popup from './popup'
import buscaPaginas from './buscaPaginas'
import formContato from './formContato'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sidebarAberta: false,
    overlay: false,
  },
  getters: {
    sidebarAberta(state) {
      return state.sidebarAberta;
    },
    overlay(state) {
      return state.overlay;
    },
  },
  mutations: {
    SET_SIDEBAR_ABERTA (state, data) {
      state.sidebarAberta = data;
    },
    SET_OVERLAY (state, data) {
      state.overlay = data;
    },
  },
  actions: {
    abriuSideBar({ dispatch }, abriu) {
      dispatch('attempt', abriu);
    },
    attempt({ commit }, abriu) {
      commit('SET_SIDEBAR_ABERTA', abriu);
    },

    showOverlay({ commit }, status) {
      // Se setado para true, abre contagem para desativar.
      // Por padrão desabilita sozinho em 5s, caso algum erro interno aconteça
      // previne que o overlay fique aberto eternamente por acidente
      if(status) {
        commit('SET_OVERLAY', status);
        setTimeout(() => {
          commit('SET_OVERLAY', false);
        }, 5000);
      } 
      // Se setado para false, espera 400ms para desligar, apenas por efeito visual
      else {
        setTimeout(() => {
          commit('SET_OVERLAY', false);
        }, 400);
      }
    },
  },
  modules: {
    authAdmin,
    posts,
    dadosRodape,
    setoresMenu,
    agentes,
    servicos,
    paginas,
    popup,
    buscaPaginas,
    formContato
  }
})
