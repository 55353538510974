import { formatarTelefone } from '@/assets/js/SharkUtils.js';

// Normaliza retirando acentos e deixando tudo minusculo
const normalizaString = (pString) => {
    return pString.normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase();
}

// Funcao responsavel por dizer se uma string contem a string procurada
// Pode ser substituida com um regex ou algum metodo de busca aproximada no futuro
const stringMatch = (pStringCheia, pStringProcurada) => {
    // Padronizando, retira acentos e passa tudo para lower case
    let strCheiaNormal = normalizaString(pStringCheia)
    let strProcuradaNormal = normalizaString(pStringProcurada)

    return strCheiaNormal.indexOf(strProcuradaNormal)
}

// Concatena todos os campos de uma dada entidade em uma so string
const stringForEach = (pCampos, pEntidade) => {
    let strAcumulada = ''
    pCampos.forEach((campo, index)=> {
        if(pEntidade[campo.rotulo]) {
            strAcumulada += campo.strinfica(pEntidade[campo.rotulo]) + (pCampos.length == index - 1 ? ',': ' ');
        }
    })

    return strAcumulada;
}

const stringficaTelefone = (pNumero) => {
    if(typeof(pNumero) === "number") {
        return pNumero.toString() + ', ' + formatarTelefone(pNumero);
    } else if(typeof(pNumero) === "string") {
        return pNumero;
    } else {
        return '';
    }
}

const stringficaCep = (pCep) => {
    if(typeof(pCep) === "number") {
        return pCep.toString();
    } else if(typeof(pCep) === "string") {
        return pCep;
    } else {
        return '';
    }
}

const stringficaHtml = (html) => {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}

const stringficaAgente = (pAgente) => {
    const camposAgente = [
        {rotulo: "nome", strinfica: (nome) => nome },
        {rotulo: "razao_social", strinfica: (razao_social) => razao_social },
        {rotulo: "cnpj", strinfica: (cnpj) => cnpj },
        {rotulo: "cep", strinfica: stringficaCep },
        {rotulo: "endereco", strinfica: (endereco) => endereco },
        {rotulo: "complemento", strinfica: (complemento) => complemento },
        {rotulo: "estado", strinfica: (estado) => estado },
        {rotulo: "cidade", strinfica: (cidade) => cidade },
        {rotulo: "telefone", strinfica: stringficaTelefone },
        {rotulo: "whatsapp", strinfica: stringficaTelefone },
        {rotulo: "conteudo", strinfica: stringficaHtml },
    ];

    return stringForEach(camposAgente, pAgente);
}

const stringficaItensComponentes = (pItens) => {
    if(!pItens || pItens.length == 0) {
        return '';
    }

    const camposItensComponente = [
        {rotulo: "titulo", strinfica: (titulo) => titulo },
        {rotulo: "texto", strinfica: stringficaHtml },
        {rotulo: "sub_titulo", strinfica: (sub_titulo) => sub_titulo },
    ];

    let itensStr = '';
    pItens.forEach((item) => {
        itensStr += stringForEach(camposItensComponente, item);
    });

    return itensStr;
}

const stringficaComponentes = (pComponentes) => {
    if(!pComponentes || pComponentes.length == 0) {
        return '';
    }

    const camposComponente = [
        {rotulo: "titulo", strinfica: (titulo) => titulo },
        {rotulo: "texto", strinfica: stringficaHtml },
        {rotulo: "txt_botao_1", strinfica: (txt_botao_1) => txt_botao_1 },
        {rotulo: "txt_botao_2", strinfica: (txt_botao_2) => txt_botao_2 },
        {rotulo: "sub_titulo", strinfica: (sub_titulo) => sub_titulo },
        {rotulo: "itens", strinfica: stringficaItensComponentes },
    ];

    let componentesStr = '';
    pComponentes.forEach((comp) => {
        componentesStr += stringForEach(camposComponente, comp);
    });

    return componentesStr;
}

const stringficaPagina = (pPagina) => {
    if(!pPagina) {
        return '';
    }

    const camposPagina = [
        {rotulo: "titulo", strinfica: (titulo) => titulo },
        {rotulo: "componentes", strinfica: stringficaComponentes },
    ];

    return stringForEach(camposPagina, pPagina);
}

const stringficaPost = (pPost) => {
    if(!pPost) {
        return '';
    }

    const camposPost = [
        {rotulo: "titulo", strinfica: (titulo) => titulo },
        {rotulo: "tags", strinfica: (tags) => tags },
        {rotulo: "autor", strinfica: (autor) => autor },
        {rotulo: "conteudo", strinfica: stringficaHtml },
    ];

    return stringForEach(camposPost, pPost);
}

//
const montaPreview = (pStringCheia, pIndexMatch) => {
    let tamanhoMaximo = 300;
    if(pStringCheia.length <= tamanhoMaximo) {
        return pStringCheia;
    }
        
    let textoEsquerda = pStringCheia.substring(0, pIndexMatch);
    let textoDireita = pStringCheia.substring(pIndexMatch, pStringCheia.length);
    textoEsquerda = textoEsquerda.substring(textoEsquerda.length < (tamanhoMaximo/2)? 0: textoEsquerda.length - (tamanhoMaximo/2), textoEsquerda.length)
    textoDireita = textoDireita.substring(0, textoDireita.length < (tamanhoMaximo/2)? textoDireita.length:(tamanhoMaximo/2))

    return textoEsquerda + textoDireita;
}

// -------------------------------------------------------------------------------------------------------------------------------------------
// Metodos finais
// -------------------------------------------------------------------------------------------------------------------------------------------
export const buscarAgentes = (pListaAgentes, pStringProcurada) => {
    if(!pListaAgentes || pListaAgentes.length == 0) {
        return [];
    }
    let resultado = [];
    // Para cada agente, transforma em string e procura texto da busca
    pListaAgentes.forEach((agente) => {
        let agenteString = stringficaAgente(agente);
        let indexMatch = stringMatch(agenteString, pStringProcurada)
        if(indexMatch >= 0) {
            // URI é a rota correspondente no router que direcionaria ao agente encontrado
            resultado.push({
                url: `/correspondentes/${agente.uri}`,
                titulo: agente.nome,
                match: agenteString,
                preview: montaPreview(agenteString, indexMatch),
            });
        }
    });

    return resultado;
}

export const buscarPaginas = (pListaPaginas, pStringProcurada) => {
    if(!pListaPaginas || pListaPaginas.length == 0) {
        return [];
    }
    let resultado = [];
    // Para cada pagina, transforma em string e procura texto da busca
    pListaPaginas.forEach((pagina) => {
        let paginaString = stringficaPagina(pagina);
        let indexMatch = stringMatch(paginaString, pStringProcurada)
        if(indexMatch >= 0) {
            // URI é a rota correspondente no router que direcionaria a pagina encontrada
            resultado.push({
                url: pagina.template != 'HOME'? `/pagina/${pagina.url_setor}`: '/',
                titulo: pagina.titulo,
                match: paginaString,
                preview: montaPreview(paginaString, indexMatch),
            });
        }
    });

    return resultado;
}

export const buscarPosts = (pListaPosts, pStringProcurada) => {
    if(!pListaPosts || pListaPosts.length == 0) {
        return [];
    }
    let resultado = [];
    // Para cada post, transforma em string e procura texto da busca
    pListaPosts.forEach((post) => {
        let postString = stringficaPost(post);
        let indexMatch = stringMatch(postString, pStringProcurada)
        if(indexMatch >= 0) {
            // URI é a rota correspondente no router que direcionaria a pagina encontrada
            resultado.push({
                url: `/blog/${post.id}`,
                titulo: post.titulo,
                match: post,
                preview: montaPreview(postString, indexMatch),
            });
        }
    });

    return resultado;
}

// Retorna por ex:
// { path: '/agente/1', match: 'string encontrada', texto_preview: 'blablabla... string encontrada ... bsdsadasd'}
export const buscarUrlsResultado = (pListaAgentes, pListaPaginas, pListaPosts, pStringProcurada) => {
    let resultado = buscarPaginas(pListaPaginas, pStringProcurada)
            .concat(buscarAgentes(pListaAgentes, pStringProcurada))
            .concat(buscarPosts(pListaPosts, pStringProcurada));
    return resultado;
}
