<template>
    <div class="container servicos-agente-container" v-if="servicos">

        <div class="row pb-3">
            <h2 class="servicos-agente-titulo"> Serviços </h2>
        </div>

        <div class="row">
            <div :class="classeColunasIcones" class="mx-auto" v-if="servicosFinanciamento && servicosFinanciamento.length > 0">
                <servicos-agente-bloco 
                    :servicos="servicosFinanciamento"
                />
            </div>
            <div :class="classeColunasIcones" class="mx-auto" v-for="(item, index) in servicosRestantes" v-bind:key="index">
                <servicos-agente-bloco 
                    :servicos="[item]"
                />
            </div>
        </div>
        

    </div>
</template>

<script>
import ServicosAgenteBloco from '@/components/agentes-components/ServicosAgenteBloco';

const servicosDic = {
    "FINANCIAMENTO": 1,
    "REFINANCIAMENTO": 2,
}

export default {
  name: 'ServicosAgente',
  components: {
      ServicosAgenteBloco,
  },
  props: {
    servicos: Array,
  },
  data: function() {
      return {
      }
  },
  computed: {
    // Servicos de financiamento e refinanciamento ficam na mesma coluna
    servicosFinanciamento() {
        if(this.servicos) {
            let financiamentos = this.servicos
                .filter(servicos => servicos.id == servicosDic["FINANCIAMENTO"] || servicos.id == servicosDic["REFINANCIAMENTO"])
            return financiamentos;
        } else {
            return [];
        }
    },
    servicosRestantes() {
        if(this.servicos) {
            let servs = this.servicos
                .filter(servicos => servicos.id !== servicosDic["FINANCIAMENTO"] && servicos.id !== servicosDic["REFINANCIAMENTO"])
            return servs;
        } else {
            return [];
        }
    },
    classeColunasIcones() {
        // Nro de colunas é dado pelo numero de servicos mas os servicos de financiamento ficam em uma coluna só
        let nroColunas = this.servicosRestantes.length + (this.servicosFinanciamento.length > 0? 1: 0);
        if(nroColunas <= 2)
          return 'col-sm-5 col-xl-4';
        if(nroColunas <= 3)
          return 'col-sm-4 col-xl-3';

        return 'col-sm-5 col-xl-4';
    }
  },
  methods: {
      
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.servicos-agente-container {
    width: 90%;
    max-width: 90%;
}

.servicos-agente-titulo {
    margin: 0 auto;
}

.servicos-agente-paragrafo-grande {
    color: #fff;
    font-size: 18px;
}

.servicos-agente-texto-pequeno,
.servicos-agente-botao-acessar {
    font-size: 14px;
}

.servicos-agente-paragrafo-grande,
.servicos-agente-texto-pequeno {
    margin-bottom: 3px;
    color: #fff;
}

.servicos-agente-botao-acessar {
    height: 50px !important;
    margin-top: 20px;
}

.servicos-agente-icone {
    max-height: 15px;
    margin-right: 6px;
}

.servicos-agente-img {
    width: auto;
    height: 1.8rem;
    position: relative;
    top: -2px;
}

.servicos-agente-container-imagem {
    width: 100%;
    height: auto;
}

.servicos-agente-imagem {
    width: 90px;
}

</style>
