import axios from 'axios';

export default {
    namespaced: true,
    state: {
        lista_posts: null,
    },

    getters: {
        posts(state) {
            return state.lista_posts;
        },
        getPostCounter(state) {
            if(state.lista_posts)
                return state.lista_posts.length;
            else
                return 0;
        },
        getComentarioCounter(state) {
            if(!state.lista_posts)
                return 0;
            let cont = 0;
            state.lista_posts.forEach(post => {
                cont += post.comentarios.length;
            });
            return cont;
        }
    },

    mutations: {
        SET_POSTS (state, posts) {
            state.lista_posts = posts;
        },
    },

    actions: {
        getPosts({ dispatch }) {
            dispatch('showOverlay', true, { root: true });
            return axios.get("/api/posts")
            .then( (response) => {
                dispatch('showOverlay', false, { root: true });
                dispatch('attempt', response.data.posts);
            })
            .catch( err => {
                dispatch('showOverlay', false, { root: true });
                dispatch('failed', err);
            });
        },
        attempt({ commit, state }, posts) {
            if(posts)
                commit('SET_POSTS', posts);
            
            if(!state.posts)
                return;    
        },
        failed(_, err) {
            console.log('falha ao carregar posts');
            console.log(err);
        },
    },
}
