<template>
    <div id="app">
        <HeaderMenu v-if="!verificaRotaAdmin($route.path)" />
        <router-view />
        <Footer v-if="!verificaRotaAdmin($route.path)" />
        <b-overlay id="overlay-background"
                   :show="showOverlay"
                   no-wrap
                   rounded="sm"
                   fixed 
        />
        <popup v-if="mostrarPopup($route.path)" />
    </div>
</template>

<script>
    import HeaderMenu from '@/components/header-components/HeaderMenu.vue'
    import Footer from '@/components/Footer.vue'
    import Popup from '@/components/Popup.vue'
    import { mapGetters, mapActions } from 'vuex';
    import { criaHandTalk } from '@/assets/js/SharkUtils';

export default {
    name: 'App',
    components: {
        HeaderMenu,
        Footer,
        Popup,
    },
    methods: {
        ...mapActions({
            getServicos: 'servicos/getServicos',
            getPaginas: 'paginas/getPaginas',
            getFormContato: 'formContato/getFormContato',
        }),
        verificaRotaAdmin: function(url_rota) {
            let rotaRaiz = url_rota.split('/')[1];
            if(rotaRaiz == 'admin')
                return true;
            return false;
        },
        mostrarPopup: function (route) {
            if (route == '/' || route == '') { return true }  return false;
        }
    },
    computed: {
        ...mapGetters({
            showOverlay: 'overlay',
        })
    },
    created() {
        this.getPaginas();
    },
    mounted() {
        this.getFormContato();
        this.getServicos();
        setTimeout(() => criaHandTalk(), 2000);
    },
}
</script>

<style>
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    #app {
        font-family: MuseoSans;
    }

    @font-face {
        font-family: MuseoSans;
        src: url(./assets/fonts/MuseoSans-100.otf);
        font-weight: 100;
        font-style: normal;
    }

    @font-face {
        font-family: MuseoSans;
        src: url(./assets/fonts/MuseoSans-300.otf);
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: MuseoSans;
        src: url(./assets/fonts/MuseoSans_500.otf);
        font-weight: bold;
        font-style: normal;
    }

    @font-face {
        font-family: MuseoSans;
        src: url(./assets/fonts/MuseoSans_500.otf);
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: MuseoSans;
        src: url(./assets/fonts/MuseoSans_700.otf);
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: MuseoSans;
        src: url(./assets/fonts/MUSEOSANS-900-nova.OTF);
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family: Museo;
        src: url(./assets/fonts/MuseoSans-900.otf);
        font-weight: 900;
        font-style: normal;
    }
    
    @font-face {
        font-family: Neutra-Display;
        src: url(../src/assets/fonts/Neutra-Display.otf);
    }

.conteudo-html-components p {
    font-weight: normal;
    line-height: normal;
    margin-bottom: 0.3rem !important;
}

.mw-60 {
    max-width: 60% !important;
}

.hover-07:hover {
  opacity: 0.7;
}

</style>
