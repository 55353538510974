<template>
    <div class="busca-paginas-page-container">

        <div class="container-fluid" >
            <div class="row busca-paginas-page-row-azul">
                <div class="col-10 mx-auto">
                    <h1 class="busca-paginas-page-titulo-h1">Busca</h1>
                </div>
            </div>
        </div>

        <div class="container-fluid" v-if="resultadoOk">
            <div class="row busca-paginas-page-row-resultados">
                <div class="col-md-10 mx-auto" v-if="paginasEncontradas.length">
                    <div class="d-flex flex-row flex-wrap">

                        <div v-for="(pagina, index) in paginasEncontradas" :key="index" class="busca-paginas-page-caixa-resultados">
                            <s-router-link :url="pagina.url">
                                <h4 class="busca-paginas-page-titulo-pagina"> {{ pagina.titulo }} </h4>
                            </s-router-link>
                            <p> {{ pagina.preview }} </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
            

        <div class="container-fluid" v-else-if="carregandoPagina">
            <div class="row busca-paginas-page-row-buscando">
                <h3>Buscando...</h3>
            </div>
        </div>

        <div class="container-fluid" v-else>
            <div class="row busca-paginas-page-row-nao-encontrado">
                <div class="col-12">
                    <h3>A busca por "{{textoBusca}}" não retornou resultados</h3>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SRouterLink from '@/components/SRouterLink';

// import axios from 'axios';

export default {
    name: 'BuscaPaginasPage',
    components: {
        SRouterLink,
    },
    data: function() {
        return {
            carregandoPagina: false,
        }
    },
    methods: {
        buscaPaginas: function(pStringBusca) {
            this.carregandoPagina = true;
            this.buscaPaginasStore(pStringBusca)
                .finally(() => {
                    this.carregandoPagina = false;
                });
        },
        ...mapActions({
            buscaPaginasStore: 'buscaPaginas/buscaPaginas',
        }),
    },
    computed: {
        ...mapGetters({
            paginasEncontradas: 'buscaPaginas/paginas',
        }),
        resultadoOk() {
            if(this.paginasEncontradas && this.paginasEncontradas.length > 0)
                return true;
            else
                return false;
        },
        textoBusca() {
            if(this.$route.query) {
                return this.$route.query.texto;
            } else {
                return '';
            }
        },
       
    },
    created() {
        this.buscaPaginas(this.$route.query.texto)
    },
    beforeRouteUpdate(to, from, next) {
        this.buscaPaginas(to.query.texto)
        next()
    },
        
}

</script>

<style scoped>

.busca-paginas-page-row-azul {
    background-color: var(--azul-claro-omni);
    min-height: 180px;
    padding: 100px 20px 20px 50px
}

.busca-paginas-page-titulo-h1 {
    color: white;
}

.busca-paginas-page-caixa-resultados {
    width: 45%;
    max-width: 45%;
    margin: 25px auto;
}

@media only screen and (max-width:768px) {
    .busca-paginas-page-caixa-resultados {
        width: 85%;
        max-width: 85%;
    }
}

.busca-paginas-page-titulo-pagina {
    color: var(--azul-claro-omni);
}

.busca-paginas-page-titulo-pagina:hover {
    opacity: 0.7;
    text-decoration: underline;
    cursor: pointer;
}

.busca-paginas-page-row-nao-encontrado,
.busca-paginas-page-row-buscando,
.busca-paginas-page-row-resultados {
    min-height: 300px;
    padding: 20px 20px 20px 20px;
}

</style>
