import axios from 'axios';
import { DADOS_SOCIAIS_PADRAO, BLOCOS_RODAPE_PADRAO} from '../assets/js/dados-padrao/RodapePadrao.json';

export default {
    namespaced: true,
    state: {
        dados_rodape: null,
        dados_sociais_rodape: null,
    },
    getters: {
        dadosRodape(state) {
            return state.dados_rodape;
        },
        dadosSociaisRodape(state) {
            return state.dados_sociais_rodape;
        },
    },
    mutations: {
        SET_DADOS_RODAPE (state, dados) {
            state.dados_rodape = dados;
        },
        SET_DADOS_SOCIAIS_RODAPE (state, dados) {
            state.dados_sociais_rodape = dados;
        },
    },
    actions: {
        getDadosRodape({ dispatch }) {
            return axios.get("/api/dados-rodape")
                .then( (response) => {

                    if(response.data.dados_rodape && response.data.dados_rodape.length > 0) {
                        dispatch('attempt', response.data.dados_rodape);
                    }
                    else {
                        dispatch('failed', response);
                    }
                })
                .catch( err => {
                    dispatch('failed', err);
                });
        },
        attempt({ commit }, dados) {
            if(dados) {
                commit('SET_DADOS_RODAPE', dados.sort((it1, it2) => it1.ordem - it2.ordem));
            }
        },
        failed({ commit }, err) {
            console.log('falha ao carregar dados do rodape, carregando o padrao');
            console.log(err);
            commit('SET_DADOS_RODAPE', BLOCOS_RODAPE_PADRAO);
        },
        getDadosSociaisRodape({ dispatch }) {
            return axios.get("/api/dados-sociais")
                .then( (response) => {
                    if(response.data.dados_sociais_rodape && response.data.dados_sociais_rodape.length > 0) {
                        dispatch('attemptSociais', response.data.dados_sociais_rodape);
                    } else {
                        dispatch('failedSociais', response);
                    }
                })
                .catch( err => {
                    dispatch('failedSociais', err);
                });
        },
        attemptSociais({ commit }, dados) {
            if(dados)
                commit('SET_DADOS_SOCIAIS_RODAPE', dados);
        },
        failedSociais({ commit }, err) {
            console.log('falha ao carregar redes sociais, carregando o padrao');
            console.log(err);
            commit('SET_DADOS_SOCIAIS_RODAPE', DADOS_SOCIAIS_PADRAO);
        },
    },
}
