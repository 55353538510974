import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import * as sharkUtils from '@/assets/js/SharkUtils'

import HomePage from '../views/HomePage.vue'
import RenderizadorPage from '../views/RenderizadorPage.vue'

import BlogPage from '../views/blog/BlogPage.vue'
import BlogPostPage from '../views/blog/BlogPostPage.vue'
import EncontreAgentePage from '../views/agentes/EncontreAgentePage.vue'
import ResultadoAgentesPage from '../views/agentes/ResultadoAgentesPage.vue'
import SejaAgentePage from '../views/agentes/SejaAgentePage.vue'
import AgenteOmniPage from '../views/agentes/AgenteOmniPage.vue'
import BuscaPaginasPage from '../views/BuscaPaginasPage.vue'

// Modulos
import adminRoutes from "./admin.routes"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/correspondentes',
    name: 'EncontreAgentePage',
    component: EncontreAgentePage,
    meta: { title: 'Encontre um correspondente', requiresAuth: true },
  },
  {
    path: '/correspondentes/:uri',
    name: 'AgenteOmniPage',
    component: AgenteOmniPage,
    meta: { title: 'Correspondente Omni', requiresAuth: true },
  },
  {
    path: '/buscar-correspondentes',
    name: 'ResultadoAgentesPage',
    component: ResultadoAgentesPage,
    props: true,
    meta: { title: 'Correspondentes Omni', requiresAuth: true },
  },
  {
    path: '/seja-correspondente',
    name: 'SejaAgentePage',
    component: SejaAgentePage,
    meta: { title: 'Seja um Correspondente Omni', requiresAuth: true },
  },
  {
    path: '/blog',
    name: 'BlogPage',
    component: BlogPage,
    meta: { title: 'Blog da Omni', requiresAuth: true },
  },
  {
    path: '/blog/:id',
    name: 'BlogPostPage',
    component: BlogPostPage,
    props: true,
    meta: { title: 'Blog da Omni', requiresAuth: true },
  },
  {
    path: '/buscar',
    name: 'BuscaPaginasPage',
    component: BuscaPaginasPage,
    props: true,
    meta: { title: 'Busca', requiresAuth: true },
  },
  {
    path: '/pagina/:url',
    component: RenderizadorPage,
    name: 'RenderizadorPage',
    props: true,
    meta: { tituloDinamico: true, requiresAuth: true },
  },
  // Rotas administrativas com autenticacao
  ...adminRoutes,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } 
    
    if (to.hash && document.querySelector(to.hash)) {
      return setTimeout(() => { 
        document.querySelector(to.hash).scrollIntoView({ behavior: 'smooth' })
      }, 300)
    }
    if (to.hash) {
      return setTimeout(() => { 
        if(document.querySelector(to.hash)) {
          return document.querySelector(to.hash).scrollIntoView({ behavior: 'smooth' })
        }
        setTimeout(() => document.querySelector(to.hash)? document.querySelector(to.hash).scrollIntoView({ behavior: 'smooth' }):null, 1500)
      }, 1000)
    }

    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
    // Abrir chat da omni pelo hash
    if (to.hash === '#chat') {
        sharkUtils.abrirChat();
        router.push({ hash: '' }).catch(() => {});
    }

  // Se não for rota que requer autenticação, nem verifica nada
  if (!to.matched.some(record => record.meta.requiresAuth)) {
    return next();
  }

    let acessarPaginaAdmin = false;
    if (to.name !== 'LoginAdmPage' && to.meta.pageAdmin) {
        acessarPaginaAdmin = true;
    }

  // Pega admin do local storage, se nao tem retorna nao autorizado
  let admin;
  try {
    admin = JSON.parse(localStorage.getItem('admin'))
  } catch(e) {
    admin = null
  }
  if(!admin) {
    alert('Não autorizado')
    return next({
        name: 'LoginAdmPage',
        query: { acessoRestrito: acessarPaginaAdmin }
    })
  }

  let token;
  try {
    // A cada navegação, verifica validade da autenticação
    await store.dispatch('authAdmin/attempt', admin)
    token = (store.getters['authAdmin/admAuth'])
  } catch(e) {
    console.log('beforeEachError', e)
    alert('Erro de autenticação')
    return next({
        name: 'LoginAdmPage',
        query: { acessoRestrito: acessarPaginaAdmin }
    })
  }

  if (!token) {
      alert('Não autorizado')
      return next({
          name: 'LoginAdmPage',
          query: { acessoRestrito: acessarPaginaAdmin }
      })
    }

  return next();
});

router.afterEach((to) => {
  // Fecha o botao do menu quando navega, se o botao hamburger estiver visivel(width <= 1024)
  const botaoMenu = document.querySelector('.navbar-toggler')
  if(botaoMenu) {
      let isVisible = botaoMenu.offsetWidth > 0 || botaoMenu.offsetHeight > 0;
      const menuCollapse = document.querySelector('.navbar-collapse')
      if(isVisible && menuCollapse.classList.contains('show') ) {
          botaoMenu.click();
      }
  }

  if(to.meta && to.meta.tituloDinamico) {
    return
  }

  Vue.nextTick(() => {
    if(to.meta && to.meta.title) {
      document.title = `Omni Banco & Financeira - ${to.meta.title}`
      return;
    }
    document.title = `Omni Banco & Financeira` 
  })

})
export default router
