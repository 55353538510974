<template>
    <div class="container-form-agentes">
        <div class="container-fluid">
            <div class="row" v-if="titulo">
                <h2 class="mx-auto titulo-form-agentes"> {{ titulo }} </h2>
            </div>
            <div class="row row-background-e-formulario">

                <div class="col coluna-vazia-formulario-agentes">
                </div>


                <div class="col-6 col-sm-6 col-md-6 coluna-formulario-agentes">
                    <div class="container">

                        <div class="row">
                            <div class="col-lg-8">
                                <div class="container">


                                    <div class="row" v-if="titulo_interno">
                                        <div class="col-12">
                                            <h5 class="titulo-interno-form-agentes"> {{ titulo_interno }} </h5>
                                        </div>
                                    </div>

                                    <div class="row py-2">
                                        <div class="col-10 pr-2">
                                            <input v-model="cep" type="text" class="form-agentes-input-cep" placeholder="Insira seu CEP">
                                        </div>
                                    </div>

                                    <div class="row py-2">
                                        <div class="col-10 pr-2">
                                            <select v-model.number="produto" class="form-agentes-select-produto">
                                                <option :value="0">Escolha um Produto</option>
                                                <option v-for="(servico, i) in servicosDisponiveis"
                                                        v-bind:key="i"
                                                        :value="servico.id">
                                                    {{ servico.nome }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-12 px-0 py-2">
                                        <botao-p @click="buscaAgente"
                                                 texto_botao="Buscar"
                                                 background_color="var(--laranja-claro-omni)"
                                                 largura="8rem" altura="2.8rem"
                                        />
                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <hr class="separador-form-agentes-rodape">
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <p class="texto-pequeno-form-agentes-rodape">Quer ser um Correspondente Bancário Omni?</p>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <p class="texto-pequeno-form-agentes-rodape font-weight-bold" style="font-family:Museo;">
                                            <router-link to="/seja-correspondente" class="texto-pequeno-form-agentes-rodape">
                                                Preencha seus dados clicando aqui.
                                            </router-link>
                                            </p>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</template>


<script>
import BotaoP from '@/components/botoes/BotaoP.vue';
import { mapGetters } from 'vuex';

// 'Grupo de máscara 1'
export default {
  name: 'FormAgentes',
  components: {
      BotaoP,
  },
  props: {
    id_comp: Number,
    componente: Object,
  },
  methods: {
      atualizaProdutoSelecionado(pValor) {
          this.produto_selecionado = pValor;
      },
      buscaAgente: function() {
        // Limpa caracteres nao numericos
        let cepNumeros = this.cep.replace(/\D/g,'');
        this.$router.push({ name: 'ResultadoAgentesPage', query: { cep: cepNumeros, produto: this.produto } });
      },
  },
  computed: {
        ...mapGetters({
            servicosDisponiveis: 'servicos/servicos',
        }),
      titulo() {
        if(this.componente) {
            if(this.componente.titulo) {
                return this.componente.titulo;
            }
        }
        return '';
      },
      titulo_interno() {
        if(this.componente) {
            if(this.componente.texto) {
                return this.componente.texto;
            }
        }
        return 'Localize o Agente mais próximo para contratar um produto Omni.';
      },
      produto: {
        get() {
            if(this.produto_selecionado !== 0) {
                return this.produto_selecionado;
            }

            if(this.componente) {
                if(this.componente.int_geral) {
                    this.atualizaProdutoSelecionado(this.componente.int_geral);
                    return this.componente.int_geral;
                }
            }
            return 0;
        },
        set(pValor) {
            this.produto_selecionado = pValor;
        }
      }
  },
  data: function() {
    return {
        produto_selecionado: 0,
        cep: '',
    }
  },
}


</script>

<style scoped>
    .container-form-agentes {
        width: 100% !important;
        margin: 40px 50px;
    }

    @media (min-width: 1800px) {
        .container-form-agentes {
            padding: 0px 90px 0px 30px;
        }
    }

    .row-background-e-formulario {
        background-image: url('../../assets/images/Grupo de máscara 1.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 12% 0;
        min-height: 400px;
    }

    .coluna-formulario-agentes {
        background-color: var(--azul-claro-omni);
        padding-left: 30px;
        padding-right: 35px;
        padding-top: 40px;
        padding-bottom: 20px;
        border-radius: 0px 9px 9px 0px;
    }

    .titulo-interno-form-agentes {
        color: #fff;
        font-weight: bold;
    }

    .texto-pequeno-form-agentes-rodape {
        color: #fff;
    }


    .form-agentes-input-cep,
    .form-agentes-select-produto {
        font-family: Museo;
        border-radius: 6px;
        outline: none;
        border: none;
        height: 3rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: small;
        font-weight: bold;
        width: 100%;
    }

    .form-agentes-select-produto {
        color: var(--azul-claro-omni) !important;
    }

    .separador-form-agentes-rodape {
        background-color: #fff;
        width: 100%;
    }

    .titulo-form-agentes {
        min-height: 80px;
        font-weight: bolder;
    }
</style>