<template>
    <div class="contaiter-fluid mx-0 banner-agentes">

        <img :src="loadImg" class="w-100" />

        <div class="row banner-agentes-texto">
            <div class="col-6 align-self-center cabecalho">{{ banner.txtCabecalho }}</div>
            <div class="col-6 align-self-center mx-0 pt-sm-3 conteudo">{{ banner.txtConteudo }}</div>
        </div>
    </div>
</template>
<script>
    import { LISTA_CORES } from '@/assets/js/dicionarioPaginas';

    export default {
        name: 'BannerSejaAgente',
        props: {
            id_comp: Number,
            componente: Object,
        },
        computed: {
            loadImg: function () {
                let pathComplete = `\\storage\\imagens\\${ this.banner.imagem }`;
                return pathComplete;
            }
        },
        data: function() {
            return {
                listaCores: LISTA_CORES,
                banner: {
                    imagem: 'seja-um-agente.jpg',
                    txtCabecalho: 'Venha para o nosso time',
                    txtConteudo: 'Tenha à disposição um portfólio completo de produtos e serviços para ofertar aos seus clientes, com suporte de uma equipe de alta performance para que sua operação aconteça com maior eficiência e segurança.',
                },
            }
        },
        methods: {
            getCorLista: function(COR) {
                return this.listaCores.find(cor => cor.chave === COR);
            }
        },
    }
</script>

<style scoped>
    .banner-agentes {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    .banner-agentes-texto {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 5%;
    }

    .cabecalho {
        font-family: MuseoSans;
        padding-right: 11.5vw;
        font-weight: 900;
        z-index: 100;
        color: white;
        font-size: clamp(0.8rem, 5.1vw, 5rem);
        line-height: 110%;
    }
    .conteudo {
        z-index: 100;
        color: white;
        font-size: clamp(0.5rem, 2.3vw, 2.2rem);
        font-weight: 400;
        line-height: 100%;
    }
</style>