<template>
    <div class="resultado-agentes-page-container">
        <div class="container resultado-agentes-page-w-100">

            <div class="row resultado-agentes-page-row-inicial">
                <div class="col-10 mx-auto">

                    <div class="resultado-agentes-page-container-imagem-inicial">
                        <img class="resultado-agentes-page-imagem-inicial" src="@/assets/images/agentes/agente_omni_busca.png"/>
                    </div>

                    <div class="resultado-agentes-page-container-texto-botao">
                        <h1 class="resultado-agentes-page-titulo"> Onde estamos</h1>
                        <botao-m class="resultado-agentes-page-botao-encontre" @click="voltaBusca">
                            Nova Busca
                        </botao-m>
                    </div>

                </div>
            </div>

            <div class="row my-5" v-if="!carregando">
                <div class="col-md-10 mx-auto" v-if="agentesEncontrados.length">
                    <div class="d-flex flex-row flex-wrap">

                        <div v-for="(agenteEncontrado, index) in agentesEncontrados" :key="index" class="resultado-agentes-page-caixa-resultado">
                            <h5 class="resultado-agentes-page-texto-distancia pl-3">{{agenteEncontrado.distancia? (agenteEncontrado.distancia/1000).toFixed(1) + ' km de distância': ''}}</h5>
                            <caixa-resultado-agente
                                :key="index+'i'"
                                :agente="agenteEncontrado"
                            />
                        </div>

                    </div>

                    <div class="w-100 mt-4">
                        <botao-m class="d-block resultado-agentes-page-botao-abrir" @click="abrirMais" v-if="maxResultados < agentes.length"> Abrir mais </botao-m>
                    </div>

                </div>

                <div class="col-md-10 mx-auto" v-else-if="!erro">
                    <h3 class="resultado-agentes-page-nao-h3-encontrado"> Não foram encontrados agentes para o serviço desejado. </h3>
                </div>

                <div class="col-md-10 mx-auto" v-else>
                    <h3 class="resultado-agentes-page-h3-erro"> {{ erro }} </h3>
                </div>
            </div>

            <div class="row my-5" v-if="carregando">
                <div class="col-md-10 mx-auto" >
                    <h3 class="resultado-agentes-page-h3-loading"> Carregando... </h3>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BotaoM from '@/components/botoes/BotaoM';
import axios from 'axios';
import CaixaResultadoAgente from '@/components/agentes-components/CaixaResultadoAgente';

import GeocodeUtils from '@/assets/js/geocode/GeocodeUtils';
import { chaveGeocoding } from '@/config/googleConfig';
import { loadGmapApi, gmapApi } from "vue2-google-maps";

export default {
    name: 'ResultadoAgentesPage',
    components: {
        BotaoM,
        CaixaResultadoAgente,
    },
    props: {
    },
    data: function() {
        return {
            carregando: false,
            erro: '',
            agentes: [],
            maxResultados: 8,
            geocoder: null,
        }
    },
    methods: {
        voltaBusca: function() {
            this.$router.push({ name: 'EncontreAgentePage' });
        },
        buscaAgenteApiJs: async function() {
            this.geocoder = new this.google.maps.Geocoder()
            this.carregando = true;
            this.erro = '';
            const params = {
                cod_cidade: 0,
                produto: parseInt(this.produto) || 0,
            };
            // Busca os agentes filtrando por serviço
            try {
                const respostaAgentes = await axios.get('/api/agentes/busca', {params})
                if(!respostaAgentes || !respostaAgentes.data) {
                    this.carregando = false;
                    return;
                }
                // Se não foi informado CEP não ordena por distancia nem faz nenhuma busca a mais.
                if(!this.cep) {
                    this.agentes = respostaAgentes.data.agentes;
                    this.carregando = false;
                    return;
                }

                this.geocoder.geocode({
                    address: this.cep,
                    region: 'br'
                },
                (resposta, status)=> {
                    if(status != 'OK') {
                        this.erro = 'Erro na busca de dados do CEP';
                        this.carregando = false;
                        return;
                    }
                    const dadosCep = GeocodeUtils.handleRespostaApiGoogleJs(resposta, this.cep)
                    if(!dadosCep) {
                        this.agentes = respostaAgentes.data.agentes;
                        this.carregando = false;
                        return;
                    }
                    this.agentes = GeocodeUtils
                                    .calculaDistanciasAgentesOrigem(respostaAgentes.data.agentes, dadosCep.latitude, dadosCep.longitude)
                                    .sort((ag1, ag2) => ag1.distancia - ag2.distancia);
                    this.carregando = false;
                })
            } catch(e) {
                this.erro = 'Erro interno na busca dos agentes';
                this.carregando = false;
                console.log('erro interno', e)
            }
        },
        abrirMais: function() {
            this.maxResultados += 8;
        },
    },
    computed: {
        ...mapGetters({
            servicosDisponiveis: 'servicos/servicos',
        }),
        cep() {
            try {
                return this.$route.query.cep;
            } catch {
                return '';
            }
        },
        produto() {            
            try {
                return this.$route.query.produto;
            } catch {
                return 0;
            }
        },
        agentesEncontrados() {
            if(this.agentes) {
                return this.agentes.slice(0, this.maxResultados);
            }
            return [];
        },        
        google: gmapApi,
    },
    watch: {
        // whenever question changes, this function will run
        google: function () {
            this.buscaAgenteApiJs()
        }
    },
    beforeMount() { 
        if(!this.google) {
            loadGmapApi({
                key: chaveGeocoding,
                libraries: "places geocoding ",
                language: "pt"
            });
            return
        }
        this.buscaAgenteApiJs()

    },
        
}

</script>

<style scoped>
.resultado-agentes-page-container {
    width: 100%;
    min-height: 900px;
}

.resultado-agentes-page-w-100 {
    max-width: 100% !important;
}
.resultado-agentes-page-row-inicial {
    background-color: var(--azul-claro-omni);
    min-height: 360px;
}
.resultado-agentes-page-container-imagem-inicial {
    height: 285px;
    max-height: 285px;
    position: relative;
    float: right;
    bottom: -110px;
}
    .resultado-agentes-page-imagem-inicial {
        width: auto;
        height: 90%;
    }


.resultado-agentes-page-botao-encontre {
    width: 100% !important;
    margin: 15px auto;
}

.resultado-agentes-page-titulo {
    color: white;
}

.resultado-agentes-page-container-texto-botao {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.resultado-agentes-page-caixa-resultado {
    width: 45%;
    max-width: 45%;
    margin: 25px auto;
}

@media only screen and (max-width:1023px) {
    .resultado-agentes-page-caixa-resultado {
        width: 85%;
        max-width: 85%;
    }
    .resultado-agentes-page-container-imagem-inicial {
        height: 180px;
        max-height: 180px;
        position: relative;
        float: right;
        bottom: -203px;
        left: 25px;
    }
}

.resultado-agentes-page-nao-encontrado {
    text-align: center;
}

.resultado-agentes-page-botao-abrir {
    width: 250px !important;
    padding: 20px !important;
    height: auto !important;
    margin: 0 auto !important;
}

.resultado-agentes-page-texto-distancia {
    font-size: 16px;
}

</style>
