export const TIPOS_TEMPLATES = [
        {chave: "PRODUTOS", valor: "Produtos"},
        {chave: "INTERNA", valor: "Pagina Interna"},
        {chave: "HOME", valor: "Home"},
];


export const TIPOS_COMPONENTES = [
        // Produzidas inicialmente p/ paginas de produtos
        {chave: "BANNER_PADRAO", valor: "Banner Padrao"},
        {chave: "IMG_DIREITA", valor: "Imagem à Direita"},
        {chave: "IMG_ESQUERDA", valor: "Imagem à Esquerda"},
        {chave: "IMAGEM_FAIXA_TEXTO", valor: "Imagem acima de faixa de texto."},
        {chave: "LISTA_ICONES", valor: "Lista de Icones"},
        {chave: "LISTA_ICONES_ESQ", valor: "Lista de Icones alinhada à Esquerda"},
        {chave: "COLUNAS_BOTAO_IMG", valor: "Colunas com botão e/ou imagem"},
        {chave: "AGENTES", valor: "Agentes"},
        {chave: "CONTATO", valor: "Contato"},
        {chave: "FAQ", valor: "Dúvidas Frequentes"},
        // Produzidas para páginas internas
        // Trabalhe conosco.
        {chave: "ROWS_CENTRALIZADAS", valor: "Linhas centralizadas"},
        // Quem somos.
        {chave: "LISTA_CARROSSEL", valor: "Lista carrossel"},
        // Produzidas para a home
        {chave: "BANNER_HOME", valor: "Banner da Home"},
        {chave: "LINHA_PRODUTOS", valor: "Linhas com produtos"},
        {chave: "VALIDA_BOLETO", valor: "Validador de boletos"},
        {chave: "ULTIMAS_NOTICIAS", valor: "Últimas notícias"},
        {chave: "IMG_DIREITA_HOME", valor: "Imagem à Direita Home"},
        {chave: "IMG_ESQUERDA_HOME", valor: "Imagem à Esquerda Home"},
        // Mapa do google
        {chave: "MAPA_GOOGLE", valor: "Mapa do Google"},
];


export const LISTA_CORES = [
        {chave: "AZUL_CLARO", valor: "Azul Claro", hexa:"#147DD2", var_hexa: "var(--azul-claro-omni)"},
        {chave: "AZUL", valor: "Azul", hexa:"#0A5091", var_hexa: "var(--azul-omni)"},
        {chave: "AZUL_ESCURO", valor: "Azul Escuro", hexa:"#253573", var_hexa: "var(--azul-escuro-omni)"},

        {chave: "LARANJA", valor: "Laranja", hexa:"#ff7323", var_hexa: "var(--laranja-claro-omni)"},
        
        {chave: "BRANCO", valor: "Branco", hexa:"#fff", var_hexa: "var(--branco-omni)"},
        {chave: "CINZA", valor: "Cinza", hexa:"#C3C3C3", var_hexa: "var(--cinza-omni)"},
        {chave: "CINZA_CLARO", valor: "Cinza claro", hexa:"#707070", var_hexa: "var(--cinza-areia-omni)"},
        {chave: "CHUMBO", valor: "Chumbo", hexa:"#232323", var_hexa: "var(--chumbo-omni)"},
];


export const LISTA_TEMAS = [
        {
                chave: "LARANJA",
                cores: {
                        primaria: { chave: "LARANJA" },
                        secundaria: { chave: "AZUL" },
                        terciaria: { chave: "BRANCO" },
                },
        },
        {
                chave: "AZUL",
                cores: {
                        primaria: { chave: "AZUL" },
                        secundaria: { chave: "BRANCO" },
                        terciaria: { chave: "LARANJA" },
                },
        },
        {
                chave: "CINZA",
                cores: {
                        primaria: { chave: "CINZA" },
                        secundaria: { chave: "BRANCO" },
                        terciaria: { chave: "LARANJA" },
                },
        },
        {
                chave: "BRANCO",
                cores: {
                        primaria: { chave: "BRANCO" },
                        secundaria: { chave: "AZUL" },
                        terciaria: { chave: "CINZA" },
                },
        },
];