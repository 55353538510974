<template>
    <div class="linha-produtos-caixa-container">
        <div class="container-fluid">

            <div class="row">
                <s-router-link :url="urlBotao?urlBotao:''" class="w-100">
                    <div class="linha-produtos-caixa-img-container">
                        <img class="linha-produtos-caixa-img" :src="urlImg" :alt="titulo">
                    </div>
                </s-router-link>
            </div>

            <div class="row py-3">
                <div class="linha-produtos-caixa-container-titulo mx-auto">
                    <span class="linha-produtos-caixa-titulo">{{titulo}}</span>
                    <s-router-link :url="urlBotao?urlBotao:''" class="linha-produtos-caixa-router-link">
                        <button class="linhas-produtos-caixa-botao">
                            <span class="linha-produtos-caixa-seta">
                                <svg class="linha-produtos-caixa-seta-svg" viewBox="0 0 256 512"><path fill="white" d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"></path></svg>
                            </span>
                        </button >
                    </s-router-link>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import SRouterLink from '@/components/SRouterLink';

export default {
    name: 'LinhasProdutosCaixa',
    components: {
        SRouterLink,
    },
    props: {
        titulo: String,
        urlImg: String,
        urlBotao: String,
    },
    data: function () {
        return {
        }
    },
    methods: {
        
    },
    created() {
    },
}

</script>

<style scoped>

/* .linha-produtos-caixa-container {
    border: 1px solid red;
} */

.linha-produtos-caixa-img-container {
    width: 100%;
    background: var(--cinza-claro-omni);
    border-radius: 8px;
}
    .linha-produtos-caixa-img-container:hover {
        opacity: 0.7;
    }

.linha-produtos-caixa-img {
    width: 100%;
    height: auto;
}

.linha-produtos-caixa-container-titulo {
    position: relative;
}

.linha-produtos-caixa-titulo {
    display: inline-block;
    position: relative;
    color: var(--chumbo-omni);
    padding-left: 6px;
    padding-right: 12px;
    top: -3px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 24px;
}

.linhas-produtos-caixa-botao {
    border: none;
    border-radius: 10px;
    outline: none;
    width: 60px;
    height: 60px;
    background-color: var(--laranja-claro-omni);
}
    .linhas-produtos-caixa-botao:hover {
        opacity: 0.7;
    }

.linha-produtos-caixa-seta {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
}

.linha-produtos-caixa-seta-svg {
    position: absolute;
    left: 55%;
    top: 50%;
    -ms-transform: translate(-50%, -50%) rotate(270deg);
    transform: translate(-50%, -50%) rotate(270deg);
    height: 40px;
    width: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px){
    .linha-produtos-caixa-titulo {
        font-size: 21px;
        padding-left: 2px;
        padding-right: 8px;
    }
    .linhas-produtos-caixa-botao {
        width: 50px;
        height: 50px;
    }
    .linha-produtos-caixa-router-link {
        margin: 0px auto;
        width: 50px;
        height: 50px;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
    .linha-produtos-caixa-titulo {
        font-size: 18px;
        padding-left: 0px;
        padding-right: 4px;
    }
    .linhas-produtos-caixa-botao {
        width: 40px;
        height: 40px;
    }
    .linha-produtos-caixa-router-link {
        margin: 0px auto;
        width: 40px;
        height: 40px;
    }
}
@media only screen and (min-width: 800px) and (max-width: 1023px) {
    .linha-produtos-caixa-titulo {
        font-size: 18px;
        padding-left: 0px;
        padding-right: 4px;
        display: block;
        white-space: nowrap;
        margin-top: 6px;
        margin-bottom: -10px;
    }
    .linhas-produtos-caixa-botao {
        width: 40px;
        height: 40px;
    }
    .linha-produtos-caixa-router-link {
        margin: 0px auto;
        width: 40px;
        height: 40px;
        display: block;
    }
}
@media only screen and (min-width: 501px) and (max-width: 799px) {
    .linha-produtos-caixa-titulo {
        font-size: 18px;
        padding-left: 1px;
        padding-right: 3px;
        display: block;
        white-space: nowrap;
        margin-bottom: -10px;
        margin-top: 4px;
    }
    .linhas-produtos-caixa-botao {
        width: 40px;
        height: 40px;
    }
    .linha-produtos-caixa-router-link {
        margin: 0px auto;
        width: 40px;
        height: 40px;
        display: block;
    }
}
@media only screen and (max-width: 500px) {
    .linha-produtos-caixa-titulo {
        font-size: 18px;
        display: block;
        margin-top: 12px;
        white-space: nowrap;
    }
    .linhas-produtos-caixa-botao {
        width: 40px;
        height: 40px;
    }
    .linha-produtos-caixa-router-link {
        margin: 0px auto;
        width: 40px;
        height: 40px;
        display: block;
    }
    .linha-produtos-caixa-seta-svg {
        height: 30px;
        width: 45px;
    }
}

</style>

