<template>
   <div class="caixa-footer">
       <div class="container-fluid">
           <div class="row p-5">
               <div class="col-md-2 mx-3 col-sm-6 footer-col">


                <div class="container-fluid">
                    <div class="row p-0">
                        <div>
                            <img src="../assets/images/novo_omni_logo_RGB.png" alt="Logo Omni" width="100" />
                        </div>
                   </div>

                    <br />

                    <div class="row p-0">
                        <div>
                            <p class="footer-endereco-branco">
                                Av. São Gabriel, 555 - 5º andar,<br />
                                Jardim Paulista, São Paulo - SP<br />
                                CEP 01435-001<br />
                                CNPJ 92.228.410/0001-02
                            </p>
                        </div>
                    </div>

                    <div class="row p-0">

                        <div>
                            <ul  v-if="dadosSociaisRodape" class="nav my-3" style="flex-wrap:nowrap !important;">
                                <li v-for="(dadoSocial, index) in dadosSociaisRodape"  v-bind:key="index" class="nav-item">
                                    <a :href="'https://' + dadoSocial.url" target="_blank">
                                        <img :src="dadoSocial.url_icone" :alt="dadoSocial.titulo" class="footer-icon-redesocial w-75 m-0"/>
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div class="row mt-2">
                       <div class="pl-0 col-5 col-md-6">
                           <img src="@/assets/images/Selo_Melhores_GPTW-2020.png"
                                alt="Selo Melhores GPTW - Instutuiçoes Financeiras 2020"
                                class="w-100" />
                       </div>
                       <div class="col-6 col-md-6 px-0">
                           <img src="@/assets/images/selocc.png"
                                alt="Selocc"
                                style="width:120%;"/>
                       </div>
                       <div class="pl-0 col-12 mt-3">
                           <img src="@/assets/images/consumidor.gov.png"
                                alt="Acrefi"
                                class="w-75" />
                       </div>
                   </div>

                </div>


               </div>
               <div class="col-md-3 mx-3 col-sm-6 footer-col">
                <div class="mb-3" v-for="(dado, index) in rodapeCol1" v-bind:key="index">
                    <p class="footer-txt-laranja">{{dado.titulo}}</p>
                    <div class="my-0 footer-conteudo-rodape conteudo-html-components"  v-html="dado.conteudo"></div>
                </div>
               </div>
               <div class="col-md-3 mx-3 col-sm-6 footer-col">
                <div class="mb-3" v-for="(dado, index) in rodapeCol2" v-bind:key="index">
                    <p class="footer-txt-laranja">{{dado.titulo}}</p>
                    <div class="my-0 footer-conteudo-rodape conteudo-html-components"  v-html="dado.conteudo"></div>
                </div>
               </div>
               <div class="col-md-2 mx-3 col-sm-6 footer-col">

                   <div class="mb-3 mt-0" v-for="(dado, index) in rodapeCol3" v-bind:key="index">
                        <div v-if="index == (rodapeCol3.length - 1)"><img src="../assets/images/icone-surdo.png" width="35" class="mb-1" /></div>
                        <p class="footer-txt-laranja">{{dado.titulo}}</p>
                        <div class="my-0 footer-conteudo-rodape conteudo-html-components" v-html="dado.conteudo"></div>
                   </div>
                   
               </div>
           </div>
       </div>

       <div class="container-fluid">
           <div class="row px-5 caixa-copy-rights">
               <div class="col-12 py-3 col-md-6 pl-md-4 align-self-center">
                   <span>&copy; 2020 Omni Banco &amp; Financeira. Todos os direitos reservados.</span>
               </div>

               <div class="col-12 py-3 col-md-6 text-md-right align-self-center">
                    <s-router-link v-for="link in links" :key="link.url" :url="link.url" class="mr-4 d-inline-block footer-links">
                        {{link.texto}}
                    </s-router-link>
               </div>
           </div>
       </div>
   </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import SRouterLink from "@/components/SRouterLink.vue";

    export default {
        name: 'Footer',
        components: {
            SRouterLink
        },
        data: function () {
            return {
                links: [
                    { texto: "Política de privacidade", url: "/storage/arquivos/Política de Privacidade_vf.pdf" },
                    { texto: "Termos de uso", url: "pagina/termos-uso" },
                    { texto: "Dicas Antifraude", url: "pagina/prevencaoagolpes" },
                    { texto: "LGPD", url: "pagina/lgpd" },
                ]
            }
        },
        methods: {
            ...mapActions({
                getDadosRodape: 'dadosRodape/getDadosRodape',
                getDadosSociaisRodape: 'dadosRodape/getDadosSociaisRodape'
            }),
            organizaDados(inicioIndex, fimIndex) {
                if (!this.dadosRodape)
                    return [];

                let result = this.dadosRodape.slice(inicioIndex, fimIndex);
                return result;
            },
            filtraViuvas: function () {
                this.$el.querySelectorAll('p').forEach((paragrafo => {
                    let wordArray = paragrafo.innerHTML.trimRight().split(' ')
                    if (wordArray.length > 1) {
                        //join the last and second to last
                        wordArray[wordArray.length - 2] += '&nbsp;' + wordArray[wordArray.length - 1]
                        wordArray.pop()
                        paragrafo.innerHTML = wordArray.join(" ")
                    }
                }))
            },
        },
        computed: {
            ...mapGetters({
                dadosRodape: 'dadosRodape/dadosRodape',
                dadosSociaisRodape: 'dadosRodape/dadosSociaisRodape'
            }),
            rodapeCol1() {
                return this.organizaDados(0, 3)
            },
            rodapeCol2() {
                return this.organizaDados(3, 6)
            },
            rodapeCol3() {
                return this.organizaDados(6, 9)
            },
        },
        mounted() {
            this.getDadosRodape();
            this.getDadosSociaisRodape();
            this.filtraViuvas();
        }

    }
</script>

<style scoped>
    .caixa-footer {
        background-color: var(--cinza-omni);
        text-align: left;
        width: 100%;
    }
    .footer-col {
        width: 100%;
        padding: 0 1%;
    }
    .footer-txt-laranja {
        color: #DD5F13;
        font-family: MuseoSans;
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 4px;
    }
    .footer-endereco-branco {
        font-family: MuseoSans;
        color: black;
        font-size: 13px;
        font-weight: bold;
    }
    .footer-icon-redesocial:hover {
        filter: brightness(80%);
    }
    .caixa-copy-rights {
        min-height: 50px;
        background-color: var(--laranja-claro-omni);
        color: white;
    }
    .footer-links {
        color: white;
        font-size: clamp(0.9rem, 1.6vw, 1rem);
    }
</style>

<style>
    .footer-conteudo-rodape {
        font-family: MuseoSans;
        color: black;
        font-size: 12px;
        font-weight: bold;
    }
    .footer-conteudo-rodape > p {
        font-family: MuseoSans;
        color: black;
        font-size: 12px;
        font-weight: bold !important;
    }
</style>