 <template>
  <div class="container-icone-lista">
    <div class="container-fluid">

        
        <div @click="iconRedirect(linkIcon)" :class="this.link? 'hover-07': ''" :style="this.link? 'cursor: pointer;':''">
          <div class="row py-1" v-if="urlIcone && alinhaEsquerda">
              <div class="col-10">
                  <div class="img-icone-lista-container-esq">
                      <img class="img-icone-lista-esq" :src="urlIcone" alt="icone"/>
                  </div>
              </div>
          </div>
          <div class="row py-2" v-else-if="urlIcone && !alinhaEsquerda">
              <div class="col-10 mx-auto">
                  <div class="img-icone-lista-container" :style="styleContainerImg">
                      <img class="img-icone-lista"  @load="carregouImagem" :src="urlIcone" alt="icone"/>
                  </div>
              </div>
          </div>

          <div class="row pt-3" v-if="titulo && alinhaEsquerda">
              <h5 class=" titulo-bloco-icone-lista-esq" v-bind:style="{ color: corTitulo }"> 
                  {{ titulo }}
              </h5>
          </div>
          <div class="row pt-3" v-else-if="titulo && !alinhaEsquerda">
              <h5 class="mx-auto titulo-bloco-icone-lista" v-bind:style="{ color: corTitulo }"> 
                  {{ titulo }}
              </h5>
          </div>

          <div class="row pt-1" v-if="sub_titulo && alinhaEsquerda">
              <h5 class=" titulo-bloco-icone-lista-esq" v-bind:style="{ color: corSubTitulo }"> 
                  {{ sub_titulo }}
              </h5>
          </div>
          <div class="row pt-1" v-else-if="sub_titulo && !alinhaEsquerda">
              <h5 class="mx-auto titulo-bloco-icone-lista" v-bind:style="{ color: corSubTitulo }"> 
                  {{ sub_titulo }}
              </h5>
          </div>


          <div v-if="alinhaEsquerda" class="row">
              <div class="texto-icone-lista-esq conteudo-html-components" v-html="texto" v-bind:style="{ color: corTexto }">
              </div>
          </div>
          <div v-else class="row">
              <div class="mx-auto texto-icone-lista conteudo-html-components" v-html="texto" v-bind:style="{ color: corTexto }">
              </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { getCor } from '@/assets/js/SharkUtils';

export default {
  name: 'ListaIconesBlocoIcone',
  props: {
    url_icone: String,
    titulo: String,
    sub_titulo: String,
    texto: String,
    cor_titulo: String,
    cor_sub_titulo: String,
    cor_texto: String,
    link: String,
    alinhamento: String,
    alturaImg: Number,
  },
  methods: {
    iconRedirect(link){
      if(link) {
        window.open(link);
      }
    },
    carregouImagem(pEvent) {
      this.$emit('carregou', pEvent.target.height);
    }
  },
  computed: {
    styleContainerImg() {
      if(!this.alturaImg) {
        return '';
      }
      return `min-height: ${this.alturaImg}px;`;
    },
    alinhaEsquerda(){
      if(this.alinhamento) {
          if(this.alinhamento === 'ESQUERDA') {
            return true;
          }
          return false;    
      }
      return false;
    },
    urlIcone() {
        if(this.url_icone) {
          return this.url_icone;
        }
        return '';
    },
    linkIcon() {
        if(this.link) {
          return this.link;
        }
        return '';
    },
    corTitulo() {
      if(this.cor_titulo) {
        return getCor(this.cor_titulo).var_hexa;
      }
      return '#000';
    },
    corSubTitulo() {
      if(this.cor_sub_titulo) {
        return getCor(this.cor_sub_titulo).var_hexa;
      }
      return '#000';
    },
    corTexto() {
      if(this.cor_texto) {
        return getCor(this.cor_texto).var_hexa;
      }
      return '#000';
    }
  },
  data: function() {
    return {
    }
  }
}
</script>

<style scoped>

.img-icone-lista-container {
  max-width: 100%;
  min-width: 50px;
  margin: auto;
  min-height: 60px;
}

  .img-icone-lista {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    max-width: 100%;
    display: block;
  }

.texto-icone-lista {
  word-break: normal;
  overflow-wrap: normal !important;
  text-align: center;
  font-weight: bold;
}

.titulo-bloco-icone-lista {
  text-align: center;
  font-weight: 900;
}

.img-icone-lista-esq {
  max-width: 100%;
  height: 100%;
  display: block;
}

.texto-icone-lista-esq {
  word-break: break-word;
  overflow-wrap: break-word !important;
  font-weight: bold;
}

.img-icone-lista-container-esq {
  min-width: 50px;
  max-width: 100%;
  height: 70px;
  margin: auto;
}

.titulo-bloco-icone-lista-esq {
  font-weight: 900;
}

</style>