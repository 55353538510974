import axios from 'axios';

export default {
    namespaced: true,
    state: {
        popup: null,
    },
    getters: {
        GET_POPUP(state) {
            return state.popup;
        },
    },
    mutations: {
        SET_POPUP(state, agentes) {
            state.lista_agentes = agentes;
        },
    },
    actions: {
        getPopup({ dispatch }) {
            dispatch('showOverlay', true, { root: true });
            axios.get("/api/popup")
                .then((response) => {
                    dispatch('showOverlay', false, { root: true });
                    return dispatch('attempt', response.data.dados);
                })
                .catch(err => {
                    dispatch('showOverlay', false, { root: true });
                    return dispatch('failed', err);
                });
        },
        attempt({ commit }, popup) {
            if (popup)
                console.log(popup);
                commit('SET_AGENTES', popup);
        },
        failed(_, err) {
            console.log('falha ao carregar popup');
            console.log(err);
        },
    },
}
