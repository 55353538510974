<template>
    <div class="ultimas-noticias-container">
        <div class="container-fluid">

            <div class="row">
                <div class="col-md-11 mx-auto">
                    <div class="container-fluid">
                    
                        <div class="row">
                            <div class="col-12 mx-auto">
                                <h2 class="ultimas-noticias-ultimas">Últimas notícias</h2>
                                <router-link :to="'/blog'" class="ultimas-noticias-mais-omni">
                                    <span class="ultimas-noticias-mais-omni-span"> Mais Omni Blog </span> 
                                    <img src="@/assets/images/seta_laranja.png" class="pl-1 mx-auto"/>
                                </router-link>
                            </div>
                        </div>

                        <div class="row">

                            <div v-for="(post, index) in ultimosPosts" :key="index" class="col-4 mx-auto ultimas-noticias-caixa-resultados">
                                <router-link :to="`/blog/${post.id}`" :key="index" >
                                    <div class="ultimas-noticias-relacionadas-img-container">
                                        <img :src="post.url_imagem" class="ultimas-noticias-relacionadas-img"  alt="imagem publicacao"/>
                                    </div>
                                    <h5 class="mx-auto ultimas-noticias-titulo-pagina"> {{ post.titulo }} </h5>
                                </router-link>
                                <div class="ultimas-noticias-paragrafo-info-container">
                                    <p class="w-75 ultimas-noticias-paragrafo-info"> Ano {{ post.ano }} - Edição {{ post.edicao }} </p>
                                    <p class="w-75 ultimas-noticias-paragrafo-info"> por {{ post.autor }} </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            

        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';


export default {
    name: 'UltimasNoticias',
    components: {
    },
    props: {
        id_comp: Number,
        componente: Object,
    },
    data: function () {
        return {
        }
    },
    methods: {
        ...mapActions({
        getPosts: 'posts/getPosts'
        }),
    },
    computed: {
        ...mapGetters({
            lista_posts: 'posts/posts',
        }),
        ultimosPosts() {
            if(!this.lista_posts || this.lista_posts.length == 0) {
                return [];
            }
            return this.lista_posts.slice(0, 3);
        },
    },
    created() {
        this.getPosts();
    }
}

</script>

<style scoped>
.ultimas-noticias-container {
    width: 100%;
    max-width: 100%;
    padding: 50px 12px;
}

.ultimas-noticias-ultimas {
    float: left;
    color: var(--azul-claro-omni);
    font-weight: 700;
}

.ultimas-noticias-mais-omni {
    float: right;
    color: var(--laranja-claro-omni);
    font-size: 18px;
}
    .ultimas-noticias-mais-omni:hover {
        opacity: 0.7;
    }

.ultimas-noticias-mais-omni-span {
    font-weight: 500;
    font-size: 20px;
}

.ultimas-noticias-caixa-resultados {
    margin: 20px 0;
}

@media only screen and (max-width:768px) {
    .ultimas-noticias-caixa-resultados {
        width: 85%;
        max-width: 85%;
    }
}

.ultimas-noticias-relacionadas-img-container {
    max-width: 100% !important;
    width: 100% !important;
    position: relative;
    overflow: hidden;
    height: 200px;
}
    .ultimas-noticias-relacionadas-img-container:hover {
        opacity: 0.7;
    }

.ultimas-noticias-relacionadas-img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-height: 100%;
    display: block;
}

.ultimas-noticias-titulo-pagina {
  width: 75%;
  text-align: center;
  padding-top: 25px;
  color: var(--azul-claro-omni);
  font-family: MuseoSans;
  font-weight: 900;
}

.ultimas-noticias-titulo-pagina:hover {
    opacity: 0.7;
    text-decoration: underline;
    cursor: pointer;
}

.ultimas-noticias-paragrafo-info-container {
    min-height: 4em;
}

.ultimas-noticias-paragrafo-info {
    text-align: center;
    font-family: MuseoSans;
    font-weight: 500;
    padding-bottom: 0px;
    margin: 0 auto;
    font-size: 14px;
}

</style>

