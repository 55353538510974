<template>
    <b-container class="container-precadastro" id="container-precadastro">

        <b-row align-h="center" class="justify-content-center m-4 pt-3">

            <h4 class="txt-interesse txt-interesse-laranja">
                Se interessou?
            </h4>
            <h4 class="txt-interesse">
                Preencha o formulário abaixo para entrar em contato com nossa área de Expansão.
            </h4>
        </b-row>

        <b-row align-h="center" class="agente-inputs" style="font-weight:900;">
            <b-col cols="12" lg="4" md="6" class="pb-md-5 px-md-5">
                <b-form-group label="Nome*"
                              label-for="contato-nome"
                              :invalid-feedback="invalidFeedbackNome"
                              :state="stateNome">

                    <b-form-input @blur="stateNome=verificaNome()"
                                  @focus="stateNome=true" v-model="novoAgente.nome"
                                  id="contato-nome"></b-form-input>
                </b-form-group>
                <b-form-group label="Estado Civil*"
                              label-for="contado-estadoCivil"
                              :invalid-feedback="invalidFeedbackEstadoCivil"
                              :state="stateEstadoCivil">
                    <b-form-select class="classic" v-model="novoAgente.estadoCivil" @change="stateEstadoCivil=true" id="contado-estadoCivil">
                        <option disabled selected hidden value="">Selecione</option>
                        <option v-for="estadocivil in TiposEstadoCivil" :value="estadocivil" :key="estadocivil">{{ estadocivil }}</option>
                    </b-form-select>
                </b-form-group>
                <b-form-group label="Telefone Residencial"
                              label-for="contado-telefone">
                    <b-form-input v-model="novoAgente.telefone"
                                  id="contato-telefone"
                                  v-mask="['(##) ####-####']"></b-form-input>
                </b-form-group>
                <b-form-group label="Estado*"
                              label-for="contato-estado"
                              :invalid-feedback="invalidFeedbackEstado"
                              :state="stateEstado">
                    <b-form-select class="classic" v-model="novoAgente.estado" @change="stateEstado=true" id="contato-estado">
                        <option disabled :value="''">Estados</option>
                        <option v-for="(estado, i) in estadosDisponiveis" v-bind:key="i" :value="estado.sigla">{{ estado.sigla }}</option>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col cols="12" lg="4" md="6" class="px-md-5">
                <b-form-group label="Data de Nascimento*"
                              label-for="contato-dataNasc"
                              :state="stateDataNasc"
                              :invalid-feedback="invalidFeedbackDataNasc">
                    <b-form-input v-model="novoAgente.dataNasc"
                                  @focus="stateDataNasc = true "
                                  @change="stateDataNasc = verificaDataNasc()"
                                  type="date"></b-form-input>
                </b-form-group>
                <b-form-group label="E-mail*"
                              label-for="contato-email"
                              :state="stateEmail"
                              :invalid-feedback="invalidFeedbackEmail">
                    <b-form-input @focus="stateEmail = true"
                                  @blur="stateEmail = verificaEmail()"
                                  v-model="novoAgente.email" id="contato-email"></b-form-input>
                </b-form-group>
                <b-form-group label="Telefone Celular*"
                              label-for="contatato-celular"
                              :state="stateCelular"
                              :invalid-feedback="invalidFeedbackCelular">
                    <b-form-input @focus="stateCelular = true"
                                  @blur="stateCelular = verificaCelular()" v-model="novoAgente.celular"
                                  id="contato-celular"
                                  v-mask="['(##) #####-####']"></b-form-input>
                </b-form-group>
                <b-form-group label="Cidade*"
                              label-for="contato-cidade"
                              :invalid-feedback="invalidFeedbackCidade"
                              :state="stateCidade"
                              description="Selecione um Estado antes de selecionar a Cidade.">
                    <b-form-select class="classic" v-model="novoAgente.cidade" @change="stateCidade=true" id="contato-cidade">
                        <option disabled :value="''">Cidades</option>
                        <option v-for="(cidadeDisp, i) in cidadesDisponiveis" v-bind:key="i" :value="cidadeDisp">{{ cidadeDisp }}</option>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row align-h="center" class="px-md-5">
            <b-col  lg="8" md="12" class="mx-auto">
                <hr class="separador">
            </b-col>
        </b-row>

        <b-row align-h="center" class="mt-5 agente-inputs" style="font-weight:900;">
            <b-col cols="12" lg="4" md="6" class="pb-4 px-md-5">
                <b-form-group label="Área de atuação interesse*"
                              label-for="contato-estadoInteresse"
                              :invalid-feedback="invalidFeedbackEstadoInteresse"
                              :state="stateEstadoInteresse">
                    <b-form-select class="classic" v-model="novoAgente.estadoInteresse" @change="stateEstadoInteresse=true" name="contato-estadoInteresse" id="contato-estadoInteresse">
                        <option disabled :value="''">Estados</option>
                        <option v-for="(estado, i) in estadosDisponiveis" v-bind:key="i" :value="estado.sigla">{{ estado.sigla }}</option>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col cols="12" lg="4" md="6" class="px-md-5">
                <b-form-group label="Cidade*"
                              label-for="contato-cidadeInteresse"
                              :invalid-feedback="invalidFeedbackCidade"
                              :state="stateCidadeInteresse"
                              description="Selecione um Estado antes de selecionar a Cidade.">
                    <b-form-select class="classic" v-model="novoAgente.cidadeInteresse" @change="stateCidadeInteresse=true" name="contato-cidadeInteresse" id="contato-cidadeInteresse">
                        <option disabled :value="''">Cidades</option>
                        <option v-for="(cidadeDisp, i) in cidadesDisponiveisInteresse" v-bind:key="i" :value="cidadeDisp">{{ cidadeDisp }}</option>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row align-h="center">
            <b-col cols="12" lg="8" md="12" class="px-md-5" style="font-weight:900;">
                <span style="word-wrap: break-word;">Tem alguma experiência profissional no segmento de negócios, crédito e cobrança?</span>
                <b-form-group>
                    <b-form-textarea id="contato-exp"
                                        v-model="novoAgente.expProfissional"
                                        rows="5"
                                        max-rows="10"
                                        no-resize></b-form-textarea>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row align-h="around" class="mb-5">

            <b-col cols="12" lg="8" md="12" class="ajuste-botao px-md-5">
                <b-button @click="enviaFormulario" class="botao-precadastro">
                    Enviar Formulário
                </b-button>
            </b-col>

            <b-col cols="12" md="8" class="ajuste-botao px-md-5 pt-xs-4 pt-sm-4 pt-lg-2" v-if="abrirCaptcha">
                <re-captcha-custom 
                    @recaptchaCallback="callbackContato"
                    @recaptchaExpired="tokenCaptcha=''"
                    @recaptchaError="erroCaptcha"
                />
            </b-col>

        </b-row>

    </b-container>
</template>

<script>
    import { mask } from 'vue-the-mask';
    import axios from 'axios'
    import estadosCidadesJson from '@/assets/js/estados-cidades.json';
    import { BButton, BFormTextarea, BFormInput, BFormGroup, BFormSelect,} from 'bootstrap-vue'
    import ReCaptchaCustom from '@/components/ReCaptchaCustom';

    export default {
        name: "PrecadastroAgente",
        cidadesEstados: estadosCidadesJson,
        directives: {
            mask
        },
        components: {
            BButton,
            BFormTextarea,
            BFormInput,
            BFormGroup,
            BFormSelect,
            ReCaptchaCustom,
        },
        props: {
            id_comp: Number,
            agente: Object
        },
        data: function () {
            return {
                stateNome: true,
                stateEstadoCivil: true,
                stateEstado: true,
                stateDataNasc: true,
                stateEmail: true,
                stateCelular: true,
                stateCidade: true,
                stateEstadoInteresse: true,
                stateCidadeInteresse: true,

                novoAgente: {
                    nome: '',
                    estadoCivil: '',
                    telefone: '',
                    estado: '',
                    dataNasc: '',
                    email: '',
                    celular: '',
                    cidade: '',
                    estadoInteresse: '',
                    cidadeInteresse: '',
                    expProfissional: '',
                },
                TiposEstadoCivil: ['Solteiro', 'Casado', 'Viúvo', 'Separado', 'Divorciado'],
                tokenCaptcha: '',
                abrirCaptcha: false,
            }
        },
        methods: {
            callbackContato(token) {
                this.tokenCaptcha = token
                this.salvarFormulario(this.novoAgente);
                setTimeout(() => this.abrirCaptcha = false, 600)
            },
            erroCaptcha(e) {
                console.log('erro no captcha', e)
                setTimeout(() => this.abrirCaptcha = false, 600)
                this.showModalError(); 
            },
            verificaNome() {
                return this.novoAgente.nome.length >= 4 ? true : false;
            },
            verificaEstadoCivil() {
                return this.novoAgente.nome.length >= 4 ? true : false;
            },
            verificaDataNasc() {
                const atual = new Date();
                let nasc = this.novoAgente.dataNasc.split("-").map(Number);
                let verifica18anos = new Date(nasc[0] + 18, nasc[1] - 1, nasc[2]);
                return verifica18anos <= atual ? true : false;
            },
            verificaEmail() {
                let usuario = this.novoAgente.email.substring(0, this.novoAgente.email.indexOf("@"));
                let dominio = this.novoAgente.email.substring(this.novoAgente.email.indexOf("@") + 1, this.novoAgente.email.length);
                if ((usuario.length >= 1) &&
                    (dominio.length >= 3) &&
                    (usuario.search("@") == -1) &&
                    (dominio.search("@") == -1) &&
                    (usuario.search(" ") == -1) &&
                    (dominio.search(" ") == -1) &&
                    (dominio.search(".") != -1) &&
                    (dominio.indexOf(".") >= 1) &&
                    (dominio.lastIndexOf(".") < dominio.length - 1))
                    return true;
                else { return false; }
            },
            verificaCelular() {
                return this.novoAgente.celular.length >= 13 ? true : false
            },
            enviaFormulario() {
                this.stateNome = this.verificaNome();
                this.stateEstadoCivil = this.verificaEstadoCivil();
                this.stateDataNasc = this.verificaDataNasc();
                this.stateEmail = this.verificaEmail();
                this.stateCelular = this.verificaCelular();
                if (this.novoAgente.estado === '')
                    this.stateEstado = false;
                if (this.novoAgente.cidade === '')
                    this.stateCidade = false;
                if (this.novoAgente.estadoInteresse === '')
                    this.stateEstadoInteresse = false;
                if (this.novoAgente.cidadeInteresse === '')
                    this.stateCidadeInteresse = false;
                if (!this.stateNome || !this.stateEstadoCivil || !this.stateEstado || !this.stateCidade
                    || !this.stateEmail || !this.stateCelular || !this.stateEstadoInteresse || !this.stateCidadeInteresse)
                    return;
                else
                    this.abrirCaptcha = true;
                return;
            },
            salvarFormulario(dados) {
                if (dados) {
                    axios.post('/api/preagentes/cadastro', dados, { headers: { "Token-Captcha": this.tokenCaptcha }})
                        .then(() => {
                            this.cleanInputs();
                            this.showConfirmModal();
                        })
                        .catch(err => {
                            console.log(err);
                            this.showModalError(); 
                        })
                }
            },
            cleanInputs: function () {
                for (let key in this.novoAgente) {
                    let prop = Object.prototype.hasOwnProperty.call(this.novoAgente, key);
                    if (prop) {
                        this.novoAgente[key] = '';
                    }
                }
            },
            showConfirmModal() {
                const el = this.$createElement;
                const textBody = el('div', {}, [
                    el('p', { class: ['text-left'] }, ['Em breve entraremos em contato.']),
                    el('p', { class: ['text-left'] }, ['- Omni Banco & Financeira']),
                ])

                this.$bvModal.msgBoxOk([textBody], {
                    title: 'Contato enviado com sucesso!',
                    size: 'md',
                    buttonSize: 'md',
                    okTitle: 'Fechar',
                    okVariant: 'primary',
                    headerClass: 'p-3 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                }).then().catch(err => { console.log(err); })
            },
            showModalError() {
                this.$bvModal.msgBoxOk('Ocorreu um erro no envio do seu formulário, tente novamente mais tarde', {
                    title: 'Algo deu errado',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'secondary',
                    okTitle: 'Fechar',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-3 border-top-0',
                    centered: true
                })
            },
        },
        computed: {
            invalidFeedbackNome() {
                if (this.stateNome) {
                    return ''
                } else if (!this.novoAgente.stateNome && this.novoAgente.nome.length > 0) {
                    return 'Nome deve ter pelo menos 4 caracteres.'
                } else {
                    return 'Por favor digite um nome.'
                }
            },
            invalidFeedbackEstadoCivil() {
                if (this.stateEstadoCivil) {
                    return ''
                } else {
                    return 'Por favor informe seu estado civil.'
                }
            },
            estadosDisponiveis() {
                return this.$options.cidadesEstados.estados.map((estado) => {
                    return {
                        sigla: estado.sigla,
                        nome: estado.nome,
                    }
                });
            },
            invalidFeedbackEstado() {
                if (this.stateEstado) {
                    return ''
                } else {
                    return 'Por favor selecione um estado.'
                }
            },
            invalidFeedbackCidade() {
                if (this.stateEstado) {
                    return ''
                } else {
                    return 'Por favor selecione uma cidade.'
                }
            },
            invalidFeedbackEstadoInteresse() {
                if (this.stateEstadoInteresse) {
                    return ''
                } else {
                    return 'Por favor selecione um estado.'
                }
            },
            invalidFeedbackCidadeInteresse() {
                if (this.stateEstadoInteresse) {
                    return ''
                } else {
                    return 'Por favor selecione uma cidade.'
                }
            },
            cidadesDisponiveis() {
                return this.$options.cidadesEstados.estados
                    // Objeto do estado com a sigla selecionada
                    .filter(estado => estado.sigla == this.novoAgente.estado)
                    // Seleciona as cidades
                    .map(estado => estado.cidades)
                    // Adiciona tudo num array so(map retornara lista de arrays com apenas com array dentro)
                    .flat();
            },
            cidadesDisponiveisInteresse() {
                return this.$options.cidadesEstados.estados
                    // Objeto do estado com a sigla selecionada
                    .filter(estado => estado.sigla == this.novoAgente.estadoInteresse)
                    // Seleciona as cidades
                    .map(estado => estado.cidades)
                    // Adiciona tudo num array so(map retornara lista de arrays com apenas com array dentro)
                    .flat();
            },
            invalidFeedbackDataNasc() {
                if (this.stateDataNasc) {
                    return ''
                }
                else {
                    return 'Informe uma data de nascimento válida.(Min: 18 anos)'
                }
            },
            invalidFeedbackEmail() {
                if (this.stateEmail) {
                    return ''
                }
                else {
                    return 'Por favor informe um e-mail válido.'
                }
            },
            invalidFeedbackCelular() {
                if (this.stateTelefone) {
                    return ''
                } else
                    return 'Informe um número de celular válido.'
            },
        },
        
    }
</script>

<style scoped>
    .container-precadastro {
        font-family: MuseoSans;
        font-size: 14px;
    }

    .ajuste-botao {
        text-align: right;
    }

    .botao-precadastro {
        background-color: var(--laranja-claro-omni);
        color: white;
        border: none;
        border-radius: 10px;
        font-weight: 900;
        font-size: 14px;
        padding: 0.7rem 2rem;
    }

        .botao-precadastro:hover, .botao-precadastro:focus {
            background-color: var(--laranja-omni);
        }

    hr.separador {
        border-top: 1px solid black;
    }

    label {
        font-weight: 700;
    }

    .txt-interesse {
        font-family: MuseoSans;
        font-weight: 900;
        font-size: 1.35rem;
    }

    .txt-interesse-laranja {
        color: var(--laranja-claro-omni);
        padding-right: 5px;
    }

    input.form-control {
        border: 1px solid black;
        height: calc(1.6em + 1rem + 2px) !important;
    }

    .custom-select {
        height: calc(1.6em + 1rem + 6px) !important;
    }

    .form-control:focus, select:focus, custom-select:focus {
        border: 1px solid var(--laranja-escuro-omni);
        box-shadow: none;
        outline: none;
    }

    .agente-inputs .form-group {
        margin-bottom: 1.5rem;
    }

    textarea {
        border: 1px solid black;
        overflow-y: hidden !important;
    }

    select {
        /* styling */
        background-color: white;
        border-radius: 4px;
        display: inline-block;
        font: inherit;
        font-weight: 500;
        border: 1px solid black;
        line-height: 1.5em;
        padding: 0.5em 3.5em 0.5em 1em;
        /* reset */
        margin: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

        select.classic {
            background-image: url(../../assets/images/svg/arrow-select.svg);
            background-position: calc(100% - 15px) calc(1em - 3px), calc(100% - 20px) calc(1em + 2px), 100% 0px;
            background-size: 13px;
            background-repeat: no-repeat;
        }

    @media screen and (max-width: 600px) {
        .container-precadastro, .botao-precadastro {
            text-align: center;
        }

        .agente-inputs {
            text-align: left;
        }

        .ajuste-botao {
            text-align: center;
        }

        .txt-interesse-laranja {
            font-size: clamp(25px, 10vw, 35px) !important;
        }
    }
    
</style>