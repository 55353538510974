<template>
    <div class="re-captcha-custom-container">
        <script type="application/javascript" src="https://www.google.com/recaptcha/api.js" async defer></script>
        <div 
            class="g-recaptcha" 
            :data-sitekey="siteKey" 
            data-callback="recaptchaCallback"
            data-expired-callback="recaptchaOnExpire"
        ></div>
    </div>
</template>

<script>
    export default {
        name: 'ReCaptchaCustom',
        data: function () {
            return {}
        },
        props: {
            siteKey: {
                type: String,
                default: '6LdgVeEZAAAAAFS0e4HOC4PPH538IRSfdZi3hs3R',
            },
        },
        methods: {
        },
        computed: {
        },
        beforeMount() {
            // Cria script declarando globalmente as funcoes callback do recaptcha
            var script = document.createElement('script');
            script.setAttribute("id", "script-recaptcha")
            var inlineScript = document.createTextNode(`
                var recaptchaOnError = function(erro) {
                    console.log('recaptchaOnError ', erro)
                    var event = new CustomEvent("onRecaptchaError", { "detail": erro });
                    document.dispatchEvent(event);
                }
                var recaptchaCallback = function(response) {
                    var event = new CustomEvent("onRecaptchaCallback", { "detail": response });
                    document.dispatchEvent(event);
                }
                var recaptchaOnExpire = function(param) {
                    var event = new CustomEvent("onRecaptchaExpired", { "detail": response });
                    document.dispatchEvent(event);
                }
            `);
            script.appendChild(inlineScript); 
            document.body.appendChild(script);
            // Ouve o evento declarado globalmente e emite um evento do vue
            document.addEventListener("onRecaptchaCallback", (e) => {
                this.$emit('recaptchaCallback', e.detail)
            });
            document.addEventListener("onRecaptchaExpired", (e) => {
                this.$emit('recaptchaExpired', e)
            });
            document.addEventListener("onRecaptchaError", (e) => {
                this.$emit('recaptchaError', e)
            });
        },
    }
</script>

<style>
    
</style>